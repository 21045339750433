import React, { useState } from 'react';
import { FiCalendar, FiCheckCircle, FiClock } from 'react-icons/fi';
import PrintedQuestion from './PrintedQuestion';
import HeaderPrinted from './HeaderPrinted';
import AnswerKey from './AnswerKey';
import { useParams } from '@reach/router';
import { useQuestionBook } from 'app/hooks/useQuestionBook';
import { useEffect } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { useClient } from 'jsonapi-react';
import { toast } from 'react-hot-toast';
import Loader from 'app/components/loader';

export default function PrintedExam() {
  const { id } = useParams();
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [course, setCourse] = useState([]);
  const { getQuestionBooks, getQuestionBook, questionBook } = useQuestionBook();

  const getQuestions = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`questions?filter[question_book_id]=${parseInt(id)}`);
    if (error) {
      toast.error('Erro ao buscar alternativas');
    } else {
      setQuestions(data);
    }
    setLoading(false);
  };

  const getCourse = async () => {
    setLoading(true);
    const { data } = await client.fetch(`courses/${questionBook?.['course-ids']?.[0]}`);
    setCourse(data);
    setLoading(false);
  };

  useEffect(() => {
    getQuestionBook(parseInt(id));
    getQuestionBooks();
    getQuestions();
  }, []);

  useEffect(() => {
    if (questionBook) {
      getCourse();
    }
  }, [questionBook]);

  const restQuestions = 100 - questions.length;

  const orderingQuestions = questionBook?.['question-book-taxonomy-questions']?.map(item => item?.['question-id'])?.map(id => questions.find(item => item?.id === id));

  if (loading) {
    return <Loader />;
  } else {
    return (
      <main className="main-content main-content--block">
        <HeaderPrinted institution={course?.institution?.name} />

        <div className="printed-exam__form">
          <p className="printed-exam__form-control">{t('textsCommon.name')}</p>
          <div className="printed-exam__form-row">
            <p className="printed-exam__form-control">Nº</p>
            <p className="printed-exam__form-control">{t('exams.seriesCourse')}</p>
            <p className="printed-exam__form-control">{t('exams.classModule')}</p>
            <p className="printed-exam__form-control">{t('exams.shift')}</p>
          </div>
          <div className="printed-exam__form-row">
            <p className="printed-exam__form-control printed-exam__form-control--50">{t('textsCommon.teacher')}</p>
            <p className="printed-exam__form-control printed-exam__form-control--50">{t('exams.subject')}</p>
          </div>
        </div>

        <div className="printed-exam__info">
          <h2 className="printed-exam__title">{questionBook?.title}</h2>
          <div className="printed-exam__meta">
            <p className="printed-exam__meta-item">
              <FiCalendar className="printed-exam__icon" />
              <span>
                {t('exams.from')}
                <strong> {moment(questionBook?.['published-at']).format(t('datesForm.formatDate'))}</strong> {t('exams.at')} <strong>{moment(questionBook?.['published-at']).format('HH:mm')}</strong>{' '}
                {t('exams.to')} <strong> {moment(questionBook?.['finished-at']).format(t('datesForm.formatDate'))}</strong> {t('exams.at')}{' '}
                <strong>{moment(questionBook?.['finished-at']).format('HH:mm')}</strong>
              </span>
            </p>
            <p className="printed-exam__meta-item">
              <FiClock className="printed-exam__icon" />{' '}
              {questionBook?.['resolution-time'] != '01:00' ? (
                <span>
                  <div dangerouslySetInnerHTML={{ __html: t('exams.hoursDuration', { hours: questionBook?.['resolution-time'] }) }}></div>
                </span>
              ) : (
                <span>
                  <div dangerouslySetInnerHTML={{ __html: t('exams.hourDuration', { hours: questionBook?.['resolution-time'] }) }}></div>
                </span>
              )}
            </p>
            <p className="printed-exam__meta-item">
              <FiCheckCircle className="printed-exam__icon" /> {questionBook?.['question-count']} {t('exams.questions')}
            </p>
          </div>
          <p className="printed-exam__description">{questionBook?.['description']}</p>
        </div>

        <div className="printed-exam__questions">
          {orderingQuestions?.map((item, index) => (
            <>
              <PrintedQuestion
                key={item?.id}
                question={item}
                index={index}
              />
            </>
          ))}
        </div>

        <div style={{ pageBreakBefore: 'always' }} />

        <HeaderPrinted institution={course?.institution?.name} />

        <div className="answer-key-wrapper">
          {orderingQuestions?.slice(0, 100).map((item, i) => (
            <AnswerKey
              question={item}
              index={i}
              key={i}
            />
          ))}

          {questions.length < 100 &&
            [...Array(restQuestions)]?.map((ak, i) => (
              <div
                className="answer-key answer-key--gray"
                key={i}
              />
            ))}
        </div>
      </main>
    );
  }
}
