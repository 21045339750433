import React, { useMemo } from 'react';
import { FiCalendar, FiChevronLeft, FiMousePointer, FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { Link, navigate, useParams } from '@reach/router';
import { t } from 'i18next';
import { useFormik } from 'formik';
import EmptyState from 'app/components/EmptyState';
import EssayCover from 'app/images/placeholders/show-course.jpg';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EssayStudentCard from './EssayStudentCard';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useState } from 'react';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import moment from 'moment';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { statusBadgeColor, statusBadgeText } from '../utils/BadgeEssay';
import ActionCableConsumer from 'app/utils/actionCableConsumer';
import { useSession } from 'app/hooks/useSession';
import TabNavBar from './TabNavBar';
import Select from 'react-select';

export default function EssayMonitoring({ essayId, uri }) {
  const [essay, setEssay] = useState({});
  const [essayStudents, setEssayStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [webhookNotification, setWebhookNotification] = useState(null);
  const [selectedTab, setSelectedTab] = useState('redacoes');
  const { session } = useSession();

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const client = useClient();

  const filters = useFormik({
    initialValues: {
      status: 'blank',
      searchTerm: '',
      institution: {
        value: ''
      }
    }
  });

  const getEssay = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(['essays', essayId]);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setEssay(data);
    }
    setLoading(false);
  };

  const getEssayStudents = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(['essays', essayId, 'essay_students']);
    if (error) {
      toast.error('Erro ao buscar estudantes');
    } else {
      setEssayStudents(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEssay();
    getEssayStudents();
  }, []);

  const options = [
    { name: 'Correção por Professor', id: 'reviewed' },
    { name: 'Correção por IA', id: 'unreviewed' },
    { name: 'Em revisão', id: 'reviewing' },
    { name: 'Em processamento', id: 'processing' },
    { name: 'Anulada', id: 'annulled' },
    { name: 'Aluno não enviou', id: 'not_sent' },
    { name: 'Aceitando respostas', id: 'accepting_proposal' },
    { name: 'Arquivo inválido', id: 'minimum_length_not_satisfied' },
    { name: 'Falha ao processar', id: 'failed_to_process' },
    { name: 'Prazo encerrado', id: 'close_for_submissions' }
  ];

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/redacao',
        name: 'Redação',
        isActive: false
      },
      {
        route: uri,
        name: essay?.topic?.length > 70 ? essay?.topic?.slice(0, 70) + '...' : essay?.topic,
        isActive: true
      }
    ]
  };

  useEffect(() => {
    if (!webhookNotification) return

    const setNewState = (item, socket) => {
      if (item?.id === socket?.id || item?.user?.id === socket?.user_id) {
        return { ...item, status: socket?.status, corrector: socket?.corrector, grade: socket?.grade, id: socket?.id }; 
      }
      return item
    }
    const newEssayStudents = essayStudents.map(item => setNewState(item, webhookNotification));
    setEssayStudents(newEssayStudents);
    setWebhookNotification(null);
  }, [webhookNotification]);

  const filteredUsersByName = essayStudents.filter(item => {
    if (filters.values.searchTerm === '') return essayStudents;
    return item.user.name.toLowerCase().includes(filters.values.searchTerm.toLowerCase());
  });

  const filteredUsersByStatus = filteredUsersByName.filter(item => {
    if (filters.values.status === 'blank') return filteredUsersByName;
    return item.status === filters.values.status;
  });

  const filteredUsersByInstitution = filteredUsersByStatus.filter(item => {
    if (filters.values.institution.value === '') return filteredUsersByStatus;
    return item.institution.id === parseInt(filters.values.institution.value, 10);
  });

  const handleReceivedNotification = data => {
    setWebhookNotification(data);
  };

  const handleSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = useMemo(() => {
    let sortableUsers = [...filteredUsersByInstitution];
    if (sortConfig.key !== null) {
      sortableUsers.sort((a, b) => {
        let aValue, bValue;

        switch (sortConfig.key) {
          case 'name':
            aValue = a.user.name ?? '';
            bValue = b.user.name ?? '';
            break;
          case 'entrega':
            aValue = new Date(a['delivery-date']) ?? new Date(0);
            bValue = new Date(b['delivery-date']) ?? new Date(0);
            break;
          case 'status':
            aValue = a.status ?? '';
            bValue = b.status ?? '';
            break;
          case 'nota':
            
            aValue = a.grade ?? 0;
            bValue = b.grade ?? 0;
            break;
          case 'corrigidoPor':
            aValue = a?.corrector?.name ?? '';
            bValue = b?.corrector?.name ?? '';
            break;
          default:
            return 0;
        }

        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return sortableUsers;
  }, [filteredUsersByInstitution, sortConfig]);

  const uniqueInstitutions = useMemo(() => {
    const institutions = essayStudents.map(student => ({
      id: student.institution.id,
      name: student.institution.name,
    }));
    
    const uniqueInstitutions = Array.from(new Set(institutions.map(inst => inst.id)))
      .map(id => institutions.find(inst => inst.id === id));
    return uniqueInstitutions;
  }, [essayStudents]);

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />
      <ActionCableConsumer
        channel="EssayStudentChannel"
        setObjectChannel={handleReceivedNotification}
        channelKey={'essay_id'}
        channelId={essayId}
      />

      <div className="course-about u-mb-4">
        <div
          className="show-course-banner"
          style={{
            backgroundImage: `url(${null || EssayCover})`
          }}
        >
          <div className="show-course-banner__inner">
            <div className="show-course-banner__container">
              <span className={`badge badge--tiny ${statusBadgeColor[essay.status]}`}>{statusBadgeText[essay.status]}</span>
              <div
                className="show-course-banner__title"
                title={essay?.topic}
              >
                {essay?.topic?.length > 70 ? essay?.topic?.slice(0, 70) + '...' : essay?.topic}
              </div>
            </div>
          </div>
        </div>

        <div className="widget-wrapper u-mb-0">
          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Índice de envio</h3>
            </div>

            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiMousePointer /> {essay?.['submissions-percent']}
              </p>
            </div>
          </div>

          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Prazo de entrega</h3>
            </div>

            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiCalendar /> {moment(essay?.['delivery-date']).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>

          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Nota média</h3>
            </div>

            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiCalendar /> {essay?.['average-grade']}
              </p>
            </div>
          </div>
        </div>

        <div className="course-about__body">
          <div>
            <h3 className="course-about__title u-mb-1">Envios</h3>

            <div className="card__progress card__progress--value-first u-mb-0">
              <div className="card__progress-container">
                <div
                  className="card__progress-bar"
                  role="progressbar"
                  style={{ width: `${essay?.['submissions-percent']}` }}
                  aria-valuenow={essay?.['submissions-percent']}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <div className="card__progress-text">{`${essay?.['submissions-count']}/${essay?.['total-of-students']}`}</div>
            </div>
          </div>
        </div>
      </div>

      <TabNavBar
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      {selectedTab === 'redacoes' && (
        <div className="course-about">
          <div className="course-about__body">
            <div className="course-about__filter">
              <div className="filter-bar filter-bar--borderless">
                <div className="filter-bar__inner">
                  <div className="filter-bar__row">
                    <label
                      htmlFor="search"
                      className="filter-bar__label"
                    >
                      {t('textsCommon.search')}
                    </label>
                    <input
                      className="form__control form__control--search-with-icon"
                      placeholder="Pesquisar por estudante"
                      type="search"
                      name="search"
                      id="search"
                      onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
                    />
                  </div>

                  <FilterSelectionBox
                    blankLabel={t('filter.blankLabelAll2')}
                    label={t('filter.labelSelectStatus')}
                    value={filters.values.status}
                    onChange={e => filters.setFieldValue('status', e.target.value)}
                    options={options}
                  />
                  <div className="filter-bar__row">
                    <label
                      htmlFor="search"
                      className="filter-bar__label"
                    >
                      Instituição
                    </label>
                    <Select
                        disabled={loading}
                        value={filters.values.institution}
                        openMenuOnFocus
                        options={[
                          { value: '', label: t('filter.blankLabelAll2') },
                          ...uniqueInstitutions.map(inst => ({ value: inst.id, label: inst.name }))
                        ]}
                        className="react-multi-select filter-bar__multi-select"
                        classNamePrefix="react-multi-select"
                        placeholder={t('filter.blankLabelAll2')}
                        noOptionsMessage={() => 'Sem opções'}
                        components={{
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null
                        }}
                        onChange={selectedOption => {
                          console.log(selectedOption)
                          filters.setFieldValue('institution', selectedOption);
                        }}
                      />
                      </div>
                </div>
              </div>

              <div className="round-dg-wrapper">
                <table
                  className="round-dg round-dg--light"
                  style={{ minWidth: '990px' }}
                >
                  <thead className="round-dg__header">
                    <tr className="round-dg__row">
                      <th
                        className="round-dg__cell-header"
                        onClick={() => handleSort('name')}
                        style={{ cursor: 'pointer', fontWeight: sortConfig.key === 'name' ? 'bold' : 'normal' }}
                      >
                        Nome {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />)}
                      </th>
                      <th
                        className="round-dg__cell-header"
                        style={{ cursor: 'pointer', width: '116px', fontWeight: sortConfig.key === 'entrega' ? 'bold' : 'normal' }}
                        onClick={() => handleSort('entrega')}
                      >
                        Entrega {sortConfig.key === 'entrega' && (sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />)}
                      </th>
                      <th
                        className="round-dg__cell-header"
                        style={{ cursor: 'pointer', width: '82px', fontWeight: sortConfig.key === 'nota' }}
                        onClick={() => handleSort('nota')}
                      >
                        Nota {sortConfig.key === 'nota' && (sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />)}
                      </th>
                      <th
                        className="round-dg__cell-header"
                        style={{ cursor: 'pointer', width: '180px', fontWeight: sortConfig.key === 'status' ? 'bold' : 'normal' }}
                        onClick={() => handleSort('status')}
                      >
                        Status da correção {sortConfig.key === 'status' && (sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />)}
                      </th>
                      <th
                        className="round-dg__cell-header"
                        style={{ cursor: 'pointer', width: '226px', fontWeight: sortConfig.key === 'corrigidoPor' ? 'bold' : 'normal' }}
                        onClick={() => handleSort('corrigidoPor')}
                      >
                        Corrigido por {sortConfig.key === 'corrigidoPor' && (sortConfig.direction === 'ascending' ? <FiArrowUp /> : <FiArrowDown />)}
                      </th>
                      <th
                        className="round-dg__cell-header"
                        style={{ width: '190px' }}
                      />
                    </tr>
                  </thead>

                  <tbody className="round-dg__body">
                    {sortedUsers?.map((student, i) => (
                      <EssayStudentCard
                        currentUser={session.user.id}
                        essayId={essayId}
                        key={i}
                        student={student}
                      />
                    ))}
                  </tbody>
                </table>
                {loading && <Loader />}
                {!loading && filteredUsersByInstitution?.length === 0 && (
                  <EmptyState
                    type="data"
                    title={'Nenhuma redação'}
                    text={'Ainda não temos redações para mostrar, mas você será notificado quando elas surgirem aqui.'}
                    bgless
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedTab === 'sobre' && (
        <div className="course-about">
          <div className="course-about__header">
            <h1 className="course-about__title">Enunciado</h1>

            <p
              className="course-about__description"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {essay?.statement}
            </p>

            <p className="course-about__description">
              Tema: {essay?.topic}
              <br />
              {/* Título: <strong>{essay?.title}</strong> */}
            </p>
          </div>

          <div className="course-about__body">
            <h2 className="course-about__title">Textos motivadores</h2>
            {essay?.['essay-motivator-texts']?.map((item, index) => {
              return (
                <article>
                  <h3 className="course-about__subtitle">Texto {index + 1}</h3>
                  <p
                    className="course-about__description"
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  />
                  {/* <p className="course-about__description">Disponível em: https://brasilescola.uol.com.br/portugues/preconceito-linguistico.htm. Acesso em: 19 ago. 2022 (adaptado).</p> */}
                </article>
              );
            })}
          </div>
        </div>
      )}
    </main>
  );
}
