import React from 'react';

function TabNavBar({ selectedTab, setSelectedTab }) {
  return (
    <div className="tab u-mb-4">
      <button
        className={selectedTab === 'correcao' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('correcao')}
      >
        Correção
      </button>
      <button
        className={selectedTab === 'sobre' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('sobre')}
      >
        Sobre
      </button>
    </div>
  );
}

export default TabNavBar;
