import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import GoalCard from './GoalCard';
import FormGoalModal from './FormGoalModal';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';

export default function Goals() {
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [goals, setGoals] = useState();
  const [currentGoal, setCurrentGoal] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGoals = async () => {
    setLoading(true);

    const { data, error } = await client.fetch('study_plan_objectives');

    if (error) {
      toast.error('Erro ao carregar objetivos');
    } else {
      setGoals(data);
    }

    setLoading(false);
  };

  const createGoal = async goal => {
    const { data, error } = await client.mutate('study_plan_objectives', goal);
    if (error) {
      toast.error('Erro ao criar objetivo');
    } else {
      // setGoals([...goals, data]);
      getGoals();
      toast.success('Objetivo criado com sucesso');
    }
  };

  const updateGoal = async goal => {
    const { data, error } = await client.mutate(['study_plan_objectives', goal.id], goal);
    if (error) {
      toast.error('Erro ao atualizar objetivo');
    } else {
      // setGoals(goals.map(g => g.id === data.id ? data : g));
      getGoals();
      setCurrentGoal(null);
      toast.success('Objetivo atualizado com sucesso');
    }
  };

  const deleteGoal = async goalId => {
    const { error } = await client.delete(['study_plan_objectives', goalId]);
    if (error) {
      toast.error('Erro ao excluir objetivo');
    } else {
      setGoals(goals.filter(g => g.id !== goalId));
      toast.success('Objetivo excluído com sucesso');
    }
  };

  useEffect(() => {
    getGoals();
  }, []);

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            className="filter-bar__back"
            onClick={() => navigate(-1)}
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Objetivos</span>
            </span>
          </button>
        </div>

        <div className="filter-bar__actions">
          <button
            className="btn btn--wide btn--primary"
            onClick={() => setShowModal(true)}
          >
            Novo objetivo
          </button>
        </div>
      </div>

      <div className="round-dg-wrapper">
        {!loading && (
          <table className="round-dg round-dg--light">
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th
                  className="round-dg__cell-header"
                  style={{ width: '200px' }}
                >
                  {'Nome do objetivo'}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '200px' }}
                >
                  {'Descrição'}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '160px' }}
                >
                  {'Semanas'}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '200px' }}
                >
                  {'Simulados'}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '66px' }}
                />
              </tr>
            </thead>

            <tbody className="round-dg__body">
              {goals?.map(g => (
                <GoalCard
                  goal={g}
                  setShowModal={setShowModal}
                  key={g.id}
                  setCurrentGoal={setCurrentGoal}
                  deleteGoal={deleteGoal}
                />
              ))}
            </tbody>
          </table>
        )}
        {Boolean(goals) && goals.length === 0 && (
          <div className="u-mt-3">
            <EmptyState type="data" />
          </div>
        )}

        {loading && <Loader />}
      </div>

      <FormGoalModal
        show={showModal}
        onClose={() => setShowModal(false)}
        createGoal={createGoal}
        updateGoal={updateGoal}
        currentGoal={currentGoal}
      />
    </main>
  );
}
