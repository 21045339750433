import React, { useEffect, useRef, useState } from 'react';
import Modal from 'app/components/Modal';
import Name from './Name';
import Question from './Question';
import Success from './Success';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import QuestionCountdown from './QuestionCountdown';

export default function ChallengeModal(props) {
  const { show, onClose } = props;
  const { currentStep, taxonomies, setShowQuestionCountDown, showQuestionCountDown } = useWeeklyChallenge();

  const questionCountdownRef = useRef();

  useEffect(() => {
    if (show && currentStep === 1) {
      questionCountdownRef.current = setTimeout(() => {
        setShowQuestionCountDown(true); 
      }, 6000);
    }
    return () => {
      if (questionCountdownRef.current) {
        clearTimeout(questionCountdownRef.current);
      }
    };
  }, [show, taxonomies]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      hideCloseButton
      contentClassName="modal__content--md u-p-0"
    >
      <div className="challenge-modal">
        {currentStep === 1 && <Name />}
        {currentStep === 2 && <Question />}
        {currentStep === 3 && <Success onClose={onClose} />}
      </div>
      <QuestionCountdown
        showQuestionCountDown={showQuestionCountDown}
        setShowQuestionCountDown={setShowQuestionCountDown}
      />
    </Modal>
  );
}
