import React, { useMemo, useState } from 'react';
import { FiZap } from 'react-icons/fi';
import { ReactComponent as MedalCheck } from 'app/images/icons/medal-check.svg';
import { ReactComponent as MedalCheckUndone } from 'app/images/icons/medal-check-undone.svg';

export default function ToDo(props) {
  return (
    <div className="card__todo">
      {/* Input utilizado na versão anterior
      <input
        className="card__todo-check-input"
        type="checkbox"
        name="done"
        id="done"
        checked={done}
        onChange={checkStudyPlan}
        onClick={e => e.stopPropagation()}
      /> */}

      <div className="card__todo-header">
        <MedalCheck className="card__todo-medal-check" />
        <button className={'btn btn--small btn--review'}>
          <FiZap className="btn__icon" /> Iniciar revisão
        </button>
      </div>

      <div className="card__todo-body">
        <h4 className="card__todo-title">English</h4>

        <div className="card__badges card__badges--one-line u-mb-0">
          <span
            className="badge badge--primary"
            title={'English'}
          >
            {'English'}
          </span>
          <span
            className="badge badge--primary"
            title={'English'}
          >
            {'English'}
          </span>

          <span
            className="badge badge--more"
            title={`English, English`}
          >
            +2
          </span>
        </div>

        {/* Assets da primeira versão
        <div className="card__todo-assets">
          <div className="card__todo-count">
            <FiVideo /> 1
          </div>
          <div className="card__todo-count">
            <FiFileText /> {studyPlanVideo.video['video-materials'].length}
          </div>
        </div> */}
      </div>

      <div className="card__todo-footer">
        <button className="btn btn--outline btn--full">Acessar bloco de estudos</button>
      </div>
    </div>
  );
}
