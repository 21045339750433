import { Link } from '@reach/router';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import { useQuery } from 'jsonapi-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { CiStreamOn } from 'react-icons/ci';
import { FiLogOut, FiMap } from 'react-icons/fi';

export default function ShortcutWidget() {
  const { t } = useTranslation();
  const { abilities } = usePlatformAbilities();

  const { data: nextLiveClass, error: errorNextLiveClass, isLoading: isLoadingNextLiveClass } = useQuery('/live_classrooms/next');
  const { data: lastStudyPlan, error: errorLastStudyPlan, isLoading: isLoadingLastStudyPlan } = useQuery('/study_plans/latest');

  const isSame = moment(nextLiveClass?.['initial-date']).format('YYYY-MM-DD') === moment(nextLiveClass?.['end-date']).format('YYYY-MM-DD');
  const formattedInitialDate = moment(nextLiveClass?.['initial-date']).format(isSame ? 'HH[h]mm' : 'DD/MM - HH[h]mm');
  const formattedEndDate = moment(nextLiveClass?.['end-date']).format(isSame ? 'HH[h]mm' : 'DD/MM - HH[h]mm');
  const date = `${formattedInitialDate} às ${formattedEndDate}`;

  const now = moment();
  const startDate = moment(nextLiveClass?.['initial-date']);
  const endDate = moment(nextLiveClass?.['end-date']);
  const isOnline = now.isBetween(startDate, endDate);

  useEffect(() => {
    if (errorNextLiveClass) {
      console.log(errorNextLiveClass);
      toast.error('Erro ao buscar dados da aula ao vivo.');
    }

    if (errorLastStudyPlan) {
      console.log(errorLastStudyPlan);
      toast.error('Erro ao buscar dados do plano de estudos.');
    }
  }, [errorNextLiveClass, errorLastStudyPlan]);

  if (isLoadingNextLiveClass || isLoadingLastStudyPlan) return <></>;

  if (!abilities?.['aulas-ao-vivo'] && !abilities?.['plano-de-estudo']) return <></>;

  return (
    <div className="shortcut-widget u-mb-4">
      {abilities?.['aulas-ao-vivo'] && nextLiveClass ? (
        <Link
          className="shortcut-widget__item"
          to={isOnline ? `/aulas-ao-vivo/${nextLiveClass?.id}` : '/aulas-ao-vivo'}
        >
          <span className="shortcut-widget__icon">
            <CiStreamOn />
          </span>

          <div className="shortcut-widget__content">
            <h3 className="shortcut-widget__title">Aula ao vivo</h3>
            <p className="shortcut-widget__text">{nextLiveClass?.title || 'Em breve a próxima aula aparecerá aqui'}</p>
            <p className="shortcut-widget__text">
              {!isOnline && `${moment(nextLiveClass?.['initial-date']).format('DD/MM')} - `}
              {date}
            </p>
          </div>

          <div className="shortcut-widget__actions">
            <span className={isOnline ? 'shortcut-widget__online' : 'shortcut-widget__offline'} />
            {isOnline && <FiLogOut />}
          </div>
        </Link>
      ) : null}

      {abilities?.['plano-de-estudo'] && lastStudyPlan ? (
        <Link
          to={lastStudyPlan ? `/plano-de-estudos/${lastStudyPlan?.id}` : `/simulados`}
          className="shortcut-widget__item shortcut-widget__item--study-plan"
        >
          <span className="shortcut-widget__icon">
            <FiMap />
          </span>

          {lastStudyPlan ? (
            <div className="shortcut-widget__content">
              <h3 className="shortcut-widget__title">Guia de Estudos</h3>
              <p className="shortcut-widget__text">
                {t('studyPlan.generate')} {lastStudyPlan?.title}
              </p>
            </div>
          ) : (
            <div className="shortcut-widget__content">
              <h3 className="shortcut-widget__title">Inicie um simulado para criar um novo plano de estudos</h3>
              <p className="shortcut-widget__text">Clique para iniciar um simulado</p>
            </div>
          )}
        </Link>
      ) : null}
    </div>
  );
}
