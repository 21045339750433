import React from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import Dropdown from 'app/components/Dropdown';

export default function GoalCard(props) {
  const { goal, setShowModal, setCurrentGoal, deleteGoal } = props;

  const { t } = useTranslation();

  const handleDelete = goalId => {
    Swal.fire({
      title: 'Deseja mesmo excluir esse objetivo?',
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteGoal(goalId);
      }
    });
  };

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <h3 className="round-dg__title">{goal.name}</h3>
      </th>
      <td className="round-dg__cell">
        <p className="round-dg__subtitle">{goal.description}</p>
      </td>
      <td className="round-dg__cell">{`${goal.week} semanas`}</td>
      <td className="round-dg__cell">
        {goal['question-books'].length > 0 && (
          <div className="card__badges card__badges--one-line u-mb-0">
            {goal['question-books'].length <= 1 &&
              goal['question-books'].map(t => (
                <span
                  className="round-dg__subtitle"
                  key={t.id}
                  title={t.title}
                >
                  {t.title}
                </span>
              ))}

            {goal['question-books'].length > 1 && (
              <>
                {goal['question-books'].slice(0, 1).map(t => (
                  <span
                    className="round-dg__subtitle"
                    key={t.id}
                    title={t.title}
                  >
                    {t.title}
                  </span>
                ))}
                <span
                  className="badge badge--more"
                  title={goal['question-books'].slice(1).map(t => ` ${t.title}`)}
                >
                  +{goal['question-books'].length - 1}
                </span>
              </>
            )}
          </div>
        )}
      </td>
      <td className="round-dg__cell">
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item
              onClick={() => {
                setCurrentGoal(goal);
                setShowModal(true);
              }}
            >
              {t('button.edit')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDelete(goal.id)}>{t('button.delete')}</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      </td>
    </tr>
  );
}
