import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';

import MainSidebar from '../components/mainSidebar';
import GroupedQuestionOpen from '../components/groupedQuestionOpen';
import Loader from '../components/loader';
import { useSession } from '../hooks/useSession';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import moment from 'moment';

function OpenAnswerBook(props) {
  const client = useClient();
  const { session } = useSession();
  const [isLoading, setIsLoading] = useState(true);
  const [questionBook, setQuestionBook] = useState();
  const [questionBooksUser, setQuestionBooksUser] = useState();
  const [questionBookTaxonomies, setQuestionBookTaxonomies] = useState([]);
  const [activeGroupedQuestions, setActiveGroupedQuestions] = useState(0);
  const [lastAnswerSubmited, setLastAnswerSubmited] = useState();

  useEffect(() => {
    try {
      getQuestionBook();
    } catch (e) {
      console.error(e);
      toast.error('Erro ao buscar prova do OPEN.');
    }
  }, []);

  const groupQuestionsByCode = items => {
    if (items.length > 0) {
      return items.reduce((result, item) => {
        (result[item.code] = result[item.code] || []).push(item);
        return result;
      }, {});
      
    }
  };

  const selectQuestionBookTaxonomy = newIndex => {
    setActiveGroupedQuestions(newIndex);
    window.scrollTo(0, 0);
  };

  const getQuestionBook = async () => {
    setIsLoading(true);
    const filterQuestionBook = { filter: { visibility: 'open' }, current_user: session.user.id };
    const questionBookResponse = await client.fetch(['question_books', props?.id, filterQuestionBook]);
    const questionBookId = questionBookResponse?.data?.id;
    setQuestionBook(questionBookResponse.data);

    if (questionBookResponse.data?.['last-question-books-user-id']) {
      const response = await client.fetch(['question_books_users', questionBookResponse.data?.['last-question-books-user-id']]);
      setQuestionBooksUser(response);
    } else {
      window.location.pathname = '/simulados';
    }

    const queryParams = `filter[visibility]=open&filter[question_book_id]=${questionBookId}`;
    const { data } = await client.fetch(`questions?${queryParams}`);

    let questionsByTaxonomy = {};
    questionBookResponse.data['question-book-taxonomies'].forEach(qbt => {
      questionsByTaxonomy[qbt.taxonomy_id] = data.filter(q => qbt.question_ids.includes(q?.id))
    });

    let questionTaxonomies = questionBookResponse.data.taxonomies;
    questionTaxonomies.forEach(questionTaxonomy => {
      const tQuestions = questionsByTaxonomy[questionTaxonomy?.id];
      questionTaxonomy.selected = false;
      questionTaxonomy.questions = tQuestions;
      questionTaxonomy.groupedQuestions = groupQuestionsByCode(tQuestions);
    });

    questionTaxonomies[0].selected = true;
    setQuestionBookTaxonomies(questionTaxonomies);

    setIsLoading(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="main-screen">
      <MainSidebar
        simulated
        disabledClock
        questionBook={questionBook}
        activeGroupedQuestions={activeGroupedQuestions}
        selectQuestionBookTaxonomy={selectQuestionBookTaxonomy}
        questionBooksUser={questionBooksUser}
        lastAnswerSubmited={lastAnswerSubmited}
      />
      <div className="main-content">
        <div className="question-list">
          {questionBook &&
            questionBook.taxonomies[activeGroupedQuestions].groupedQuestions &&
            Object.entries(questionBook.taxonomies[activeGroupedQuestions].groupedQuestions).map(([key, groupedQuestion]) => (
              <GroupedQuestionOpen
                key={key}
                disabledSaveAnswer
                answers={questionBook.taxonomies[activeGroupedQuestions].answers || []}
                groupedQuestion={groupedQuestion}
                questionBooksUser={questionBooksUser}
              />
            ))}
          <p>
            <strong>Para continuar selecione a próxima matéria no menu.</strong>
          </p>
          <br />
          <br />
          <br />
        </div>

        <div className="questions-options">
          <Link
            to="/simulados"
            className="btn btn--outline btn--full"
          >
            Voltar para simulados
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OpenAnswerBook;
