import React, { useState, useEffect, useRef } from 'react';
import Vimeo from '@u-wave/react-vimeo';

export default function VimeoPlayer(props) {
  const { disclaimerModal, setFinishVideoModal, unansweredQuestions, videoId, onUpdateProgress, setUpdateContentNow, selectedItem, showQuizModal, setShowQuizModal, setCurrentQuiz, onFetchLearnSystemUserItem } = props;
  const [finished, setFinished] = useState(false);
  const [currentPercent, setCurrentPercent] = useState();
  const [count, setCount] = useState(0);
  const [player, setPlayer] = useState(null);

  const parsedQuiz = selectedItem?.['quiz-items']?.map(item => {
    const time = item.duration;
    const parts = time.split(':');
    const hours = parseInt(parts[0], 10) * 3600;
    const minutes = parseInt(parts[1], 10) * 60;
    const seconds = parseInt(parts[2], 10);
    const totalSeconds = hours + minutes + seconds;
    return {
      body: item.body,
      id: item.id,
      duration: totalSeconds,
      'quiz-item-alternatives': item['quiz-item-alternatives'],
      taxonomies: item.taxonomies
    };
  });

  const openModalTimings = parsedQuiz?.map(item => item.duration);

  useEffect(() => {
    setFinished(false);
  }, [videoId]);

  useEffect(() => {
    if ((!showQuizModal || !disclaimerModal) && player) {
      player.play();
    }
  }, [showQuizModal, disclaimerModal]);

  const onReady = player => {
    setPlayer(player);
  };

  const handlePlay = () => {
    setTimeout(() => setCount(0), 2000);
  };

  const onTimeUpdate = async e => {
    const percent = parseInt(e.percent * 100);
    const videoCurrentTime = parseInt(e.seconds);
    const videoDuration = parseInt(e.duration);

    if (videoCurrentTime === videoDuration - 2 && unansweredQuestions.length > 0 && count === 0) {
      setFinishVideoModal(true);
      setShowQuizModal(true);
      setCurrentQuiz(unansweredQuestions[0]);
      player.pause();
      setCount(prevState => prevState + 1);
    }

    if (openModalTimings.includes(Math.floor(e.seconds)) && count == 0) {
      player.pause();
      setShowQuizModal(true);
      setCount(count + 1);
      setCurrentQuiz(parsedQuiz.find(item => item.duration == Math.floor(e.seconds)));

      if (document.fullscreenElement !== null) {
        document.exitFullscreen();
      }
    }

    if (!finished) {
      if (currentPercent !== percent && percent >= 90) {
        setFinished(true);
        setCurrentPercent(percent);
        await onUpdateProgress(percent);
        await onFetchLearnSystemUserItem();
      } else if (currentPercent !== percent && percent > 0 && percent % 5 == 0) {
        setCurrentPercent(percent);
        await onUpdateProgress(percent);
      }
    }

    if (percent === 100) {
      setUpdateContentNow(true);
    }
  };

  return (
    <Vimeo
      onReady={onReady}
      onPlay={handlePlay}
      video={videoId}
      autoplay
      height="100%"
      width="100%"
      onTimeUpdate={onTimeUpdate}
    />
  );
}
