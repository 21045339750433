import React from 'react';
import { Link } from '@reach/router';
import { t } from 'i18next';

export default function KpiWidget(props) {
  const { title, count, actionTitle, linkText, route } = props;

  return (
    <div className="kpi-widget">
      <div className="kpi-widget__header">
        <h3 className="kpi-widget__title">{title || t('textsCommon.title')}</h3>
      </div>

      <div className="kpi-widget__body kpi-widget__body--dashboard">
        <h4 className="kpi-widget__count">{count || '0'}</h4>
        <p className="kpi-widget__text">{actionTitle || 'Título da ação'}</p>
      </div>

      <Link
        to={route || '/'}
        className="kpi-widget__link"
      >
        {linkText || 'Texto do link'}
      </Link>
    </div>
  );
}
