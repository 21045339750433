import React from 'react';

export default function PrintedQuestion(props) {
  const { question, index } = props;

  const getTaxonomy = () => {
    if (question) {
      return question?.knowledge;
    } else if (question?.taxonomies.length > 0) {
      return question?.taxonomies[0]?.name;
    } else {
      return null;
    }
  };

  const checkMotivatorExistence = () => {
    if (question?.['motivator-texts']?.[0]?.body || question?.['motivator-texts']?.[0]?.print) {
      return true;
    } else {
      return false;
    }
  };

  const sanitizeImages = html => {
    const maxImageWidth = '50%';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const images = tempDiv.getElementsByTagName('img');

    for (let i = 0; i < images.length; i++) {
      images[i].style.maxWidth = maxImageWidth;
    }
    return tempDiv.innerHTML;
  };

  return (
    <div className="printed-question">
      <div className="printed-question__header">
        <div className="printed-question__inner-header">
          <h3 className="printed-question__title">{`Questão ${index + 1}`}</h3>
          <p className="printed-question__subtitle">{getTaxonomy()}</p>
        </div>
        <p className="printed-question__tag">{`Q${question?.id}`}</p>
      </div>

      <div className="printed-question__enunciation">
        <div
          className="question-card__introduction"
          dangerouslySetInnerHTML={{ __html: question?.description }}
        />
      </div>

      {checkMotivatorExistence() && (
        <div className="printed-question__motivating-text">
          <p>
            <div
              className="question-card__introduction"
              dangerouslySetInnerHTML={{ __html: sanitizeImages(question?.['motivator-texts']?.[0]?.body) }}
            />
          </p>
          <p className="printed-question__press">
            <div
              className="question-card__introduction"
              dangerouslySetInnerHTML={{ __html: question?.['motivator-texts']?.[0]?.print }}
            />
          </p>
        </div>
      )}

      <ul className="printed-question__alternatives">
        {question?.alternatives?.map(item => {
          if (item?.['is-correct'] === null) {
            return null;
          }
          return (
            <li
              className="printed-question__alternative"
              key={item?.id}
            >
              <span className="printed-question__alternative-id">
                {item?.name}{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <rect
                    x="0.603516"
                    y="1"
                    width="11"
                    height="11"
                    rx="5.5"
                    fill="white"
                    stroke="#8894AC"
                  />
                </svg>
              </span>
              <span className="printed-question__alternative-text">
                <div
                  className="question-card__introduction"
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
