import React, { useState } from 'react';
import CourseStep from './CourseStep';
import NameStep from './NameStep';
import DetailsStep from './DetailsStep';
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { useEffect } from 'react';
import moment from 'moment';
import { t } from 'i18next';
import CreateMockQuestionBook from '.';

function MockQuestionBookContent(props) {
  const { step, courses, setCurrentStep, coursesLoading, editingQuestionBook, steps, currentStep } = props;
  const client = useClient();
  const [goals, setGoals] = useState([]);

  const getGoals = async () => {
    const { data, error } = await client.fetch('study_plan_objectives');
    if (error) {
      toast.error('Erro ao carregar objetivos');
    } else {
      setGoals(data);
    }
  };

  const createGoal = async goal => {
    const { data, error } = await client.mutate('study_plan_objectives', goal);
    if (error) {
      toast.error('Erro ao criar objetivo');
    } else {
      getGoals();
      createMockQuestionBookForm.setFieldValue('study-plan-objective-id', data.id);
      toast.success('Objetivo criado com sucesso');
    }
  };

  const createExam = async () => {
    const auxValues = { ...createMockQuestionBookForm.values };

    auxValues['taxonomy-ids'] = auxValues?.selectedTaxonomies.map(t => t.id);
    auxValues['course-ids'] = auxValues.courseId;
    if (auxValues['classroom-ids']?.length !== 0) {
      const initialDate = auxValues.evaluationStart.date;
      const initialTime = `${auxValues.evaluationStart.hour}:${auxValues.evaluationStart.minute} `;
      auxValues['published-at'] = `${initialDate} ${initialTime}`;

      const finishDate = auxValues.evaluationEnd.date;
      const finishTime = `${auxValues.evaluationEnd.hour}:${auxValues.evaluationEnd.minute}`;
      auxValues['finished-at'] = `${finishDate} ${finishTime}`;
    } else {
      auxValues['published-at'] = null;
      auxValues['finished-at'] = null;
    }

    delete auxValues.courseId;
    delete auxValues.selectedTaxonomies;
    delete auxValues.evaluationStart;
    delete auxValues.evaluationEnd;
    delete auxValues.association;

    const path = editingQuestionBook ? ['question_books', editingQuestionBook.id] : 'question_books';
    const { data, error } = await client.mutate(path, auxValues);
    if (error) {
      if (!editingQuestionBook) {
        toast.error(t('toast.errorCreateSimulation'));
      } else {
        toast.error(t('toast.errorEditSimulation'));
      }
    } else {
      if (!editingQuestionBook) {
        toast.success(t('toast.successCreateSimulation'));
      } else {
        toast.success(t('toast.successEditSimulation'));
      }

      navigate(`/simulados/gerenciar/${data.id}`, { state: { activeTabs: 'simulados' } });
    }
  };

  const createMockQuestionBookForm = useFormik({
    initialValues: {
      title: '',
      resolutionTime: '',
      evaluationStart: {
        date: '',
        hour: '00',
        minute: '00'
      },
      evaluationEnd: {
        date: '',
        hour: '00',
        minute: '00'
      },
      description: '',
      passingScore: '',
      correctionFactor: '',
      courseId: [],
      'study-plan-objective-id': '',
      taxonomyIds: [],
      selectedTaxonomies: [],
      visibility: 'open',
      published: false,
      official_content: false,
      authored_by_user: false,
      correct_after_user_finish: true
    },
    onSubmit: () => handleSubmit,
    validationSchema: yup.object({
      title: yup.string().required(t('warning.requiredField')),
      courseId: yup.array().min(1, t('warning.requiredField')),
      'study-plan-objective-id': yup.number().required(t('warning.requiredField')),
      selectedTaxonomies: yup.array().min(1, t('warning.selectSubject')).nullable(),
      description: yup.string().required(t('warning.requiredField')),
      resolutionTime: yup.string().required(t('warning.requiredField')),
      evaluationStart: yup.object({
        date: yup.string().required(t('warning.requiredField')),
        hour: yup.string().required(t('warning.requiredField')),
        minute: yup.string().required(t('warning.requiredField'))
      }),
      evaluationEnd: yup.object({
        date: yup.string().required(t('warning.requiredField')),
        hour: yup.string().required(t('warning.requiredField')),
        minute: yup.string().required(t('warning.requiredField'))
      }),
      passingScore: yup.string().required(t('warning.requiredField')),
      correctionFactor: yup.string().required(t('warning.requiredField')),
      studyPlanNumWeeks: yup.string().required(t('warning.requiredField'))
    })
  });

  useEffect(() => {
    getGoals();
  }, []);

  useEffect(() => {
    if (editingQuestionBook !== null) {
      createMockQuestionBookForm.setValues({
        title: editingQuestionBook?.title,
        resolutionTime: editingQuestionBook?.['resolution-time'],
        evaluationStart: {
          date: moment(editingQuestionBook?.evaluationStart.date).format('YYYY-MM-DD'),
          hour: parseInt(editingQuestionBook?.evaluationStart?.hour),
          minute: parseInt(editingQuestionBook?.evaluationStart?.minute)
        },
        evaluationEnd: {
          date: moment(editingQuestionBook?.evaluationEnd.date).format('YYYY-MM-DD'),
          hour: parseInt(editingQuestionBook?.evaluationEnd?.hour),
          minute: parseInt(editingQuestionBook?.evaluationEnd?.minute)
        },
        'study-plan-objective-id': editingQuestionBook?.['study-plan-objective-id'],
        description: editingQuestionBook?.description,
        passingScore: editingQuestionBook?.['passing-score'],
        correctionFactor: editingQuestionBook?.['correction-factor'],
        selectedTaxonomies: editingQuestionBook?.taxonomies,
        published: editingQuestionBook?.published,
        courseId: editingQuestionBook?.['course-ids'],
        taxonomyIds: editingQuestionBook?.['taxonomy-ids'],
        studyPlanNumWeeks: editingQuestionBook?.['study-plan-num-weeks'],
        official_content: editingQuestionBook?.['official-content'],
        authored_by_user: editingQuestionBook?.['authored-by-user'],
        correct_after_user_finish: editingQuestionBook?.['correct-after-user-finish']
      });
    }
    return () => {
      createMockQuestionBookForm.resetForm();
    };
  }, [editingQuestionBook, courses]);

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (step === 'curso' && !editingQuestionBook) {
      createMockQuestionBookForm.setFieldTouched('courseId', true);
      createMockQuestionBookForm.setFieldTouched('title', true);
      createMockQuestionBookForm.setFieldTouched('description', true);
      createMockQuestionBookForm.setFieldTouched('study-plan-objective-id', true);
      if (createMockQuestionBookForm.values.courseId && createMockQuestionBookForm.values.title && createMockQuestionBookForm.values.description && createMockQuestionBookForm.values['study-plan-objective-id']) {
        setCurrentStep(2);
        navigate('/simulados/criar/name');
      }
      return;
    } else if (step === 'curso' && editingQuestionBook) {
      createMockQuestionBookForm.setFieldTouched('courseId', true);
      createMockQuestionBookForm.setFieldTouched('title', true);
      createMockQuestionBookForm.setFieldTouched('description', true);
      if (!createMockQuestionBookForm.errors.courseId && !createMockQuestionBookForm.errors.title && !createMockQuestionBookForm.errors.description) {
        setCurrentStep(2);
        navigate(`/simulados/criar/name/${editingQuestionBook.id}`);
      }
      return;
    } else if (step === 'name' && !editingQuestionBook) {
      createMockQuestionBookForm.setFieldTouched('selectedTaxonomies', true);
      if (!createMockQuestionBookForm.errors.selectedTaxonomies) {
        navigate(`/simulados/criar/details`);
        setCurrentStep(3);
      }
      return;
    } else if (step === 'name' && editingQuestionBook) {
      createMockQuestionBookForm.setFieldTouched('selectedTaxonomies', true);
      if (!createMockQuestionBookForm.errors.selectedTaxonomies) {
        navigate(`/simulados/criar/details/${editingQuestionBook.id}`);
        setCurrentStep(3);
      }
    } else if (step === 'details') {
      createMockQuestionBookForm.setFieldTouched('resolutionTime', true);
      createMockQuestionBookForm.setFieldTouched('evaluationStart', true);
      createMockQuestionBookForm.setFieldTouched('evaluationStart.date', true);
      createMockQuestionBookForm.setFieldTouched('evaluationStart.hour', true);
      createMockQuestionBookForm.setFieldTouched('evaluationStart.minute', true);
      createMockQuestionBookForm.setFieldTouched('evaluationEnd.date', true);
      createMockQuestionBookForm.setFieldTouched('evaluationEnd.hour', true);
      createMockQuestionBookForm.setFieldTouched('evaluationEnd.minute', true);
      createMockQuestionBookForm.setFieldTouched('passingScore', true);
      createMockQuestionBookForm.setFieldTouched('correctionFactor', true);
      createMockQuestionBookForm.setFieldTouched('studyPlanNumWeeks', true);
      if (!createMockQuestionBookForm.errors.resolutionTime && !createMockQuestionBookForm.errors.evaluationStart && !createMockQuestionBookForm.errors.evaluationEnd && !createMockQuestionBookForm.errors.correctionFactor) {
        createExam();
      }
    }
  };
  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form form--step-by-step"
          method="post"
          onSubmit={handleSubmit}
        >
          <div className="page__content fadeIn u-mb-5">
            <div className="new-questions__header">
              <h2 className="new-questions__title">{t('exams.newSimulation')}</h2>
            </div>
            {step === 'curso' && (
              <CourseStep
                courses={courses}
                questionBook={editingQuestionBook}
                createMockQuestionBookForm={createMockQuestionBookForm}
                coursesLoading={coursesLoading}
                goals={goals}
                createGoal={createGoal}
              />
            )}
            {step === 'name' && <NameStep createMockQuestionBookForm={createMockQuestionBookForm} />}
            {step === 'details' && <DetailsStep createMockQuestionBookForm={createMockQuestionBookForm} />}

            <span className="form__text">
              <strong>* {t('warning.requiredField')}</strong>
            </span>
          </div>
          <div style={btnsStyles}>
            {step !== 'curso' && (
              <button
                type="button"
                className="btn btn--outline btn--wide u-mr-4"
                onClick={() => {
                  if (editingQuestionBook) {
                    navigate(`/simulados/criar/${steps[currentStep - 2].route}/${editingQuestionBook.id}`);
                    setCurrentStep(currentStep - 1);
                  } else {
                    navigate(`/simulados/criar/${steps[currentStep - 2].route}`);
                    setCurrentStep(currentStep - 1);
                  }
                }}
              >
                {t('exams.previous')}
              </button>
            )}
            <button
              type="submit"
              className="btn btn--primary btn--wide"
            >
              {step !== 'details' ? t('button.next') : t('button.finish')}
            </button>
          </div>
        </form>
      </div>

      <aside className="page__aside" />
    </div>
  );
}

export default MockQuestionBookContent;
