import React, { useState, useEffect } from 'react';
import Modal from 'app/components/Modal';
import { FiZap } from 'react-icons/fi';
import { ReactComponent as MedalSuccess } from 'app/images/icons/medal-success.svg';
import { ReactComponent as MedalWarning } from 'app/images/icons/medal-warning.svg';
import { useClient } from 'jsonapi-react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectCards } from 'swiper/modules';
import Flashcard from './Flashcard';

export default function FlashcardModal(props) {
  const { show, onClose, questions = [], taxonomyName, flashcardData, onChangePercentage, quantityFlashcardsAccepted, setRestart, restart, done, setDone, advice, setAdvice } = props;

  const client = useClient();
  const { t } = useTranslation();
  const [isTurned, setIsTurned] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [contRight, setContRight] = useState(quantityFlashcardsAccepted);
  const [skippedQuestion, setSkippedQuestion] = useState([]);
  const [responsePercentage, setResponsePercentage] = useState(0);
  const [cicleQuestions, setCicleQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [replied, setReplied] = useState(false);
  const [correctQuestionsIndex, setCorrectQuestionsIndex] = useState([]);

  async function handleSubmitQuestion(knowAnswer, acceptedAnswer, answered) {
    setIsSubmitting(true);
    const currentFlashcardId = cicleQuestions[currentQuestion]?.id;

    const { data, error } = await client.mutate(`/user_flashcards/${flashcardData.id}`, {
      'user-answer-flashcards-attributes': [
        {
          'flashcard-id': currentFlashcardId,
          'know-answer': knowAnswer,
          'accepted-answer': acceptedAnswer,
          answered: answered
        }
      ]
    });

    if (error) {
      console.log(error);
      setIsSubmitting(false);
      toast.error(t('toast.errorOnSendAnswerFlashCard'));
      return error;
    }

    if (done !== undefined) {
      setDone(!done && data['done-study-plan-video'] ? data['done-study-plan-video'] : done);
    }
    setIsSubmitting(false);
    setResponsePercentage(parseFloat(data?.percentage));
    onChangePercentage(parseFloat(data?.percentage)?.toFixed(0));
  }

  // function removeCorrectQuestion() {
  //   const correctQuestionId = cicleQuestions[currentQuestion]?.id;
  //   setSkippedQuestion(prevIds => prevIds?.filter(question => question?.id !== correctQuestionId));
  // }

  function accumulateQuestions() {
    const questionToAdd = cicleQuestions[currentQuestion];
    if (!skippedQuestion.some(q => q.id === questionToAdd.id)) {
      setSkippedQuestion(prevIds => [...prevIds, questionToAdd]);
    }
  }

  useEffect(() => {
    if (skippedQuestion.length === 0 && responsePercentage == 100) {
      setReplied(true);
    }

    if (responsePercentage < 100 || (replied && restart)) {
      setCicleQuestions(questions);
    } else {
      setCicleQuestions(skippedQuestion);
    }

    // if (!replied && currentQuestion + 1 === cicleQuestions.length && responsePercentage == 100) {
    //   setCurrentQuestion(0);
    // }
  }, [replied, skippedQuestion, questions, currentQuestion, responsePercentage]);

  useEffect(() => {
    if (!replied && responsePercentage === 100) {
      setAdvice(true);
    } else {
      setAdvice(false);
    }
  }, [responsePercentage, replied]);

  useEffect(() => {
    if (flashcardData.percentage) {
      setResponsePercentage(flashcardData.percentage);
    }
  }, [flashcardData]);

  useEffect(() => {
    setCurrentQuestion(0);
  }, [flashcardData]);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setCorrectQuestionsIndex([]);
      setCicleQuestions([]);
    }, 300);
  };
  return (
    <Modal
      show={show}
      onClose={() => handleClose()}
      contentClassName="modal__content--md"
    >
      <div className="flashcard-modal">
        <div className="card__progress">
          <div className="card__progress-container">
            <div
              className="card__progress-bar"
              role="progressbar"
              style={{ width: `${responsePercentage}%` }}
              aria-valuenow={responsePercentage}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
          <div className="card__progress-text">{`${responsePercentage?.toFixed(2)}%`}</div>
        </div>

        {responsePercentage === 100 && advice && !replied ? (
          <div className="flashcard-wrapper">
            <div className="flashcard-success">
              <div className="flashcard-success__inner">
                <MedalWarning className="flashcard-success__medal" />

                <h3 className="flashcard-success__title">
                  Você passou por todos os cards. <br />
                </h3>
                <p className="flashcard-success__description">
                  Revise os cards que você não recordou. <br />
                  Lembrando de todas, você concluirá este bloco de estudos.
                </p>
                <button
                  className="btn btn--wide btn--primary"
                  onClick={() => {
                    setAdvice(false);
                    setCurrentQuestion(0);
                  }}
                >
                  Revisar e concluir o bloco
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {(cicleQuestions.length > correctQuestionsIndex.length || restart) && !advice ? (
          <div className="flashcard-wrapper">
            <div className="flashcard-wrapper__inner">
              <div className="flashcard-wrapper__header">
                <h3 className="flashcard-wrapper__title">{taxonomyName}</h3>
                <p className="flashcard-wrapper__subtitle">
                  <FiZap /> {t('flashCard.review')}
                </p>
              </div>

              <Swiper
                slidesPerView={1}
                modules={[EffectCards]}
                effect="cards"
                loop
                noSwipingClass="my-no-swiping"
                enabled={restart}
                grabCursor={restart}
              >
                {cicleQuestions.map((question, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Flashcard
                        setCorrectQuestionsIndex={setCorrectQuestionsIndex}
                        correctQuestionsIndex={correctQuestionsIndex}
                        setContRight={setContRight}
                        contRight={contRight}
                        restart={restart}
                        accumulateQuestions={accumulateQuestions}
                        setCurrentQuestion={setCurrentQuestion}
                        currentQuestion={currentQuestion}
                        cicleQuestions={cicleQuestions}
                        responsePercentage={responsePercentage}
                        handleSubmitQuestion={handleSubmitQuestion}
                        question={question}
                        setIsTurned={setIsTurned}
                        isTurned={isTurned}
                        isSubmitting={isSubmitting}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        ) : null}

        {cicleQuestions?.length === correctQuestionsIndex.length && !restart ? (
          <div className="flashcard-success">
            <div className="flashcard-success__inner">
              <MedalSuccess className="flashcard-success__medal" />

              <h3 className="flashcard-success__title">
                {t('flashCard.successTitle')}
                {/* <br /> {t('flashCard.successPoints')} {contRight}/{allQuestionsQuantity} */}
              </h3>

              <p className="flashcard-success__description">{t('flashCard.successDescription')}</p>

              <button
                className="btn btn--wide btn--primary"
                onClick={onClose}
              >
                {t('flashCard.buttonReturnStudyPlan')}
              </button>
            </div>

            <button
              className="btn btn--wide btn--outline"
              onClick={() => {
                setRestart(true);
                setCicleQuestions(questions);
              }}
            >
              Responder novamente
            </button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
