import * as React from 'react';

function IconPerformance(props) {
  return (
    <svg
      stroke="currentColor"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_34682_14)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8 13.333V6.667M12 13.333V2.667M4 13.333v-2.666" />
        <g clipPath="url(#clip1_34682_14)">
          <path d="M9 1.667l-5 5M7.333 1.333h2v2" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_34682_14">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
          />
        </clipPath>
        <clipPath id="clip1_34682_14">
          <path
            fill="#fff"
            transform="translate(2.667 -.667)"
            d="M0 0H8V8H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPerformance;
