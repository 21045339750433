import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { t } from 'i18next';
import { Link } from '@reach/router';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import EssayCard from './EssayCard';
import CreateEssay from './CreateEssay';
import EssayMonitoring from './EssayMonitoring';
import EssayCorrection from './EssayCorrection';
import EssayConsult from './EssayConsult';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import EssayAbout from './EssayAbout';
import EssaySend from './EssaySend';
import EssayPrint from './EssayPrint';
import getPermission from 'app/utils/getPermission';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Essay(props) {
  const { uri } = props;

  const [courses, setCourses] = useState([]);
  const [essays, setEssays] = useState([]);
  const [loading, setLoading] = useState(false);
  const client = useClient();
  const isAdmin = !checkIncludeProfiles({ only: ['student'] });
  const [filteredEssays, setFilteredEssays] = useState([]);

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  const getCourses = async () => {
    return await client.fetch('courses/simple');
  };

  const getEssays = async () => {
    setLoading(true);
    try {
      const response = await client.fetch('essays');
      return response;
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    const [courses, essays] = await Promise.all([getCourses(), getEssays()]);
    if (courses.error) {
      toast.error('Erro ao carregar cursos');
    } else {
      setCourses(courses.data);
    }
    if (essays.error) {
      toast.error('Erro ao carregar redações');
    } else {
      setEssays(essays.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredEssays(essays.filter(item => item?.['courses-ids']?.includes(parseInt(filters.values.course)) || filters.values.course === 'blank'));
  }, [filters.values.course, essays]);

  const deleteEssay = async id => {
    const { error } = await client.delete(['essays', id]);
    if (error) {
      toast.error('Erro ao deletar redação');
    } else {
      toast.success('Redação excluida!');
      setEssays(prevState => prevState.filter(item => item.id !== id));
    }
  };

  const handleDeleteEssay = id => {
    Swal.fire({
      title: 'Deseja mesmo excluir esta redação?',
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteEssay(id);
      }
    });
  };

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: uri,
        name: 'Redação',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('exams.selectCourse')}
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
        </div>

        {getPermission('Criar redação', 'Redação') ? (
          <div className="filter-bar__actions">
            <Link
              className="btn btn--primary btn--wide"
              to={`/redacao/criar/curso`}
            >
              {'Nova redação'}
            </Link>
          </div>
        ) : null}
      </div>

      {loading && <Loader />}

      {filteredEssays?.length === 0 && !loading && <EmptyState type="content" />}

      <div className="fadeIn">
        <div className="tab__cards">
          {filteredEssays?.map(essay => (
            <EssayCard
              essay={essay}
              isAdmin={isAdmin}
              key={essay.id}
              deleteEssay={handleDeleteEssay}
            />
          ))}
        </div>
      </div>
    </main>
  );
}

export { Essay as default, CreateEssay, EssayMonitoring, EssayCorrection, EssayConsult, EssayAbout, EssaySend, EssayPrint };
