import React, { useState } from 'react';
import { navigate } from '@reach/router';
import menuIcon from 'app/images/icons/menu-icon.svg';
import Notebook from './Notebook';

function Sidebar(props) {
  const { children, hasBrand = true } = props;

  const [active, setActive] = useState(false);

  const mobileNavToggle = () => {
    setActive(!active);
  };

  const appName = process.env.REACT_APP_NAME;
  const Logo = require(`app/images/${appName}/horizontal-logo.svg`);

  return (
    <div className="main-sidebar">
      <div className="main-sidebar__mobile-hero">
        <div
          className="main-sidebar__mobile-hero-brand u-cursor-pointer"
          onClick={() => navigate('/')}
        >
          <img src={Logo} />
        </div>

        <div
          className="main-sidebar__mobile-hero-menu"
          onClick={mobileNavToggle}
        >
          <img
            alt=""
            src={menuIcon}
          />
        </div>
      </div>

      <div className={`main-sidebar__container ${active ? 'active' : ''}`}>
        {hasBrand && (
          <div
            className="main-sidebar__brand u-cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img src={Logo} />
          </div>
        )}

        {children}
      </div>
    </div>
  );
}

export default Sidebar;

Sidebar.Notebook = Notebook;
