import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from '@reach/router';
import { t } from 'i18next';
import Dropdown from 'app/components/Dropdown';
import getPermission from 'app/utils/getPermission';
import moment from 'moment';
import { statusBadgeColor, statusBadgeText } from './utils/BadgeEssay';

export default function EssayCard(props) {
  const { essay, isAdmin, deleteEssay } = props;

  const accepting_proposal = essay?.status === 'accepting_proposal';
  const reviewing = essay?.status === 'reviewing';

  const isCorrected = essay?.['essay-student']?.grade;
  const correctorName = essay?.['essay-student']?.corrector?.name;
  const grade = essay?.['essay-student']?.grade ?? 0;

  let badgeColor = '';
  let badgeText = '';

  if (accepting_proposal) {
    badgeColor = 'badge--attention';
    badgeText = statusBadgeText[essay?.status];
  } else {
    badgeColor = statusBadgeColor[essay?.status] ?? 'badge--success';
    badgeText = statusBadgeText[essay?.status];
  }

  if (reviewing) {
    badgeColor = 'badge--success';
    badgeText = statusBadgeText.unreviewed;
  } else {
    badgeColor = statusBadgeColor[essay?.status] ?? 'badge--success';
    badgeText = statusBadgeText[essay?.status];
  }

  return (
    <div className="card">
      <div className="card__header">
        <div className="card__inner-header u-mb-1">
          <h2
            className="card__title"
            title={essay?.topic}
          >
            {essay?.topic.length > 50 ? essay?.topic.slice(0, 50) + '...' : essay?.topic}
          </h2>
        </div>

        <span
          title={badgeText}
          className={`badge badge--tiny ${badgeColor}`}
        >
          {badgeText}
        </span>
      </div>

      <div className="card__body">
        {isCorrected ? (
          correctorName && essay?.status !== 'reviewing' ? (
            <div className="card__columns">
              <p className="card__count">
                <span className="u-w-100">{' Corrigida por'}</span>
                {correctorName}
              </p>

              <p className="card__count">
                <span className="u-w-100">{' Nota'}</span>
                {grade}
              </p>
            </div>
          ) : (
            <p className="card__count">
              <span className="u-w-100">{' Nota'}</span>
              {grade}
            </p>
          )
        ) : (
          <p className="card__count">
            <span className="u-w-100">{'Entrega até'}</span>
            {moment(essay?.['delivery-date']).format('DD/MM/YYYY')}
          </p>
        )}
      </div>

      <div className="card__footer">
        {isAdmin ? (
          <div
            className="btn-group btn-group--with-dropdown"
            role="group"
            aria-label="Ações"
          >
            <Link
              to={`/redacao/acompanhamento/${essay?.id}`}
              className="btn btn--outline btn--small"
            >
              {t('button.access')}
            </Link>
            {getPermission('Excluir redação', 'Redação') || getPermission('Editar redação', 'Redação') ? (
              <Dropdown className="btn--small btn btn--outline">
                <Dropdown.Toggle>
                  <FiChevronDown />
                </Dropdown.Toggle>

                <Dropdown.Content>
                  {getPermission('Editar redação', 'Redação') && <Dropdown.Item to={`/redacao/editar/curso/${essay?.id}`}>{t('button.edit')}</Dropdown.Item>}
                  {getPermission('Excluir redação', 'Redação') && <Dropdown.Item onClick={() => deleteEssay(essay?.id)}>{t('button.delete')}</Dropdown.Item>}
                </Dropdown.Content>
              </Dropdown>
            ) : null}
          </div>
        ) : essay?.status === 'reviewed' || essay.status === 'unreviewed' || essay.status === 'reviewing' || essay.status === 'annulled' ? (
          <Link
            to={`/redacao/consulta/${essay?.id}/${essay?.['essay-student']?.id}`}
            className="btn btn--outline btn--small"
          >
            Acessar correção
          </Link>
        ) : essay?.status === 'close_for_submissions' ? null : (
          <Link
            to={`/redacao/sobre/${essay?.id}`}
            className={`btn btn--outline btn--small ${essay?.status === 'Em correção' && 'disabled'}`}
          >
            Acessar redação
          </Link>
        )}
      </div>
    </div>
  );
}
