import React from 'react';
import { BiJoystick } from 'react-icons/bi';
import { AiOutlineTrophy } from 'react-icons/ai';
import { FiClock } from 'react-icons/fi';
import { TfiMedall } from 'react-icons/tfi';
import medalPoints from './img/medal-points.svg';
import { ReactComponent as Stars } from './img/stars.svg';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import { useClient, useQuery } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function Success(props) {
  const { onClose } = props;
  const { currentChallenge, rightAnswers, challengeCurrentStatus, generalCount } = useWeeklyChallenge();

  const { data: position } = useQuery('/challenge_attempt_items/position');

  const minutes = Math.floor(generalCount / 60);
  const seconds = generalCount % 60;
  const timeFormat = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return (
    <section className="challenge-modal__section challenge-modal__section--success">
      <div className="challenge-success">
        <div className="challenge-success__header">
          <img
            className="avatar avatar--md"
            src={currentChallenge?.image}
            alt="foto"
          />

          <h3 className="challenge-success__title">{currentChallenge?.title}</h3>
          <p className="challenge-success__subtitle">
            <BiJoystick className="challenge-success__icon" /> Desafio
          </p>
        </div>

        <div className="challenge-success__body">
          <div className="challenge-success__message">
            <h4 className="challenge-success__message-title">É isso aí!</h4>
            <p className="challenge-success__message-subtitle">Seu esforço valeu a pena</p>
          </div>

          <div className="challenge-success__points">
            <p className="challenge-success__total">{challengeCurrentStatus?.score}</p>
            <img
              src={medalPoints}
              alt="medalha de pontos"
            />
            <Stars className="challenge-success__stars" />
          </div>

          <div className="challenge-success__kpis-list">
            <div className="challenge-success__kpi">
              <h5 className="challenge-success__kpi-title">
                Acertos <TfiMedall />
              </h5>
              <p className="challenge-success__kpi-value">{rightAnswers}</p>
            </div>
            <div className="challenge-success__kpi">
              <h5 className="challenge-success__kpi-title">
                Tempo <FiClock />
              </h5>
              <p className="challenge-success__kpi-value">{timeFormat}</p>
            </div>
            <div className="challenge-success__kpi">
              <h5 className="challenge-success__kpi-title">
                Posição <AiOutlineTrophy />
              </h5>
              <p className="challenge-success__kpi-value">
                <span className="challenge-success__kpi-icon">
                  <AiOutlineTrophy />
                </span>{' '}
                {position?.position}º lugar
              </p>
            </div>
          </div>

          <button
            className="btn btn--full btn--outline"
            onClick={onClose}
          >
            Voltar para desafios
          </button>
        </div>
      </div>
    </section>
  );
}
