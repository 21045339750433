import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { FiMoreHorizontal } from 'react-icons/fi';
import toast from 'react-hot-toast';

import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import NewInvitationModal from './NewInvitationModal';
import EmptyState from 'app/components/EmptyState';
import Dropdown from 'app/components/Dropdown';
import Select from 'react-select';
import TabNavBar from '../TabNavBar';
import { useCourse } from 'app/hooks/useCourse';
import { Link } from '@reach/router';
import Loader from 'app/components/loader';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useSession } from 'app/hooks/useSession';
import { navigate } from '@reach/router';
import { t } from 'i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function ClassroomInvitations(props) {
  const { uri } = props;

  const client = useClient();
  const [show, setShow] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [classrooms, setClassrooms] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const { courses, getSimpleCourses } = useCourse();
  const [filteredInvitations, setFilteredInvitations] = useState([]);
  const appName = window.location.host;
  const { session } = useSession();

  const filters = useFormik({
    initialValues: {
      classroom: 'blank',
      institution: 'blank',
      course: 'blank'
    }
  });

  const getClassrooms = async () => {
    const { data } = await client.fetch('classrooms/simple');
    setClassrooms(data);
  };

  const getInvitations = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('classroom_invitations');
    if (error) {
      toast.error('Erro ao encontrar convites.');
    } else {
      setInvitations(data);
      setLoading(false);
    }
  };

  const getInstitutions = async () => {
    const { data, error } = await client.fetch('/institutions');
    if (error) {
      toast.error('Erro ao buscar instituições');
    } else {
      setInstitutions(data);
    }
  };

  const handleCopyLink = async invitation => {
    try {
      await navigator.clipboard.writeText(`${appName}/criar-uma-conta/${invitation.link}`);
      toast.success('Link copiado para sua área de transferência.');
    } catch (err) {
      toast.error(`Falha ao copiar: ${err}`);
    }
  };

  const handleDelete = id => {
    Swal.fire({
      title: t('warning.warningDeleteInvite'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteInvitation(id);
      }
    });
  };

  const deleteInvitation = async id => {
    const { error } = await client.delete(`classroom_invitations/${id}`);
    if (error) {
      toast.error('Erro ao excluir convite.');
    } else {
      getInvitations();
    }
  };

  useEffect(() => {
    if (!session.user.profileNames.includes('Aluno')) {
      getClassrooms();
      getInvitations();
      getInstitutions();
      getSimpleCourses();
    }
  }, [session]);

  useEffect(() => {
    if (session.user.profileNames.includes('Aluno')) {
      navigate('/');
    }
  }, [session]);

  const filterClassroomByInstitution = classrooms.filter(item => {
    return filters.values.institution === 'blank' || item?.institution?.id === filters.values.institution;
  });

  const filterClassroomByCourse = filterClassroomByInstitution.filter(item => {
    return filters.values.course === 'blank' || item.course?.id === parseInt(filters.values.course);
  });

  const filterInvitationByInstitution = invitations.filter(item => {
    return filters.values.institution === 'blank' || item.classroom?.institution?.id === filters.values.institution;
  });

  const filterInvitationByCourse = filterInvitationByInstitution.filter(item => {
    return filters.values.course === 'blank' || item?.classroom?.course?.id === parseInt(filters.values.course);
  });

  const filterInvitationByClassroom = filterInvitationByCourse.filter(item => {
    return filters.values.classroom === 'blank' || item?.classroom?.id === parseInt(filters.values.classroom);
  });

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Turmas',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <TabNavBar selectedTab="convites" />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label className="filter-bar__label">{t('filter.institution')}</label>
            <Select
              defaultValue={filters.values.institution}
              openMenuOnFocus
              options={[
                { value: 'blank', label: t('filter.blankLabelAll') },
                ...institutions?.map(item => {
                  return { value: item.id, label: item.name };
                })
              ]}
              className="react-multi-select filter-bar__multi-select"
              classNamePrefix="react-multi-select"
              placeholder={t('filter.blankLabelAll')}
              noOptionsMessage={() => 'Sem opções'}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => filters.setFieldValue('institution', e.value)}
              style={{ width: '300px' }}
            />
          </div>
          <FilterSelectionBox
            label="Selecione um curso"
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={courses.map(item => ({
              id: item.id,
              name: item.title
            }))}
          />
          <FilterSelectionBox
            label={t('createAccount.selectClass')}
            value={filters.values.classroom}
            onChange={e => filters.setFieldValue('classroom', e.target.value)}
            options={filterClassroomByCourse.map(item => ({
              id: item.id,
              name: item.title
            }))}
          />
        </div>

        <div className="filter-bar__actions">
          <button
            className="btn btn--wide btn--primary btn--wide"
            onClick={() => setShow(true)}
          >
            {t('classes.buttonGenerateLink')}
          </button>
        </div>
      </div>

      {loading && <Loader />}

      {!loading && (
        <div className="round-dg-wrapper">
          <table
            className="round-dg round-dg--light"
            style={{ borderSpacing: '0 10px' }}
          >
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th
                  className="round-dg__cell-header"
                  style={{ width: '140px' }}
                >
                  {t('textsCommon.class')}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '140px' }}
                >
                  {t('filter.institution')}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '140px' }}
                >
                  Curso
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '140px' }}
                >
                  {t('classes.thIdentifier')}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '112px' }}
                >
                  {t('classes.thRegistrationCount')}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '110px' }}
                >
                  {t('classes.thSendLink')}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '116px' }}
                >
                  {t('classes.thSendingDate')}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '66px' }}
                />
              </tr>
            </thead>

            <tbody className="round-dg__body">
              {filterInvitationByClassroom?.map((invitation, index) => (
                <tr
                  className="round-dg__row"
                  key={index}
                >
                  <th className="round-dg__cell-header round-dg__cell-header--row">{invitation?.classroom?.title}</th>
                  <th className="round-dg__cell-header round-dg__cell-header--row">{invitation?.classroom?.institution?.name}</th>
                  <th className="round-dg__cell-header round-dg__cell-header--row">{invitation?.classroom?.course?.name}</th>
                  <td className="round-dg__cell">
                    <span className="expanded-calendar__tag expanded-calendar__tag--success">#{invitation.link}</span>
                  </td>
                  <td className="round-dg__cell">
                    <Link
                      to={`/turmas/convites/${invitation.id}`}
                      className="btn btn--link u-p-0"
                    >
                      {invitation['student-count']} {t('classes.users')}
                    </Link>
                  </td>
                  <td className="round-dg__cell">
                    <button
                      className="btn btn--link u-p-0"
                      onClick={() => handleCopyLink(invitation)}
                    >
                      {t('classes.copyLink')}
                    </button>
                  </td>
                  <td className="round-dg__cell">{moment(invitation['created-at']).format('DD/MM/YYYY')}</td>
                  <td className="round-dg__cell">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FiMoreHorizontal />
                      </Dropdown.Toggle>

                      <Dropdown.Content>
                        {invitation['student-count'] === 0 && <Dropdown.Item onClick={() => handleDelete(invitation.id)}>{t('button.delete')}</Dropdown.Item>}
                        <Dropdown.Item to={`/turmas/convites/${invitation.id}`}>{t('classes.buttonDetails')}</Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!loading && filterInvitationByClassroom.length === 0 && <EmptyState type="data" />}
        </div>
      )}

      <NewInvitationModal
        show={show}
        onClose={() => {
          setShow(false);
          getInvitations();
        }}
        classrooms={classrooms}
        courses={courses}
        institutions={institutions}
      />
    </main>
  );
}
