import React from 'react';
import { FiEdit, FiVideo } from 'react-icons/fi';
import { Link } from '@reach/router';
import { FiMessageSquare } from 'react-icons/fi';
import getPermission from 'app/utils/getPermission';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

export default function FrequentShortcutWidget() {
  const { abilities } = usePlatformAbilities();

  return (
    <div className="frequent-shortcut-widget-wrapper">
      <h3 className="frequent-shortcut-widget-wrapper__title">Atalhos frequentes</h3>

      <div className="frequent-shortcut-widget">
        {getPermission('Criar cursos', 'Cursos') && abilities?.cursos ? (
          <Link
            className="frequent-shortcut-widget__item"
            to="/curso/criar/sobre"
          >
            <FiVideo className="frequent-shortcut-widget__icon" />
            <span className="frequent-shortcut-widget__link-text">Novo curso</span>
          </Link>
        ) : null}
        {getPermission('Criar monitorias', 'Monitorias') && abilities?.auditorio ? (
          <Link
            className="frequent-shortcut-widget__item"
            to="/auditorio/criar/nome"
          >
            <FiMessageSquare className="frequent-shortcut-widget__icon" />
            <span className="frequent-shortcut-widget__link-text">Novo auditório</span>
          </Link>
        ) : null}

        {getPermission('Criar redação', 'Redação') && abilities?.redacao ? (
          <Link
            className="frequent-shortcut-widget__item"
            to="/redacao/criar/curso"
          >
            <FiEdit className="frequent-shortcut-widget__icon" />
            <span className="frequent-shortcut-widget__link-text">Novo redação</span>
          </Link>
        ) : null}
      </div>
    </div>
  );
}
