import React, { useEffect, useRef, useState } from 'react';
import mockAvatar from './img/mockAvatar.png';
import { FiClock } from 'react-icons/fi';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';

export default function QuestionCountdown({ showQuestionCountDown }) {
  const { setCurrentStep, setStart, currentStep, setShowQuestionCountDown, questionCountDownTimer, setQuestionCountDownTimer, currentTaxonomy, currentChallenge } = useWeeklyChallenge();
  let intervalRef = useRef();

  useEffect(() => {
    if (showQuestionCountDown && currentStep === 1) {
      intervalRef.current = setInterval(() => {
        if (questionCountDownTimer > 0) setQuestionCountDownTimer(questionCountDownTimer - 1);
      }, 1000);

      return () => clearInterval(intervalRef.current);
    }
  }, [showQuestionCountDown, questionCountDownTimer]);

  useEffect(() => {
    if (questionCountDownTimer === 0) {
      setShowQuestionCountDown(false);
      setCurrentStep(currentStep + 1);
      setStart(true);
    }
  }, [questionCountDownTimer]);

  useEffect(() => {
    setQuestionCountDownTimer(5);
  }, [currentTaxonomy]);

  if (!showQuestionCountDown) return null;
  return (
    <div className="question-countdown">
      <div className="question-countdown__content">
        <div className="question-countdown__header">
          <img
            className="avatar"
            src={currentChallenge?.image}
            alt="foto"
          />

          <p className="question-countdown__hat">Tema escolhido:</p>
          <h3 className="question-countdown__title">{currentTaxonomy?.name}</h3>
        </div>

        <div className="question-countdown__body">
          <p className="question-countdown__text">Prepare-se!</p>

          <span className="btn btn--outline">
            <FiClock className="btn__icon" /> 00:0{questionCountDownTimer}
          </span>
        </div>
      </div>
    </div>
  );
}
