import React from 'react';
import engagement1 from 'app/images/engagement-1.svg';
import engagement2 from 'app/images/engagement-2.svg';
import { Link } from '@reach/router';

export default function EngagementWidget() {
  return (
    <section className="engagement-widget">
      <div className="engagement-widget__header  u-mb-2">
        <h3 className="engagement-widget__title">Como melhorar o engajamento dos estudantes?</h3>
        {/* <Link
          className="btn btn--link u-p-0"
          to="/"
        >
          Ver relatório de insights
        </Link> */}
      </div>

      <div className="engagement-card-wrapper">
        <div className="engagement-card">
          <div className="engagement-card__header">
            <img
              className="engagement-card__img"
              src={engagement1}
              alt="homem no computador"
            />
          </div>

          <div className="engagement-card__body">
            <h3 className="engagement-card__title">Nova Ferramenta de Insights para Professores</h3>
            <p className="engagement-card__subtitle">Em breve, a funcionalidade de Insights chegará à nossa plataforma! Com análises detalhadas do desempenho e engajamento dos alunos, prepare-se para transformar sua sala de aula!</p>
          </div>
        </div>

        {/* <div className="engagement-card">
          <div className="engagement-card__header">
            <img
              className="engagement-card__img"
              src={engagement2}
              alt="homem no computador"
            />
          </div>

          <div className="engagement-card__body">
            <h3 className="engagement-card__title">Melhore o desempenho em redação</h3>
            <p className="engagement-card__subtitle">
              O <strong>rendimento em redação caiu</strong> nos 3 últimos meses.
            </p>
          </div>
        </div> */}
      </div>
    </section>
  );
}
