import React, { useState } from 'react';
import { useFormik } from 'formik';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import Loader from '../../components/loader';
import { useSurvey } from 'app/hooks/useSurvey';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const SurveyForm = ({ relationshipsLoading, relationships, activeSurvey, onClose }) => {
  const { t } = useTranslation();
  const [selectedAlternative, setSelectedAlternative] = useState(0);
  const { createSurvey, updateSurvey } = useSurvey();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [estrela, setEstrela] = useState(false);
  const [texto, setTexto] = useState(false);

  const quizAlternatives = [
    { id: 0, name: t('events.toggleCourse') },
    { id: 1, name: t('events.togglePublic') }
  ];

  const surveyId = activeSurvey?.id;

  const schema = Yup.object({
    title: Yup.string().required(t('warning.requiredField')),
    'course-ids': Yup.array().of(Yup.string()).min(1, t('warning.requiredField')).required(t('warning.requiredField'))
  });

  const formSurvey =
    activeSurvey != null
      ? useFormik({
          initialValues: {
            title: activeSurvey.title,
            description: activeSurvey.description,
            'finished-at': activeSurvey['finished-at'],
            'published-at': activeSurvey['published-at'],
            'course-ids': activeSurvey['course-ids'],
            'classroom-ids': activeSurvey['classroom-ids'],
            representation: activeSurvey['representation']
          },
          onSubmit: values => {
            updateSurvey(surveyId, { ...values });
            onClose();
          }
        })
      : useFormik({
          initialValues: {
            title: '',
            'published-at': new Date(),
            'finished-at': new Date(),
            'course-ids': [],
            'classroom-ids': [],
            'is-public': true,
            representation: []
          },
          validationSchema: schema,
          onSubmit: async values => {
            setIsSubmitting(true);
            await createSurvey({ ...values });
            setIsSubmitting(false);
            onClose();
          }
        });

  const today = moment().format(t('datesForm.formatDate'));

  return (
    <>
      {formSurvey && (
        <form
          method="post"
          onSubmit={formSurvey.handleSubmit}
        >
          <h2 className="form__title">{t('nps.newQuiz')}</h2>

          {relationshipsLoading ? (
            <Loader />
          ) : (
            <>
              <div className="form__row">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Escolha o tipo de caderno"
                >
                  {quizAlternatives.map((alternative, index) => (
                    <React.Fragment key={index}>
                      <input
                        type="radio"
                        name="notebookType"
                        id={alternative.id}
                        autoComplete="off"
                        defaultChecked={index === selectedAlternative}
                        onChange={() => {
                          if (alternative.name === 'Público') {
                            formSurvey.setFieldValue('course-ids', []);
                            formSurvey.setFieldValue('is-public', true);
                          }
                          if (alternative.name === 'Curso') {
                            formSurvey.setFieldValue('course-id', []);
                          } else if (['Curso'].includes(alternative.name)) {
                            formSurvey.setFieldValue('is-public', false);
                          }
                          setSelectedAlternative(alternative.id);
                        }}
                      />
                      <label
                        htmlFor={alternative.id}
                        className="btn btn--outline"
                      >
                        {alternative.name}
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              {selectedAlternative === 0 && (
                <div className="form__row form__row--hr">
                  <select
                    className="form__select"
                    name="courses"
                    id="courses"
                    aria-label="Escolha o curso"
                    value={formSurvey.values['course-ids'][0]}
                    onChange={e => formSurvey.setFieldValue('course-ids', [e.target.value])}
                  >
                    <option>{t('filter.labelSelectTheCourse')}</option>
                    {relationships.courses.map(c => (
                      <option
                        value={c.id}
                        key={c.id}
                      >
                        {c.title}
                      </option>
                    ))}
                  </select>
                  {formSurvey.touched['course-ids'] && formSurvey.errors['course-ids'] && <span style={{ color: 'red' }}>{formSurvey.errors['course-ids']}</span>}
                </div>
              )}

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="published-at"
                >
                  {t('nps.resolutionPeriod')}
                </label>

                <div className="form__columns">
                  <div className="form__col">
                    <input
                      className="form__control"
                      id="published-at"
                      name="published-at"
                      type="date"
                      min={today}
                      value={moment(formSurvey.values['published-at']).format('YYYY-MM-DD')}
                      onChange={e => formSurvey.setFieldValue('published-at', e.target.value)}
                    />
                  </div>
                  <div className="form__col">
                    <input
                      className="form__control"
                      id="finished-at"
                      name="finished-at"
                      type="date"
                      min={today}
                      value={moment(formSurvey.values['finished-at']).format('YYYY-MM-DD')}
                      onChange={e => formSurvey.setFieldValue('finished-at', e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="title"
                >
                  {t('nps.quizTitle')}
                </label>
                <input
                  className="form__control"
                  id="title"
                  name="title"
                  type="text"
                  placeholder={t('nps.placeholderQuizTitle')}
                  value={formSurvey.values.title}
                  onChange={formSurvey.handleChange}
                />
                 {formSurvey.touched.title && formSurvey.errors.title && <span style={{ color: 'red' }}>{formSurvey.errors.title}</span>}
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="title"
                >
                  {t('tasks.description')}
                </label>
                <input
                  className="form__control"
                  id="description"
                  name="description"
                  type="text"
                  placeholder={t('nps.placeholderDescription')}
                  value={formSurvey.values.description}
                  onChange={formSurvey.handleChange}
                />
              </div>

              <div className="filter-bar">
                <div className="filter-bar__inner">
                  <FilterCheckBox
                    label={t('nps.stars')}
                    value={estrela}
                    checked={formSurvey.values.representation === 'stars'}
                    onChange={() => {
                      setEstrela(true);
                      formSurvey.setFieldValue('representation', 'stars');
                      setTexto(false);
                    }}
                  />
                  <FilterCheckBox
                    label={t('nps.openReponses')}
                    value={texto}
                    checked={formSurvey.values.representation === 'text'}
                    onChange={() => {
                      setTexto(true);
                      formSurvey.setFieldValue('representation', 'text');
                      setEstrela(false);
                    }}
                  />
                </div>
              </div>

              <button
                type="submit"
                className={`btn btn--primary u-ml-auto ${isSubmitting && 'disabled'}`}
                onClick={formSurvey.handleSubmit}
              >
                {t('button.save')}
              </button>
            </>
          )}
        </form>
      )}
    </>
  );
};
