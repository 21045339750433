import React from 'react';
import { FiMap, FiMenu } from 'react-icons/fi';
import { Link } from '@reach/router';

export default function ShortcutWidgetAdmin() {
  return (
    <div className="shortcut-widget u-mb-4">
      <Link
        to="/plano-de-aulas/inicio"
        className="shortcut-widget__item shortcut-widget__item--study-plan"
      >
        <span className="shortcut-widget__icon">
          <FiMap />
        </span>
          <h3 className="shortcut-widget__title">Plano de Aulas</h3>
      </Link>
    </div>
  );
}
