import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Modal from 'app/components/Modal';
import QuestionCard from './QuestionCard';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import { useClient } from 'jsonapi-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'app/components/loader';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';
import EmptyState from 'app/components/EmptyState';
import { useSession } from 'app/hooks/useSession';

export default function AddQuestionModal(props) {
  const location = useLocation();
  const pageUrl = location.pathname;
  const currentUser = useSession().session?.user;
  const { t } = useTranslation();
  const { show, onClose, setSelectedQuestions, selectedQuestions, selectedQuestionBook, addQuestion, removeQuestion, filteredQuestions, selectedTaxonomy, selectedExam } = props;
  const [questions, setQuestions] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState(questions);
  const [loading, setLoading] = useState(false);
  const client = useClient();

  function getExamType(url) {
    const parts = url.split('/');
    const value = parts[1];
    return value;
  }

  const examType = getExamType(pageUrl);

  const getQuestions = async () => {
    let url;
    switch (examType) {
      case 'simulados':
        url = `questions?filter[visibility]=[mock, exam_and_mock, train]&filter[taxonomies.id]=${selectedTaxonomy?.taxonomy_id}&page[size]=200&simple=true`;
        break;
      case 'apostilas':
        url = `questions?filter[visibility]=[train]&filter[taxonomies.id]=${selectedTaxonomy?.taxonomy_id}&page[size]=200&simple=true`;
        break;
      case 'provas':
        url = `questions?filter[visibility]=[exam, exam_and_mock, train]&filter[taxonomies.id]=${selectedTaxonomy?.taxonomy_id}&page[size]=200&simple=true`;
        break;
    }
    setLoading(true);
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorGetQuestions'));
    } else {
      setQuestions(data.filter(item => {
        if (item.published !== true) return false;      
        if (item['authored-by-user']) {
          return item?.creator?.id === currentUser?.id;
        }      
        return true;
      }));
    }
    setLoading(false);
  };

  const filters = useFormik({
    initialValues: {
      modality: 'blank',
      unpublished: false,
      searchTerm: ''
    }
  });

  const modalitys = [
    { name: t('exams.selectTrueOrFalse'), id: 2 },
    {
      name: t('exams.selectMultipleChoice'),
      id: 1
    }
  ];
  const onCloseModal = () => {
    onClose();
    setTimeout(() => setQuestions([]), 500);
  };
  useEffect(() => {
    if (show) {
      getQuestions();
    }
  }, [filters.values.modality, selectedTaxonomy?.taxonomy_id, show]);

  useEffect(() => {
    if (filters.values.searchTerm == '') {
      setCurrentQuestions(questions);
    } else {
      const filter = filters.values.searchTerm;
      const filterText = text => {
        return text?.toLowerCase()?.includes(filter.toLocaleLowerCase());
      };
      setCurrentQuestions(
        questions?.filter(question => {
          return filterText(question?.code) || filterText(question?.description) || filterText(question?.['motivator-texts']?.[0]?.body) || filterText(question?.id?.toString());
        })
      );
    }

    if (filters.values.modality == 1) {
      setCurrentQuestions(questions.filter(item => item.modality == 'multiple_choice'));
    }

    if (filters.values.modality == 2) {
      setCurrentQuestions(questions.filter(item => item.modality == 'right_wrong'));
    }
  }, [filters.values, show, questions]);

  useEffect(() => {
    if (show) {
      filters.setFieldValue('searchTerm', '');
      filters.setFieldValue('modality', 'blank');
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onClose={onCloseModal}
      contentClassName="modal__content--md"
    >
      <h3 className="modal__simple-title">
        {t('exams.addQuestionTitle')} {selectedTaxonomy?.name}
      </h3>

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('filter.selectQuestionType')}
            blankLabel={t('filter.blankLabelAll')}
            value={filters.values.modality}
            onChange={e => {
              filters.setFieldValue('modality', e.target.value);
            }}
            options={modalitys.map(item => item)}
          />

          <FilterCheckBox
            label={t('filter.checkboxUnreleasedQuestions')}
            value={filters.values.unpublished}
            onChange={() => filters.setFieldValue('unpublished', !filters.values.unpublished)}
          />
          <input
            style={{ position: 'sticky', top: '0' }}
            className="form__control u-mb-3"
            type="search"
            placeholder={t('exams.placeholderSearchQuestion')}
            onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
          />
        </div>
      </div>

      <div
        className="round-dg-wrapper"
        style={{ maxHeight: '70vh' }}
      >
        {loading && <Loader />}
        {!loading && currentQuestions?.length === 0 ? (
          <EmptyState type="content" />
        ) : (
          <table className="round-dg round-dg--light">
            <tbody className="round-dg__body">
              {currentQuestions?.map((question, index) => {
                const selectedQuestion = filteredQuestions.find(item => item.id === question.id);
                return (
                  <QuestionCard
                    removeQuestion={removeQuestion}
                    addQuestion={addQuestion}
                    selectedQuestionBook={selectedQuestionBook}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                    question={question}
                    isSelected={!!selectedQuestion}
                    addBtn
                    hideActions
                    id={index}
                    key={index}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Modal>
  );
}
