import React, { useState, useEffect } from 'react';
import { useSession } from '../../hooks/useSession';
import { FiAlertTriangle, FiChevronLeft } from 'react-icons/fi';
import ClassroomTabNavBar from './ClassroomTabNavBar';
import CourseClassroomVideo from './CourseClassroomVideo';
import CourseClassroomComments from './CourseClassroomComments';
import CourseClassroomFiles from './CourseClassroomFiles';
import ClassroomSidebar from './ClassroomSidebar';
import CourseClassroomQuestionBooks from './CourseClassroomQuestionBooks';
import CourseClassroomActivities from './CourseClassroomActivities';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useParams } from '@reach/router';
import SucessModal from 'app/components/SuccessModal';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Modal from 'app/components/Modal';
import QuizModal from './QuizModal';
import { Link, navigate } from '@reach/router';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import Loader from 'app/components/loader';
import Error404 from 'app/components/Error404';
import BreadCrumbs from 'app/components/BreadCrumbs';
import DisclaimerModal from 'app/components/Disclaimer Modal';

function CourseClassroom(props) {
  const { uri } = props;

  const { t } = useTranslation();
  const [activeTabs, setActiveTabs] = useState('courseClassroomFiles');
  const client = useClient();
  const { id, lessonId } = useParams();
  const [learningSystemContents, setLearningSystemContents] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedContent, setSelectedContent] = useState();
  const [courseTitle, setCourseTitle] = useState();
  const { session } = useSession();
  const [learningSystem, setLearnigSystem] = useState(null);
  const [learningSystemUser, setLearningSystemUser] = useState();
  const [learningSystemUserItem, setLearningSystemUserItem] = useState();
  const [updateContentNow, setUpdateContentNow] = useState(false);
  const [emptyTabs, setEmptyTabs] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState();
  const [answered, setAnswered] = useState(false);
  const [answeredQuestionsCount, setAnsweredQuestionsCount] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [unfinishedExam, setUnfinishedExam] = useState();
  const [courseFinished, setCourseFinished] = useState(false);
  const [canCloseModal, setCanCloseModal] = useState(false);
  const { abilities } = usePlatformAbilities();
  const authorityLevel = getAuthorityLevel();
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [finishVideoModal, setFinishVideoModal] = useState(false);
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const [filteredQuestionIds, setFilteredQuestionIds] = useState([]);

  const getLearningSytem = async () => {
    const { error, data } = await client.fetch(`learning_systems/${id}?detailed=true&with_question_books=true`);
    if (error) {
      setNotFound(true);
    } else {
      setLearnigSystem(data);
    }
  };

  const getLearnSystemContents = async () => {
    const url = `learning_system_contents?filter[learning_system_id]=${id}`;
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      if (authorityLevel === 'student') {
        setLearningSystemContents(filterLearningSystemContents(data));
      } else {
        setLearningSystemContents(data);
      }
      setCourseTitle(data[0]?.['learning-system']?.title);

      if (!lessonId) {
        getLastUnlockLearningSystemItem(data);
      }
    }
  };

  useEffect(() => {
    if (lessonId && learningSystemContents) {
      const selectedContent = learningSystemContents.find(discipline => {
        const selectedLesson = discipline?.['learning-system-items'].find(lesson => lesson.id === parseInt(lessonId));
        setSelectedItem(selectedLesson);
        return selectedLesson;
      });
      setSelectedContent(selectedContent);
    }
  }, [lessonId, learningSystemContents]);

  const filterQuestionIds = () => {
    const filteredIds = learningSystem?.['learning-system-question-books'].filter(question => question['learning-system-item-id'] === selectedItem?.id).map(question => question['question-book'].id);
    setFilteredQuestionIds(filteredIds);
  };

  function filterLearningSystemContents(array) {
    const filteredContents = [];
    for (let i = 0; i < array.length; i++) {
      const content = array[i];
      if (content.hasOwnProperty('learning-system-items') && content['learning-system-items'].length > 0) {
        filteredContents.push(content);
      }
    }
    return filteredContents;
  }

  const updateLearningSystemContents = learningSystemUserItem => {
    const newData = learningSystemContents?.map(content => {
      content['learning-system-items'] = content?.['learning-system-items']?.map(lsi => {
        if (lsi?.['learning-system-user-items']?.length > 0 && lsi?.['learning-system-user-items'][0]?.id === learningSystemUserItem.id) {
          lsi['learning-system-user-items'][0].percent = learningSystemUserItem.percent;
          lsi['learning-system-user-items'][0].finished = learningSystemUserItem.finished;
        }
        return lsi;
      });
      return content;
    });

    setLearningSystemContents(newData);
  };

  const createLearningSystemUser = async () => {
    const { error, data } = await client.mutate('learning_system_users', {
      percent: 0,
      'learning-system-id': id,
      finished: false
    });

    if (!error) {
      setLearningSystemUser(data);
    }
  };

  const checkLearningSystemUser = async () => {
    const url = `learning_system_users?filter[user_id]=${session?.user?.id}&filter[learning_system_id]=${id}`;
    const { data, error } = await client.fetch(url);

    if (!error && data.length > 0) {
      setLearningSystemUser(data[0]);
      if (data[0].course.status === 'completed') {
        setCourseFinished(true);
      }
    } else {
      createLearningSystemUser();
    }
  };

  const createLearningSystemUserItem = async () => {
    const { error, data } = await client.mutate('learning_system_user_items', {
      'learning-system-item-id': selectedItem?.id,
      'learning-system-user-id': learningSystemUser?.id,
      percent: 0
    });

    if (!error) {
      const newLearningSystemContents = learningSystemContents.map(item => {
        item['learning-system-items'] = item['learning-system-items'].map(lsi => {
          if (lsi.id === selectedItem?.id) {
            lsi['learning-system-user-items'].push(data);
          }
          return lsi;
        });
        return item;
      });

      setLearningSystemContents(newLearningSystemContents);
      setLearningSystemUserItem(data);
    }
  };

  const checkLearningSystemUserItem = async () => {
    const url = `learning_system_user_items?filter[learning_system_user_id]=${learningSystemUser?.id}&filter[learning_system_item_id]=${selectedItem?.id}`;
    const { data, error } = await client.fetch(url);

    if (!error && data.length > 0) {
      setLearningSystemUserItem(data[0]);
    } else {
      createLearningSystemUserItem();
    }
  };

  const getLastUnlockLearningSystemItem = data => {
    let flag = false;
    for (const d of data) {
      for (const lsi of d?.['learning-system-items']) {
        if (!lsi['learning-system-user-items'][0]?.finished) {
          selectedContent?.id !== d.id && setSelectedContent(d);
          if (selectedItem?.id !== lsi.id) {
            setSelectedItem(lsi);
          }
          flag = true;
          break;
        }
      }
      if (flag) break;
    }

    if (!flag) {
      if (!learningSystem?.['learning-system-users']?.[0]?.finished) {
        setUnfinishedExam(learningSystem?.['question-books']?.find(item => !item?.finished));
      }
      setSelectedContent(data[0]);
      setSelectedItem(data[0]['learning-system-items'][0]);
      setUpdateContentNow(false);
    }
  };

  const showSuccessModal = () => {
    setTimeout(() => {
      setShowModal(learningSystemUser?.percent === 100 ? true : false), 500;
    });
  };

  function checkFinished(array) {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].hasOwnProperty('finished') || array?.[i]?.finished !== true) {
        return false;
      }
    }
    return true;
  }

  const resetQuizState = () => {
    setShowQuizModal(false);
    setAnsweredQuestionsCount(selectedItem?.['quiz-items'].length);
    setAnswered(false);
    setCanCloseModal(false);
    setFinishVideoModal(false);
  };

  useEffect(() => {
    if (selectedItem) setUnansweredQuestions(selectedItem['quiz-items']);
    filterQuestionIds();
  }, [selectedItem, learningSystem]);

  useEffect(() => {
    setEmptyTabs(true);
    setActiveTabs('courseClassroomFiles');

    if (authorityLevel == 'student' && selectedItem && learningSystemUser?.id) {
      checkLearningSystemUserItem();
    }
    showSuccessModal();
  }, [selectedItem, learningSystemUser]);

  useEffect(() => {
    if (authorityLevel == 'student') checkLearningSystemUser();
    if (learningSystemContents) {
      setIsFinished(checkFinished(learningSystemContents));
      if (isFinished) {
        setSelectedContent(learningSystemContents[0]);
      }
    }

    async function loadData() {
      const promises = [getLearnSystemContents(), getLearningSytem()];

      await Promise.all(promises).finally(() => setLoading(false));
    }

    loadData();
  }, []);

  useEffect(() => {
    if (authorityLevel == 'student' && updateContentNow) {
      if ((learningSystemUserItem?.['unfinished-items'] || []).length === 0) {
        checkLearningSystemUserItem();
        getLearnSystemContents();
        checkLearningSystemUser();
        getLastUnlockLearningSystemItem(learningSystemContents);
        setUpdateContentNow(false);
      } else {
        let message = '';
        switch (learningSystemUserItem?.['unfinished-items']?.[0]?.registerType) {
          case 'Activity':
            message = 'Você precisa realizar atividades obrigatórias para continuar.';
            break;
          case 'QuestionBook':
            message = 'Você precisa responder questões obrigatórias para continuar.';
            break;
          case 'QuizItem':
            message = 'Você precisa responder questões durante o vídeo para continuar.';
            break;
          default:
            message = 'Existe alguma pendência na aula, verifique a aba de questões, atividades complementares, ou responda as perguntas durante o vídeo.';
            break;
        }

        toast(message, {
          className: 'toast-error',
          duration: 10000,
          icon: <FiAlertTriangle />
        });
        setUpdateContentNow(false);
        getLastUnlockLearningSystemItem(learningSystemContents);
      }
    }
  }, [updateContentNow]);

  useEffect(() => {
    if (learningSystem?.['learning-system-items-count'] === 0 || learningSystemContents.length === 0) {
      setNotFound(true);
    } else {
      setNotFound(false);
    }
  }, [learningSystem, learningSystemContents]);

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: authorityLevel === 'student' ? '/curso' : '/curso/admin',
        name: 'Cursos',
        isActive: false
      },
      {
        route: authorityLevel === 'student' ? `/curso/acompanhamento/${learningSystem?.course?.id}` : `/curso/admin/${learningSystem?.course?.id}`,
        name: learningSystem?.course?.title,
        isActive: false
      },
      {
        route: authorityLevel === 'student' ? `/curso/${learningSystem?.course?.id}/modulo/${learningSystem?.id}/sobre` : `/curso/admin/${learningSystem?.course?.id}`,
        name: learningSystem?.title,
        isActive: false
      },
      {
        route: null,
        name: selectedItem?.body,
        isActive: true
      }
    ]
  };

  useEffect(() => {
    if (authorityLevel === 'student') {
      setDisclaimerModal(true);
    }
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      {!notFound && (
        <>
          <main className="main-content main-content--block">
            <div className="main-content-base">
              <div className="breadcrumb">
                <BreadCrumbs
                  data={breadCrumbs}
                  borderless
                />
              </div>

              {selectedItem && (
                <CourseClassroomVideo
                  disclaimerModal={disclaimerModal}
                  setFinishVideoModal={setFinishVideoModal}
                  unansweredQuestions={unansweredQuestions}
                  unfinishedExam={unfinishedExam}
                  setCurrentQuiz={setCurrentQuiz}
                  showQuizModal={showQuizModal}
                  setShowQuizModal={setShowQuizModal}
                  selectedItem={selectedItem}
                  url={selectedItem?.url}
                  learnSystemUseItem={learningSystemUserItem}
                  setLearnSystemUseItem={setLearningSystemUserItem}
                  setUpdateContentNow={setUpdateContentNow}
                  updateLearnSystemContents={updateLearningSystemContents}
                  checkLearnSystemUseItem={checkLearningSystemUser}
                />
              )}

              <ClassroomSidebar
                unfinishedExam={unfinishedExam}
                learnigSystem={learningSystem}
                answeredQuestionsCount={answeredQuestionsCount}
                setActiveTabs={setActiveTabs}
                learnSystemContents={learningSystemContents}
                learnSystemContentsLength={learningSystemContents.length}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                setSelectedContent={setSelectedContent}
                selectedContent={selectedContent}
                learnigSystemUser={learningSystemUser}
                lessonId={lessonId}
                getLearnSystemContents={getLearnSystemContents}
                id={id}
              />
              {!emptyTabs && (
                <div className="u-w-100">
                  <ClassroomTabNavBar
                    abilities={abilities}
                    activeTabs={activeTabs}
                    setActiveTabs={setActiveTabs}
                  />
                </div>
              )}

              {activeTabs === 'courseClassroomFiles' && (
                <div className="tab__pane">
                  {abilities?.['cursos-materiais'] && <CourseClassroomFiles selectedItemId={selectedItem?.id} />}
                  <CourseClassroomComments selectedItemId={selectedItem?.id} />
                </div>
              )}
              <CourseClassroomQuestionBooks
                selectedItemId={selectedItem?.id}
                selectedContent={selectedContent}
                setEmptyTabs={setEmptyTabs}
                activeTab={activeTabs === 'courseClassroomQuestionBooks'}
                questionIds={filteredQuestionIds}
              />
              <CourseClassroomActivities
                selectedItemId={selectedItem?.id}
                setEmptyTabs={setEmptyTabs}
                activeTab={activeTabs === 'courseClassroomActivities'}
              />
            </div>
          </main>

          <DisclaimerModal
            show={disclaimerModal}
            lessonName={selectedItem?.body}
            onClose={() => {
              setDisclaimerModal(false);
            }}
          />
          <SucessModal
            learningSystem={learningSystem}
            learningSystemUser={learningSystemUser}
            header={courseFinished ? t('lms.courseCompleted') : t('lms.moduleCompleted')}
            title={courseFinished ? t('lms.courseCompletedCongratulations') : t('lms.moduleCompletedCongratulations')}
            moduleCertificate={true}
            show={showModal}
            onClose={() => setShowModal(false)}
          />
          <Modal
            hideCloseButton={finishVideoModal ? !canCloseModal : !answered}
            show={showQuizModal}
            onClose={() => {
              if (finishVideoModal && canCloseModal) {
                resetQuizModal();
              }
              if (!finishVideoModal && answered) {
                setShowQuizModal(false);
                setAnsweredQuestionsCount(selectedItem?.['quiz-items'].length);
                setAnswered(false);
              }
            }}
          >
            <QuizModal
              unansweredQuestions={unansweredQuestions}
              finishVideoModal={finishVideoModal}
              setUnansweredQuestions={setUnansweredQuestions}
              setCanCloseModal={setCanCloseModal}
              setCurrentQuiz={setCurrentQuiz}
              selectedItem={selectedItem}
              currentQuiz={currentQuiz}
              setAnswered={setAnswered}
              answered={answered}
              resetQuizState={resetQuizState}
            />
          </Modal>
        </>
      )}

      {notFound && (
        <div className="main-content main-content--block">
          <EmptyState
            type="content"
            title={t('emptyState.contentNotAvaliable')}
            text={t('emptyState.contactATeacherText')}
          />
        </div>
      )}
    </>
  );
}

export default CourseClassroom;
