import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Content from './Content';
import MatterList from './MatterList';
import Sidebar from './Sidebar';
import { useClient } from 'jsonapi-react';
import { useLocation, useParams } from '@reach/router';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

export default function ManageExam(props) {
  const { t } = useTranslation();
  const params = useParams();
  const [exams, setExams] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedExam, setSelectedExam] = useState();
  const [examId, setExamId] = useState();
  const [selectedTaxonomy, setSelectedTaxonomy] = useState();
  const [taxonomies, setTaxonomies] = useState([]);
  const [taxonomyId, setTaxonomyId] = useState(params?.taxonomyId);
  const [examQuestions, setExamQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const client = useClient();
  const location = useLocation();
  const [loading, setLoading] = useState();
  const { path } = props;
  const activeTab = location?.state?.activeTabs;
  const [count, setCount] = useState(0);

  const getExams = async () => {
    setLoading(true);
    let filter = '';
    if (path.includes('/apostilas')) {
      filter = '?filter[visibility]=train&with_question_book_taxonomies=true';
    }
    if (path.includes('/provas')) {
      filter = '?filter[visibility]=exam&with_question_book_taxonomies=true';
    }
    if (path.includes('/simulados')) {
      filter = '?filter[visibility]=open&with_question_book_taxonomies=true';
    }
    const { data, error } = await client.fetch(`question_books${filter}`);
    if (error) {
      toast.error(t('toast.errorGetExams'));
    } else {
      const newData = data.map(exam => {
        exam['question-book-taxonomies'] = exam?.['question-book-taxonomies']?.map(qbt => {
          const taxonomy = exam.taxonomies.find(t => t.id === qbt.taxonomy_id);
          return { ...qbt, name: taxonomy?.name || '' };
        });
        return exam;
      });

      const filteredExams = newData.filter(exam => exam['creator-profile'] !== "Aluno")

      setExams(filteredExams);
    }
    setLoading(false);
  };

  const getCourses = async () => {
    const { data, error } = await client.fetch('courses');
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setCourses(data);
    }
  };

  const getExamQuestions = async () => {
    const { data, error } = await client.fetch(`questions?filter[question_book_id]=${examId}`);
    if (error) {
      toast.error('Erro ao carregar questões da prova');
    } else {
      setSelectedQuestions(data);
    }
  };

  const getTaxonomies = async () => {
    setLoading(true);
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch {
      toast.error(t('toast.errorGetTaxonomy'));
    }
    setLoading(false);
  };

  const reorder = (list, startingIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startingIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const updateMattersList = async values => {
    setLoading(true);
    const { destination, source } = values;
    if (!destination) {
      return null;
    }
    if (destination.index === source.index) {
      return null;
    }
    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(selectedExam['question-book-taxonomies'], sIndex, dIndex);
    const newMattersOrder = newItems.map((matter, index) => {
      return {
        id: matter.id,
        sequence: index
      };
    });
    const { error } = await client.mutate([`question_books`, selectedExam.id], { [`question-book-taxonomies-attributes`]: newMattersOrder }, { method: 'PUT' });
    if (error) {
      toast.error('Erro ao reordenar matérias');
    } else {
      getExams();
      toast.success(t('toast.successReorderSubjects'));
    }
    setLoading(false);
  };

  const updateQuestionsList = async values => {
    setLoading(true);
    const { destination, source } = values;
    if (!destination) {
      null;
    }
    if (destination.index === source.index) {
      return null;
    }
    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(filteredQuestions, sIndex, dIndex).map((question, index) => {
      question['question-book-taxonomy-questions'] = question['question-book-taxonomy-questions'].map(e => {
        if (e?.['question-book-taxonomy-id'] === selectedTaxonomy?.id) {
          e.sequence = index;
        }
        return e;
      });
      return question;
    });

    setFilteredQuestions(newItems);
    setSelectedQuestions(
      selectedQuestions.map(question => {
        const newQuestion = filteredQuestions.find(q => q.id === question.id);
        return newQuestion ? newQuestion : question;
      })
    );

    const newQuestionsOrder = newItems.map((question, index) => {
      return {
        id: question['question-book-taxonomy-questions'].find(item => item['question-book-taxonomy-id'] == taxonomyId)?.id,
        sequence: index
      };
    });
    const { error } = await client.mutate([`question_book_taxonomies`, taxonomyId], { [`question-book-taxonomy-questions-attributes`]: newQuestionsOrder }, { method: 'PUT' });
    if (error) {
      toast.error(t('toast.errorReorderingQuestions'));
    } else {
      // getExams();
      toast.success(t('toast.successReorderingQuestions'));
    }
    setLoading(false);
    setSelectedTaxonomy(selectedExam?.['question-book-taxonomies']?.find(item => item.id == taxonomyId));
  };

  useEffect(() => {
    if (path === '/provas/gerenciar/:id') {
      setExamId(location.state || parseInt(props?.questionbookId));
    }
    if (path === '/provas/gerenciar/:questionbookId') {
      setExamId(parseInt(props?.questionbookId));
    }
    if (path === '/provas/gerenciar/:id/:taxonomyId') {
      setTaxonomyId(location.state || parseInt(props?.taxonomyId));
    }
    if (path === '/provas/gerenciar/:questionbookId/:taxonomyId') {
      setExamId(parseInt(props?.questionbookId));
    }

    if (path === '/apostilas/gerenciar/:id') {
      setExamId(location.state || parseInt(props?.questionbookId));
    }
    if (path === '/apostilas/gerenciar/:questionbookId') {
      setExamId(parseInt(props?.questionbookId));
    }
    if (path === '/apostilas/gerenciar/:id/:taxonomyId') {
      setTaxonomyId(location.state || parseInt(props?.taxonomyId));
    }
    if (path === '/apostilas/gerenciar/:questionbookId/:taxonomyId') {
      setExamId(parseInt(props?.questionbookId));
    }

    if (path === '/simulados/gerenciar/:id') {
      setExamId(location.state || parseInt(props?.questionbookId));
    }
    if (path === '/simulados/gerenciar/:questionbookId') {
      setExamId(parseInt(props?.questionbookId));
    }
    if (path === '/simulados/gerenciar/:id/:taxonomyId') {
      setTaxonomyId(location.state || parseInt(props?.taxonomyId));
    }
    if (path === '/simulados/gerenciar/:questionbookId/:taxonomyId') {
      setExamId(parseInt(props?.questionbookId));
    }
  }, [props, location.state, path]);

  useEffect(() => {
    const currentTaxonomy = selectedExam?.['question-book-taxonomies']?.find(item => item.id == taxonomyId);
    if (examId && exams) {
      const currentExam = exams.find(item => item.id == examId);
      setSelectedExam(currentExam);
    }
    if (taxonomyId) {
      setSelectedTaxonomy(currentTaxonomy);
      if (examId) {
        getExamQuestions();
      }
    }
    if (selectedExam && selectedExam?.taxonomies.length > 0 && !currentTaxonomy) {
      const firstTaxonomy = selectedExam?.['question-book-taxonomies']?.[0];
      setSelectedTaxonomy(firstTaxonomy);
    }
  }, [exams, examId, taxonomyId, selectedExam?.['question-book-taxonomies']]);

  useEffect(() => {
    if (examId) {
      getExamQuestions();
    }
  }, [examId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getExams();
      getCourses();
    }, 500);

    return () => clearTimeout(timer);
  }, [count]);

  useEffect(() => {
    const newFilteredQuestions = selectedQuestions
      .filter(item => {
        if (selectedTaxonomy === undefined) {
          return true;
        }
        if (selectedTaxonomy?.id) {
          return item?.['question-book-taxonomy-questions']?.some(item => item?.['question-book-taxonomy-id'] == selectedTaxonomy?.id);
        }
      })
      .sort((a, b) => {
        const sequenceA =
          a['question-book-taxonomy-questions'].find(e => {
            return e?.['question-book-taxonomy-id'] === selectedTaxonomy?.id;
          })?.sequence || 0;

        const sequenceB =
          b['question-book-taxonomy-questions'].find(e => {
            return e?.['question-book-taxonomy-id'] === selectedTaxonomy?.id;
          })?.sequence || 0;

        return sequenceA - sequenceB;
      });

    setFilteredQuestions(newFilteredQuestions);
  }, [selectedQuestions, selectedTaxonomy]);

  return (
    <>
      <Sidebar
        path={path}
        activeTab={activeTab}
        setExamId={setExamId}
        examId={examId}
        selectedExam={selectedExam}
        exams={exams}
        courses={courses}
      />

      <main className="main-content u-p-0">
        <div className="new-questions">
          <DragDropContext onDragEnd={values => updateMattersList(values)}>
            <MatterList
              setCount={setCount}
              count={count}
              exams={exams}
              setExams={setExams}
              loading={loading}
              setSelectedExam={setSelectedExam}
              selectedTaxonomy={selectedTaxonomy}
              setTaxonomyId={setTaxonomyId}
              taxonomyId={taxonomyId}
              selectedExam={selectedExam}
              path={path}
              getTaxonomies={getTaxonomies}
              taxonomies={taxonomies}
            />
          </DragDropContext>

          <DragDropContext onDragEnd={values => updateQuestionsList(values)}>
            <Content
              setFilteredQuestions={setFilteredQuestions}
              loading={loading}
              selectedTaxonomy={selectedTaxonomy}
              taxonomyId={taxonomyId}
              selectedExam={selectedExam}
              filteredQuestions={filteredQuestions}
              setSelectedQuestions={setSelectedQuestions}
              selectedQuestions={selectedQuestions}
              examQuestions={examQuestions}
              path={path}
            />
          </DragDropContext>
        </div>
      </main>
    </>
  );
}
