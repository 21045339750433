import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiMail, FiPhone } from 'react-icons/fi';
import { useSession } from 'app/hooks/useSession';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import userAvatar from '../../images/user-avatar.svg';

import { Link, navigate, useLocation } from '@reach/router';
import Medal from 'app/components/Medal';

import Loader from 'app/components/loader';
import BgAluno from 'app/images/bg_aluno.jpg';
import TabNavBar from './TabNavBar';
import UserMetricsView from './UserMetricsView';
import UserMedalsView from './UserMedalsView';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import Onboarding from 'app/components/Onboarding';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function moreThanThreeDays(last_access) {
  const currentDate = new Date();
  const lastAccessDate = new Date(last_access);
  const difference = (currentDate - lastAccessDate) / (1000 * 3600 * 24);
  return difference < 3;
}

export function ShowUser(props) {
  const { t } = useTranslation();
  const { id, path, uri } = props;
  const { session } = useSession();
  const client = useClient();
  const location = useLocation();

  const profiles = session.user?.profileNames || (session.user && session.user['profile-names']) || [];

  const isCurrentUser = id == session.user.id;
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userClassrooms, setUserClassrooms] = useState([]);
  const [isStudent, setIsStudent] = useState();
  const [userMetrics, setUserMetrics] = useState();
  const { abilities } = usePlatformAbilities();
  const [medals, setMedals] = useState([]);
  const [medalsLoading, setMedalsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    getUser();
  }, [id]);

  const lastAccess = user?.['last-access'];
  const isActive = moreThanThreeDays(lastAccess);
  const { selectedTab } = props;
  const getUser = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`users/${id}`);
    if (error) {
      toast.error(t('toast.errorGetStudentInfo'));
    } else {
      setUser(data);
      const newIsStudent = data?.['profile-names']?.[0] === 'Aluno';
      setIsStudent(newIsStudent);
      getUserClassrooms(data);
      const isAdmin = !profiles?.includes('Aluno');
      setIsAdmin(isAdmin);
      getUserMetrics();
    }
    setLoading(false);
  };

  const getUserClassrooms = async user => {
    const { data } = await client.fetch(`classrooms?filter[users.id]=${user.id}`);
    setUserClassrooms(data);
  };

  const getUserMetrics = async () => {
    const { data, error } = await client.fetch(`general_metrics?filter[user_id]=${id}&learning_system_users=true`);
    if (error) {
      toast.error(t('toast.errorGetStudentMetrics'));
    } else {
      setUserMetrics(data);
    }
  };

  const getMedals = async () => {
    setMedalsLoading(true);
    const { data, error } = await client.fetch('medals');
    if (error) {
      toast.error(t('toast.errorMedals'));
    } else {
      setMedals(data);
      setMedalsLoading(false);
    }
  };

  const isOwner = () => {
    if (session?.user?.id === user?.id || isAdmin) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getMedals();
  }, []);

  const userPhone = user?.['user-detail']?.phone;

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: uri,
        name: 'Desempenho',
        isActive: true
      }
    ]
  };

  return (
    <>
      <div className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        <section className="profile">
          {location?.state?.isRedirected && (
            <div className="filter-bar">
              <div className="filter-bar__inner">
                <button
                  className="filter-bar__back"
                  onClick={() => navigate(-1)}
                >
                  <span className="card__icon-wrapper">
                    <FiChevronLeft className="card__icon" />
                  </span>
                  <span className="filter-bar__back-container">
                    <span className="filter-bar__title">Voltar</span>
                  </span>
                </button>
              </div>
            </div>
          )}

          {isAdmin && (
            <Link
              to={`/course/edit/participant/${id}`}
              className="btn btn--wide btn--outline"
            >
              {t('button.edit')}
            </Link>
          )}

          <figure className="profile__bg-wrapper">
            <img
              className="profile__bg"
              src={BgAluno}
              alt="imagem de fundo"
            />
          </figure>

          <div className="profile__inner">
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/user-avatar.svg';
              }}
              className="profile__avatar"
              src={user?.image?.url ? user?.image?.url + `?now=${new Date().getTime()}` : userAvatar}
            />
            <div className="profile__header">
              <div className="profile__data">
                <span className="badge badge--small">{user?.['profile-names'] && user?.['profile-names'][0]}</span>
                {isActive ? <span className={`badge badge--small badge--success u-ml-1`}>{t('journey.isActive')}</span> : <span className={`badge badge--small badge--danger u-ml-1`}>{t('journey.isInactive')}</span>}

                <h2 className="profile__title">{user?.name}</h2>

                {isOwner() && (
                  <div className="profile__info-wrapper">
                    <span className="profile__info">
                      <FiMail className="profile__icon" /> {user?.email}
                    </span>

                    {userPhone != undefined ? (
                      <span className="profile__info">
                        <FiPhone className="profile__icon" /> {userPhone}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>

              {isStudent && medalsLoading && (
                <div className="medal-wrapper">
                  <Loader />
                </div>
              )}
              {isStudent && !medalsLoading && (
                <div
                  onClick={() => navigate(`/usuario/medalhas/${id}`, { state: { isRedirected: true } })}
                  className="medal-wrapper"
                >
                  <p className="medal-wrapper__info">
                    <strong>
                      {user?.['medal-count']} {t('journey.medals')}
                    </strong>
                  </p>
                  <Medal.Stack value={user?.['medal-count']} />
                </div>
              )}
            </div>
          </div>
        </section>
        {isAdmin ||
          (isCurrentUser && (
            <TabNavBar
              selectedTab={selectedTab}
              abilities={abilities}
              id={id}
            />
          ))}
        {selectedTab === 'metricas' && abilities?.metricas && (
          <UserMetricsView
            loading={loading}
            isStudent={isStudent}
            isAdmin={isAdmin}
            isCurrentUser={isCurrentUser}
            user={user}
            userMetrics={userMetrics}
            id={id}
          />
        )}

        {selectedTab === 'medalhas' && (isCurrentUser || isAdmin) ? <UserMedalsView medals={medals} /> : null}

        <Onboarding
          show={showOnboarding}
          setShowOnboarding={setShowOnboarding}
          onClose={() => setShowOnboarding(false)}
          path={path}
          profiles={profiles}
          title={t('onboarding.titleShowUser')}
        />
      </div>
    </>
  );
}
