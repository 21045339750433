import React, { useState, useEffect } from 'react';
import Progress from '../components/Progress';
import moment from 'moment';

function SimulatedClock(props) {
	const { questionBooksUser, questionBook, finishOpen } = props;
  const [clock, setClock] = useState();
  const [percentageTime, setPercentageTime] = useState();
	// const [intervalId, setIntervalId] = useState();
	let intervalId = null

  useEffect(() => {
		tick();
		if(intervalId !== null) { clearInterval(intervalId) }
		intervalId = setInterval(() => tick(), 1000);
  }, []);

  const tick = () => {
    if(questionBook && questionBooksUser) {
			const createdAt = questionBooksUser.created_at;
			const finishedAt = moment(questionBook["finished-at"])
			const resolutionTime = moment(`1970-01-01 ${questionBook['resolution-time']}`)
			let newClock;
			let percentage;

			const resolutionTimeDurationSeconds = moment(questionBook['resolution-time'], 'HH:mm').diff(moment().startOf('day'), 'seconds');
			if(moment(createdAt).add(resolutionTimeDurationSeconds, 'seconds').isAfter(finishedAt)) {
				const passedSeconds = moment.duration(finishedAt.diff(moment())).asSeconds();
				const durationSeconds = finishedAt.diff(createdAt, 'seconds');
				percentage = 100 - ((passedSeconds * 100) / durationSeconds)
				newClock = moment.utc(passedSeconds * 1000);
			} else {
		    const passedSeconds = moment().diff(createdAt, 'seconds');
				percentage = ((passedSeconds * 100) / resolutionTimeDurationSeconds)
				newClock = resolutionTime.subtract(moment().diff(createdAt, 'seconds'), 'second');
			}

			if(percentage > 100) {
				clearInterval(intervalId)
				finishOpen()
			} else {
				setPercentageTime(100 - percentage)
				setClock(newClock)
			}
    }
  }

	return (
    <div className="simulated__progress">
      {clock && <Progress.Circular time={clock.format('H:mm:ss')} valueNow={percentageTime} />}
    </div>
	);
}

export default SimulatedClock;
