import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { ProjectsProvider } from '../provider';
import { AvatarStack } from 'app/components/Avatar';
import { FiChevronDown } from 'react-icons/fi';
import { useCourse } from 'app/hooks/useCourse';
import MainNav from 'app/components/mainNav';
import TabNavBar from '../TabNavBar';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import getPermission from 'app/utils/getPermission';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import toast from 'react-hot-toast';
import Dropdown from 'app/components/Dropdown';
import Swal from 'sweetalert2';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Error404 from 'app/components/Error404';
import Select from 'react-select';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function PersonalizedProjects(props) {
  return (
    <ProjectsProvider>
      <ScreenContentPersonalizedProjects {...props} />
    </ProjectsProvider>
  );
}

export function ScreenContentPersonalizedProjects(props) {
  const { uri } = props;

  const { t } = useTranslation();
  const { courses, getSimpleCourses } = useCourse();
  const [classrooms, setClassrooms] = useState([]);
  const [personalizedProjects, setPersonalizedProjects] = useState([]);
  const [filteredPersonalizedProjects, setFilteredPersonalizedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = useClient();
  const authorityLevel = getAuthorityLevel();

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      classroom: { value: 'blank', label: t('filter.blankLabelAll') },
      approvedProjects: false,
      projectsInProgress: false
    }
  });

  const otherFilters = [
    {
      label: t('metrics.approvedProjects'),
      value: filters.values.approvedProjects,
      onChange: () => {
        filters.setFieldValue('projectsInProgress', false);
        filters.setFieldValue('approvedProjects', !filters.values.approvedProjects);
      }
    },
    {
      label: t('metrics.projectsInProgress'),
      value: filters.values.projectsInProgress,
      onChange: () => {
        filters.setFieldValue('approvedProjects', false);
        filters.setFieldValue('projectsInProgress', !filters.values.projectsInProgress);
      }
    }
  ];

  const getClassrooms = async () => {
    if (filters.values?.course && filters.values.course !== 'blank') {
      const { data } = await client.fetch(`classrooms/simple?filter[course_id]=${filters.values.course}`);
      setClassrooms(data);
    }
  };

  const getPersonalizedProjects = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('project_groups?filter[project_model]=custom');
    if (error) {
      toast.error('Erro ao buscar Projetos Personalizados.');
    } else {
      setPersonalizedProjects(data);
      setLoading(false);
    }
  };

  const deletePersonalizedProject = async id => {
    setLoading(true);
    const { error } = await client.delete(['project_groups', id]);
    if (error) {
      toast.error(t('toast.errorDeleteProjectGroup'));
    } else {
      toast.success(t('toast.successDeleteProjectGroup'));
      setFilteredPersonalizedProjects(personalizedProjects.filter(p => p.id !== id));
      setLoading(false);
    }
  };

  useEffect(() => {
    getSimpleCourses();
    getPersonalizedProjects();
  }, []);

  useEffect(() => {
    getClassrooms();
    filters.setFieldValue('classroom', { value: 'blank', label: t('filter.blankLabelAll') });
  }, [filters.values.course]);

  useEffect(() => {
    let newPersonalizedProjects = personalizedProjects;
    if (filters.values.course !== 'blank') {
      newPersonalizedProjects = personalizedProjects.filter(u => u.projects.some(p => p['projectable-type'] === 'Course' && p['projectable-id'] === +filters.values.course));
    }
    if (filters.values.classroom.value !== 'blank') {
      newPersonalizedProjects = personalizedProjects.filter(u => u.projects.some(p => p['projectable-type'] === 'Classroom' && p['projectable-id'] === +filters.values.classroom.value));
    }
    if (filters.values.approvedProjects === true) {
      newPersonalizedProjects = newPersonalizedProjects.filter(u => u.projects.every(p => p.approved === true));
    }
    if (filters.values.projectsInProgress === true) {
      newPersonalizedProjects = newPersonalizedProjects.filter(u => u.projects.some(p => p.approved === null));
    }

    setFilteredPersonalizedProjects(newPersonalizedProjects);
  }, [filters.values, personalizedProjects]);

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: uri,
        name: 'Projetos',
        isActive: true
      }
    ]
  };

  return (
    <ProjectsProvider>
      {authorityLevel !== 'student' ? (
        <div className="main-screen">
          <MainNav />

          {getPermission('Visualizar lista de projetos', 'Projetos') && (
            <main className="main-content main-content--block">
              <BreadCrumbs data={breadCrumbs} />

              <TabNavBar selectedTab="personalizados" />

              <div className="filter-bar">
                <div className="filter-bar__inner">
                  <FilterSelectionBox
                    blankLabel={t('filter.blankLabelAll')}
                    label={t('filter.labelSelectCourse')}
                    value={filters.values.course}
                    onChange={e => filters.setFieldValue('course', e.target.value)}
                    options={courses?.map(o => ({
                      id: o.id,
                      name: o.title
                    }))}
                  />
                  <div>
                    <label className="form__label">{t('filter.labelSelectClassroom')}</label>
                    <Select
                      value={filters.values.classroom}
                      openMenuOnFocus
                      options={[
                        { value: 'blank', label: t('filter.blankLabelAll') },
                        ...classrooms?.map(item => {
                          return { value: item.id, label: item?.institution?.name + ' - ' + item?.course?.title + ' - ' + item.title };
                        })
                      ]}
                      className="react-multi-select filter-bar__multi-select"
                      classNamePrefix="react-multi-select"
                      placeholder={loading ? t('loader.loading2') : t('filter.blankLabelAll')}
                      noOptionsMessage={() => 'Sem opções'}
                      components={{
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null
                      }}
                      onChange={e => filters.setFieldValue('classroom', e)}
                    />
                  </div>

                  <FilterMultiSelect
                    data={otherFilters}
                    title="Status"
                  />
                </div>

                {getPermission('Criar projetos', 'Projetos') && (
                  <Link
                    to="/projetos/criar/passo/curso-turma"
                    className="btn btn--wide btn--primary"
                  >
                    {t('projects.newProjects')}
                  </Link>
                )}
              </div>

              {loading && <Loader />}

              {filteredPersonalizedProjects?.length === 0 && !loading && (
                <EmptyState
                  type="project"
                  title={t('emptyState.textData')}
                  text={t('emptyState.createNewProjectInteract')}
                />
              )}

              {!loading && (
                <div className="tab__cards">
                  {filteredPersonalizedProjects?.map(p => {
                    const data = p.projects[0];
                    const id = p?.id;
                    return (
                      <div
                        className="card"
                        key={data?.id}
                      >
                        <div className="card__header">
                          <div className="card__inner-header">
                            <h3 className="card__title">{data?.name}</h3>
                          </div>

                          {data?.approved ? <span className="badge badge--success badge--tiny">{t('exams.statusInApproved')}</span> : <span className="badge badge--primary badge--tiny">{t('exams.inProgress')}</span>}
                        </div>

                        <div className="card__body">
                          <div className="card__columns">
                            <div className="card__avatar">
                              <h4 className="card__avatar-title">{t('projects.participants')}</h4>
                              <AvatarStack
                                data={data?.users}
                                modalTitle={`${t('projects.titleProjectParticipants')} ${data?.name}`}
                                className="avatar--xs"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="card__footer">
                          <div
                            className="btn-group btn-group--with-dropdown"
                            role="group"
                            aria-label="Ações"
                          >
                            {data?.['project-users']?.some(projectUser => projectUser.user_id === session.user.id) ? (
                              <Link
                                to={`/projetos/${data?.id}`}
                                type="button"
                                className="btn btn--outline btn--small"
                              >
                                {t('button.accessProject')}
                              </Link>
                            ) : (
                              <Link
                                to={`/projetos/${data?.id}/sobre`}
                                type="button"
                                className="btn btn--outline btn--small"
                              >
                                {t('button.detailsProject')}
                              </Link>
                            )}
                            {getPermission('Editar projetos', 'Projetos') || getPermission('Excluir projetos', 'Projetos') ? (
                              <Dropdown className="btn--small btn btn--outline">
                                <Dropdown.Toggle>
                                  <FiChevronDown />
                                </Dropdown.Toggle>

                                <Dropdown.Content>
                                  {getPermission('Editar projetos', 'Projetos') && <Dropdown.Item to={`/projetos/criar/passo/curso-turma/${id}`}>{t('button.edit')}</Dropdown.Item>}
                                  {getPermission('Excluir projetos', 'Projetos') && (
                                    <Dropdown.Item
                                      onClick={() => {
                                        Swal.fire({
                                          title: t('projects.warningDeleteProject'),
                                          text: t('projects.warningDeleteCannotBeUndoneText'),
                                          confirmButtonText: t('button.yes'),
                                          showDenyButton: true,
                                          denyButtonText: t('button.no'),
                                          showCloseButton: true
                                        }).then(res => {
                                          if (res.isConfirmed) {
                                            deletePersonalizedProject(id);
                                          }
                                        });
                                      }}
                                    >
                                      {t('textsCommon.delete')}
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Content>
                              </Dropdown>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </main>
          )}
        </div>
      ) : (
        <Error404 />
      )}
    </ProjectsProvider>
  );
}
