import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextareaAutosize from 'react-autosize-textarea';
import Modal from 'app/components/Modal';
import { FiInfo } from 'react-icons/fi';
import * as yup from 'yup';

export default function FormGoalModal(props) {
  const { show, onClose, createGoal, updateGoal, currentGoal } = props;

  const form = useFormik({
    initialValues: {
      name: '',
      description: '',
      "end-date": ''
    },
    validationSchema: yup.object({
      name: yup.string().required('Campo obrigatório'),
      "end-date": yup.date().required('Campo obrigatório')
    }),
    onSubmit: values => {
      currentGoal ? updateGoal({ ...values, id: currentGoal.id }) : createGoal(values);
      onClose();
    }
  });

  useEffect(() => {
    if (currentGoal) {
      form.setValues({
        name: currentGoal.name,
        description: currentGoal.description,
        "end-date": currentGoal["end-date"]
      });
    }
  }, [currentGoal]);

  useEffect(() => {
    if (!show) {
      form.resetForm();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <form
        method="post"
        className="form"
        onSubmit={form.handleSubmit}
      >
        <h2 className="modal__simple-title">{'Novo objetivo'}</h2>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="name"
          >
            {'Nome do objetivo'}
          </label>
          <input
            className="form__control"
            id="name"
            name="name"
            type="text"
            placeholder={'Ex: Enem 2023'}
            value={form.values.name}
            onChange={form.handleChange}
          />
          {form.errors.name && form.touched.name && <span style={{ color: 'red' }}>{form.errors.name}</span>}
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="description"
          >
            {'Descrição'}
          </label>

          <TextareaAutosize
            placeholder={'Ex: Dominar os conceitos de física clássica para o Enem 2023'}
            id="description"
            name="description"
            value={form?.values?.description}
            onChange={e => form?.setFieldValue('description', e.target.value)}
          />
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="end-date"
          >
            {'Data do objetivo'}
          </label>

          <input
            className="form__control"
            id="end-date"
            name="end-date"
            type="date"
            value={form?.values?.["end-date"]}
            onChange={e => form?.setFieldValue('end-date', e.target.value)}
            placeholder="Data do objetivo"
          />

          <span className="form__text">
            <FiInfo className="card__icon" /> Quantas semanas até o data do objetivo
          </span>
          {form.errors["end-date"] && form.touched["end-date"] && <span style={{ color: 'red' }}>{form.errors["end-date"]}</span>}
        </div>

        <button
          className="btn btn--wide btn--primary"
          type="submit"
        >
          {'Salvar'}
        </button>
      </form>
    </Modal >
  );
}
