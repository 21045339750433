import React from 'react';
import EssaySendSidebar from './EssaySendSidebar';
import EssaySendContent from './EssaySendContent';

export default function EssaySend({ essayId }) {
  return (
    <main className="main-content">
      <EssaySendContent essayId={essayId} />
    </main>
  );
}
