import { useClient } from 'jsonapi-react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import toast from 'react-hot-toast';
import { FiPlus, FiCheckCircle } from 'react-icons/fi';
import MatterModal from './MatterModal';
import { useTranslation } from 'react-i18next';

export default function StepName(props) {
  const { t } = useTranslation();
  const { step, form, values, touched } = props;
  const client = useClient();
  const [taxonomies, setTaxonomies] = useState();
  const [showModal, setShowModal] = useState(false);

  const getTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch {
      toast.error('Erro ao carregar taxonomias');
    }
  };

  useEffect(() => {
    getTaxonomies();
  }, []);

  const addMatter = matter => {
    form.setFieldValue('selectedTaxonomies', [...form?.values?.selectedTaxonomies, matter]);
  };

  const removeMatter = matter => {
    form.setFieldValue(
      'selectedTaxonomies',
      form?.values?.selectedTaxonomies.filter(item => item.id !== matter.id)
    );
  };

  useEffect(() => {
    form.setFieldValue('selectedTaxonomies', form?.values?.selectedTaxonomies);
  }, [form?.values?.selectedTaxonomies]);

  return (
    step === 'nome' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form.values.title || t('exams.newExam')}</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('exams.detailsDescription')}</p>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="title"
            >
              *{t('exams.nameExam')}
            </label>
            <input
              className="form__control"
              placeholder={t('exams.placeholderExamName')}
              id="title"
              name="title"
              type="text"
              value={form?.values?.title}
              onChange={e => form.setFieldValue('title', e.target.value)}
            />
            {form?.touched.title && form?.errors.title && <span style={{ color: 'red' }}>{form?.errors.title}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="description"
            >
              *{t('exams.descriptionRecommendations')}
            </label>

            <TextareaAutosize
              placeholder={t('textsCommon.writeHere')}
              id="description"
              name="description"
              value={form?.values?.description}
              onChange={e => form.setFieldValue('description', e.target.value)}
            />
            {form?.touched.description && form?.errors.description && <span style={{ color: 'red' }}>{form?.errors.description}</span>}
          </div>

          <fieldset className="form__row">
            <legend className="form__label">*{t('exams.examSubjects')}</legend>

            <button
              type="button"
              className="btn btn--outline-dashed btn--small btn--full"
              onClick={() => setShowModal(true)}
            >
              <FiPlus className="btn__icon" /> {t('textsCommon.buttonAddMatters')}
            </button>

            <MatterModal
              addMatter={addMatter}
              removeMatter={removeMatter}
              selectedMatters={form?.values?.selectedTaxonomies}
              matters={taxonomies}
              show={showModal}
              onClose={() => setShowModal(false)}
              countType={'exam'}
            />
            {form?.touched?.selectedTaxonomies && form?.errors.selectedTaxonomies && <span style={{ color: 'red' }}>{form?.errors.selectedTaxonomies}</span>}
          </fieldset>

          <table className="round-dg round-dg--light">
            <tbody className="round-dg__body">
              {form?.values?.selectedTaxonomies?.map((matter, index) => (
                <tr
                  className="round-dg__row"
                  key={index}
                >
                  <th className="round-dg__cell-header round-dg__cell-header--row">
                    <div className="card__header u-mb-0">
                      <div className="card__inner-header">
                        <div className="card__icon-wrapper">
                          <FiCheckCircle className="card__icon" />
                        </div>
                        <h3 className="card__title">{matter.name}</h3>
                      </div>
                    </div>
                  </th>
                  <td className="round-dg__cell">
                    {matter?.['questions-count']} {t('exams.questions')}
                  </td>
                  <td
                    className="round-dg__cell"
                    style={{ width: '140px' }}
                  >
                    <button
                      type="button"
                      className="btn btn--full btn--primary"
                      onClick={() => removeMatter(matter)}
                    >
                      {t('button.remove')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
}
