import React from 'react';
import InstructionWidget from 'app/components/Widget/InstructionWidget';
import signature from 'app/images/signature.svg';
import photoCellPhone from 'app/images/photo-cell-phone.svg';
import add from 'app/components/EmptyState/img/add.svg';

export default function EssaySendTips({ essayId }) {
  const instructions = [
    {
      number: 1,
      title: 'Escreva sua redação',
      description: 'Use uma folha de sua preferência ou baixe e imprima uma que deixamos disponível abaixo para você.',
      img: signature,
      cta: {
        text: 'Baixar folha',
        to: `/redacao/impressao/${essayId}`
      }
    },
    {
      number: 2,
      title: 'Tire uma foto da sua redação',
      description: 'Faça um bom enquadramento, não corte palavras, ajuste o foco e click!',
      img: photoCellPhone
    },
    {
      number: 3,
      title: 'Envie para a plataforma',
      description: 'Após fazer a foto, clique no botão “Enviar redação” para que possamos corrigir.',
      img: add
    }
  ];

  return (
    <aside className="page__aside page__aside--bg">
      <h3 className="page__aside-title">Como enviar minha redação?</h3>
      <p className="page__aside-text">Siga as instruções abaixo:</p>

      <div className="instruction-widget-wrapper">
        {instructions.map(item => (
          <InstructionWidget
            data={item}
            key={item.id}
          />
        ))}
      </div>
    </aside>
  );
}
