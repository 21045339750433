import MultiSelect from 'app/components/MultiSelect';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import toast from 'react-hot-toast';
import { useSession } from 'app/hooks/useSession';

export default function StepAbout(props) {
  const { step, form, error, creatorId, isEditing } = props;
  const client = useClient();
  const [taxonomies, setTaxonomies] = useState();
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const isAdmin = checkIncludeProfiles({ only: ['admin'] });
  const [classrooms, setClassrooms] = useState([]);

  const getTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch {
      toast.error('Erro ao carregar taxonomias');
    }
  };

  const getClassrooms = async () => {
    const { data, error } = await client.fetch('classrooms');
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setClassrooms(data);
    }
  };

  useEffect(() => {
    getClassrooms();
  }, []);

  useEffect(() => {
    getTaxonomies();
  }, []);

  const currentUser = useSession()?.session?.user;
  const allowToChangeAuthorial = currentUser.id === creatorId || currentUser?.profileNames.includes('Administrador');

  return (
    step === 'sobre' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title"> {t('courses.buttonNewCourse')}</h2>
        </div>

        <div className="new-questions__body">
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="title"
            >
              *{t('filter.courseName')}
            </label>
            <input
              className="form__control"
              placeholder={t('courses.placeholderCourseName')}
              id="title"
              name="title"
              type="text"
              value={form?.values?.title}
              onChange={e => form?.setFieldValue('title', e.target.value)}
            />
            {form?.touched?.title && form?.errors?.title && <span style={{ color: 'red' }}>{form?.errors?.title}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="about"
            >
              *{t('courses.aboutCourse')}
            </label>

            <TextareaAutosize
              placeholder={t('courses.aboutCoursePlaceholder')}
              id="about"
              name="about"
              value={form?.values?.about}
              onChange={e => form.setFieldValue('about', e.target.value)}
            />
            {form?.touched?.about && form?.errors?.about && <span style={{ color: 'red' }}>{form?.errors?.about}</span>}
          </div>

          <div className="form__row">
            <div className="form__check">
              <input
                className="form__check-input"
                id="isPublic"
                name="is-public"
                checked={form.values['is-public']}
                type="checkbox"
                onClick={() => form.setFieldValue('is-public', !form.values['is-public'])}
              />
              <label htmlFor="isPublic">O conteúdo do curso é público</label>
            </div>
          </div>

          <div className="form__row form__row--columns">
            <div className="form__col">
              <label
                className="form__label"
                htmlFor="about"
              >
                *Data de conclusão do curso
              </label>
              <input
                className="form__control"
                placeholder={t('lms.typeAnumber')}
                id="finished-at"
                name="finished-at"
                type="date"
                value={form?.values?.['finished-at'] ? moment(form?.values?.['finished-at']).format('YYYY-MM-DD') : ''}
                onChange={e => form.setFieldValue('finished-at', e.target.value)}
              />
              {form?.touched?.['finished-at'] && form?.errors?.['finished-at'] && <span style={{ color: 'red' }}>{form?.errors?.['finished-at']}</span>}
            </div>

            <div className="form__col" />
          </div>
          <fieldset className="form__row">
            <legend className="form__label">*{t('tasks.mattersRelated')}</legend>

            <MultiSelect
              selectedTaxonomies={form?.values?.selectedTaxonomies}
              setFieldValue={form?.setFieldValue}
              openModal={() => setIsOpenModalTaxonomies(true)}
              placeholder={t('tasks.mattersAdd')}
            />
            {form?.touched?.selectedTaxonomies && form?.errors?.selectedTaxonomies && <span style={{ color: 'red' }}>{form?.errors?.selectedTaxonomies}</span>}
          </fieldset>


           <div className="form__row">
            <label
              className="form__label"
              htmlFor="classroom"
            >
              *Relacione o curso a uma turma
            </label>
            <select
              className="form__select"
              id="classroom"
              name="classroom"
              value={form?.values?.classroom}
              onChange={e => form.setFieldValue('classroom', e.target.value)}
            >
              <option value="">Selecione uma Turma</option>
              {classrooms?.map(c => (
                <option
                  key={c.id}
                  value={c.id}
                >
                  {c.title}
                </option>
              ))}
            </select>
            {form.touched?.classroom && form.errors?.classroom && <span style={{ color: 'red' }}>{form.errors?.classroom}</span>}
          </div> 

          <fieldset className="form__row">
            <legend className="form__label">Inteligência Artificial</legend>

            <div className="form__check form__switch">
              <input
                className="form__check-input"
                id="quiz"
                name="quiz"
                type="checkbox"
                checked={form?.values?.['ai-options']?.quizzes}
                onClick={() => {
                  form?.setFieldValue('ai-options.quizzes', !form?.values?.['ai-options']?.quizzes);
                }}
              />
              <label htmlFor="quiz">Quizz durante a aula</label>
            </div>
            <div className="form__check form__switch">
              <input
                className="form__check-input"
                id="mindMap"
                name="mindMap"
                type="checkbox"
                checked={form?.values?.['ai-options']?.mindmaps}
                onClick={() => {
                  form?.setFieldValue('ai-options.mindmaps', !form?.values?.['ai-options']?.mindmaps);
                }}
              />
              <label htmlFor="mindMap">Mapa mental</label>
            </div>
            <div className="form__check form__switch">
              <input
                className="form__check-input"
                id="studyGuide"
                name="studyGuide"
                type="checkbox"
                checked={form?.values?.['ai-options']?.['study-guide']}
                onClick={() => {
                  form?.setFieldValue('ai-options.study-guide', !form?.values?.['ai-options']?.['study-guide']);
                }}
              />
              <label htmlFor="studyGuide">Resumo automático</label>
            </div>
          </fieldset>

          {(allowToChangeAuthorial || !isEditing) && (
            <fieldset className="form__row">
              <legend className="form__label">Autoria do curso</legend>

              <div className="form__check form__switch">
                <input
                  className="form__check-input"
                  id="authorialContent"
                  name="authorialContent"
                  type="checkbox"
                  checked={form?.values?.['authored-by-user']}
                  onClick={() => {
                    form?.setFieldValue('authored-by-user', !form?.values?.['authored-by-user']);
                  }}
                />
                <label htmlFor="authorialContent">
                  Este curso é de minha autoria e <strong>somente eu posso editá-lo.</strong>
                </label>
              </div>

              {isAdmin && (
                <div className="form__check form__switch">
                  <input
                    className="form__check-input"
                    id="officialContent"
                    name="officialContent"
                    type="checkbox"
                    checked={form?.values?.['official-content']}
                    onClick={() => {
                      form?.setFieldValue('official-content', !form?.values?.['official-content']);
                    }}
                  />
                  <label htmlFor="officialContent">
                    Este curso é <strong>oficial;</strong> apenas a equipe administrativa têm permissão para editá-lo.
                  </label>
                </div>
              )}
            </fieldset>
          )}

          <span className="form__text">
            <strong>* Campo obrigatório!</strong>
          </span>
        </div>

        {taxonomies?.length > 0 && isOpenModalTaxonomies && (
          <ModalTaxonomies
            taxonomies={taxonomies}
            selectedTaxonomies={form?.values?.selectedTaxonomies}
            setFieldValue={form?.setFieldValue}
            closeModal={() => setIsOpenModalTaxonomies(false)}
          />
        )}
      </div>
    )
  );
}
