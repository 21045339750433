const messages = {
  en: {
    translations: {
      login: {
        login: 'Log in to your account',
        password: 'Password:',
        forgotPass: 'Forgot your password?',
        forgotText: 'Enter your email address so we can send you the password reset link.',
        forgotTextDescription: 'Please check your inbox and spam or junk folder if you do not find the email in your main inbox.',
        sendLink: 'Send link by e-mail',
        createAccount: 'Create a new account.',
        termsText: 'When clicking "Create Account", you agree to the',
        userTerm: 'terms of use',
        privacyTerm: 'privacy policy'
      },
      createAccount: {
        createYourAccount: 'Create your new account',
        name: 'Name:',
        cellphone: 'Cellphone:',
        selectSchool: 'Select a school',
        selectClass: 'Select a class',
        password: 'Password:',
        buttonCreateAccount: 'Create my account',
        loginWithMyAccount: 'Login with my existing account'
      },
      header: {
        help: 'Need help?',
        notes: 'Notes',
        myNotes: 'Private notes',
        classNotes: 'Class notes',
        importantNotes: 'Important notes',
        createNewNote: 'Create new note',
        nameNote: 'Note name',
        newNote: 'New note',
        notesPlaceholder: 'Type note name...',
        languagePt: 'Portuguese',
        languageEn: 'English',
        notificationTitle: 'Notifications',
        notificationRead: 'Mark all as read'
      },
      helpCenter: {
        helpCenterTitle: 'Help Center',
        helpTitle: 'You are in our help center',
        frequentAsks: 'Frequently Asked Questions',
        needNewPass: 'I need a new password',
        sendLinkPass: 'Link Sent',
        sendLinkPassText: 'We have sent a password reset link to your registered email address ',
        notificationChoose: 'I want to choose my notifications',
        notificationChooseTitle: 'Choose Notifications',
        notificationChooseText: 'In the <strong>Profile</strong> menu, go to Notifications and select the <strong>notifications</strong> you want to receive.',
        notificationGo: 'Go to notifications',
        contactUs: 'Contact us',
        firstSteps: 'First steps',
        showMore: 'Show more',

        materials: 'Materials',
        questions: 'Questions',
        projects: 'Projects',
        classes: 'Classses',
        activities: 'Complementary Activities',
        generalMetrics: 'General metrics',
        tasks: 'Tasks',
        journey: 'Journey',
        quiz: 'Quiz'
      },
      helpFooter: {
        talkToUs: 'Talk to us',
        asksSend: 'Send your question (we will reply within 48 hours)',
        eventTitle: 'Event Title',
        eventPlaceholder: 'What is the subject?',
        ask: 'Question',
        askPlaceholder: 'Write your question here',
        emailSended: 'Email sent',
        emailReceived: 'We have received your message. We will contact you soon.'
      },
      sideBar: {
        dashboard: 'Dashboard',
        feed: 'Feed',
        courses: 'Courses',
        tasks: 'Tasks',
        lms: 'Classes',
        exams: 'Exams',
        materials: 'Materials',
        questionBooks: 'Question Books',
        activities: 'Complementary Activities',
        projects: 'Projects',
        journey: 'Journey',
        study: 'Study Plan',
        profile: 'Profile',
        leave: 'Log out',
        liveClass: 'Live classes',

        taxonomyAndMaterials: 'Taxonomies',
        coursesAndClass: 'Classes',
        metrics: 'Metrics',
        participants: 'Participants'
      },
      classes: {
        toggleInvitations: 'Invitations',
        toggleImportSpreadsheet: 'Import Spreadsheet',
        thIdentifier: 'Identifier',
        thImportCount: 'Import Count',
        thImportSpreadsheet: 'Imported Spreadsheet',
        thImportDate: 'Import Date',
        thRegistrationCount: 'Registration Count',
        thSendLink: 'Sent Link',
        thSendingDate: 'Sending Date',
        copyLink: 'Copy Link',
        users: 'users',
        buttonDetails: 'Details',
        buttonGenerateLink: 'Generate Link',
        editClass: 'Edit Class',
        accessLink: 'Access Link',
        accessLinkDescription: 'Allow students to register via the link',
        associateClass: 'Associate the class',
        tagImportSuccess: 'Imported successfully',
        tagimportError: 'Import error',
        tagImportWait: 'Awaiting import',
        buttonTemplateSpreadsheet: 'Template spreadsheet',
        buttonImportSpreadsheet: 'Import spreadsheet'
      },
      dashboard: {
        projects: 'Projects',
        activities: 'Complementary Activities',
        perceptionQuest: 'Perception Questionnaire',
        newProjectVersionToEvaluate: 'New project version to evaluate',
        newProjectVersionsToEvaluate: 'New project versions to evaluate',
        activityToEvaluate: 'Activity to evaluate',
        activitiesToEvaluate: 'Activities to evaluate',
        answer: 'Answer',
        answers: 'Answers',
        accessProject: 'Access projects',
        accessActivities: 'Access activities',
        accessQuest: 'Access Questionnaires',
        latestUpdates: 'Latest updates',
        unreadNotifications: 'Unread notifications',
        important: 'Important',
        completedModules: 'Completed Modules'
      },
      events: {
        buttonAddEvent: 'Add event',
        titleCreateNewEvent: 'Create a New Event',
        toggleClass: 'Class',
        togglePublic: 'Public',
        toggleCourse: 'Course',
        eventDay: 'Event Day',
        eventHour: 'Event Time',
        eventTitle: 'Event Title',
        placeholderEventDescription: 'Enter the event name',
        placeholderAboutEvent: 'Describe the event',
        toastCreating: 'Creating...',
        toastEventSuccessSaved: 'Event successfully saved!',
        toastEventSuccessCreated: 'Event successfully created!',
        toastEventErrorSaved: 'Error saving event!',
        todayTasks: 'Today Tasks'
      },
      feed: {
        mentorsAndTeachers: 'Mentors and Teachers',
        textareaPlaceholder: 'Share something',
        titleEvents: 'Events',
        buttonBackToFeed: 'Back to Feed'
      },
      tasks: {
        course: 'Course Task',
        class: 'Class Task',
        new: 'New Task',
        individual: 'Individual Task',
        dynamic: 'Dynamic Task',
        lastWeek: 'last week',
        nextWeek: 'next week',
        typeLearn: 'Learn',
        typeTraining: 'Training',
        titleTaskLabel: 'Task Title',
        typeLabel: 'Type',
        placeholderTitleTask: 'Write the task title',
        placeholderType: 'Select...',
        noOptions: 'No options',
        duration: 'Duration',
        hour: 'hour',
        hours: 'hours',
        description: 'Description',
        placeholderDescription: 'Describe the task to the students',
        mattersRelated: 'Related Subjects',
        mattersAdd: 'Add subjects...',
        buttonCreateTask: 'Create Task',
        dailyLimit: 'Daily limit of 8h accumulated.',
        dailyLimitStudent: 'students will exceed the daily limit.',
        textReduceTime: 'Try reducing the task time or choosing another date so that most of them can be included.',
        buttonUndoTask: 'Undo Task',
        titleEditDescription: 'Click on task description to edit',
        untitled: 'Untitled',
        toggleCourses: 'Courses',
        toggleClass: 'Classes',
        toggleStudents: 'Students',
        studentTaskTracking: 'Student Task Tracking'
      },
      lms: {
        lms: 'Classes',
        tableHeaderConcludedIn: 'Completed on',
        toggleLms: 'Classes',
        toggleCertifications: 'Certifications',
        toggleMaterialsAndForum: 'Materials and forum',
        toggleQuestionBooks: 'Exercises',
        toggleActivity: 'Complementary Activities',
        by: 'By',
        requirements: 'Requirements',
        tagRequirementsNot: 'No requirements',
        tagNewMessage: 'New message',
        tagNew: 'New',
        buttonStartModule: 'Start module',
        buttonContinue: 'Continue',
        titleAboutModule: 'About the module',
        titleWhatLearn: 'What will you learn?',
        moduleCompleted: 'Module completed',
        moduleCompletedCongratulations: 'Congratulations! You did your best and completed the module',
        moduleCompletedCertificateReady: 'Your certificate is already ready',
        buttonGetCertificate: 'Get certificate',
        titleQuestionLms: 'Answer the question based on the class you are watching.',
        buttonTextSendingAnswer: 'Sending answer...',
        certificateTitle: 'Completion Certificate',
        certificateText:
          'We certify that <strong>{{userName}}</strong> has successfully completed the <strong>{{moduleTitle}}</strong> module of the {{courseTitle}} course on {{monthName}} {{day}}, {{year}}, promoted by {{envName}} on the Estudologia platform.',
        certificateTextSmall: '*The course duration represents the duration of all videos in the course up to the moment of the most recent completion.',
        publish: 'Publish',
        stepsModulesNameSideBar: 'To get started, enter the module name and descriptions',
        stepsModulesObjectivesSideBar: 'Next, write learning objectives for the students',
        stepsModulesRequirementsSideBar: 'Choose the requirements for the module and relate them to subjects',
        stepsModulesImageSideBar: 'Finally, choose an image that helps identify the module',
        newModule: 'New module',
        moduleName: 'Module name',
        placeholderModuleName: 'Enter module name',
        shortDescription: 'Short description',
        placeholderShortDescription: 'Enter a short description',
        aboutModule: 'About the module',
        placeholderAboutModule: "Here you can talk about the module's characteristics, methodologies, objectives, and more.",
        workload: 'Workload',
        typeAnumber: 'Type a number',
        asideTextDescription: 'The module will be made available to the <strong>students of {{currentCourseTitle}}</strong>. They will be notified when the module is created.',
        studentsCourse: 'Students in the course {{currentCourseTitle}}',
        distribution: 'Distribution',
        objectiveModuleTitle: 'What students are learning',
        objectiveModuleDescription: 'Write learning objectives that students can expect to achieve by the end of the course',
        addAItem: 'Add an item',
        placeholderModule1: 'Example: Learn programming logic',
        placeholderModule2: 'Example: Manage your own business',
        placeholderModule3: 'Example: Develop strategies to study effectively',
        asideTitleLearning: 'What will students learn?',
        asideTextLearning:
          'The focus of this section is to quickly help students understand the benefits of the module. Our tip is to <strong>structure the objectives with short, direct sentences using action verbs.</strong> Here are some examples:',
        asideRequirementsDescription:
          'If the student needs to <strong>complete previous modules</strong> before this one you are creating, select them as <strong>requirements</strong>. The student will only have access to the module if they have completed the requirements you have chosen.',
        placeholderSelectRequirements: 'Select the requirements',
        relatedSubjectDescription: 'The choice of <strong>related subjects</strong> helps to build the learning path for students.',
        previewModule: 'Preview module',
        whatWillLearnTitle: 'What will you learn?',
        requirementModuleTitle: 'Is there any requirement for the module?',
        modulesOf: 'Modules of',
        printCertificate: 'Print Certificate'
      },
      exams: {
        toggleExams: 'Exams',
        toggleMyResults: 'My Results',
        statusInAnalysis: 'In Analysis',
        statusInApproved: 'Approved',
        statusInDisapproved: 'Disapproved',
        statusAcceptingAnswers: 'Accepting Answers',
        statusUnrealized: 'Unrealized',
        statusDone: 'Completed',
        labelPublishedAt: 'Published at:',
        accessExam: 'Access Exam',
        examAnswer: 'Exam Answer',
        inProgress: 'In Progress',
        thStats: 'Stats',
        thExams: 'Exams',
        thCourse: 'Course',
        thClass: 'Class',
        thResults: 'Results',
        thResolutionDate: 'Resolution Date',
        thScore: 'Score',
        thActions: 'Actions',
        thAvarageScore: 'Avarage score',
        thParticipants: 'Participants',
        thParticipant: 'Participant',
        tableHeaderQuestionId: 'Question ID',
        tableHeaderAnswerKey: 'Official Answer Key',
        tableHeaderStudentResponse: 'Student Response',
        tableHeaderStudentResult: 'Student Result',
        tableHeaderOverallPerformace: 'Overall Performance',
        examBannerDescription: 'Below you can find some instructions from your teacher. Read them carefully and good luck with your exam!',
        examBannerDescriptionWaterAndBathroom: 'Did you drink water? Went to the bathroom?',
        titleDescription: 'Description and Instructions',
        from: 'From',
        at: 'at',
        to: 'to',
        and: 'and',
        hoursDuration: '<strong>{{hours}}</strong> hours duration',
        hourDuration: '<strong>{{hours}}</strong> hour duration',
        questions: 'questions',
        titleMaterialsDistribuition: 'Materials Distribution',
        sendExam: 'Submit Exam',
        next: 'Next',
        previous: 'Previous',
        reply: 'Reply',
        replyAndFinish: 'Reply and Finish',
        submitExam: 'Submit Exam',
        time: 'Time',
        subtitleSubmitExam: 'Do you want to finish and submit your answers?',
        submitedQuestions: 'answered questions',
        titleSendAnswers: 'Answers Submitted',
        textCongratulations: 'Congratulations! You have finished answering',
        textAbleToPerformace: 'You will be able to check your performance result after',
        textResultPerformace: 'You can follow the result in 1 hour and 30 minutes by clicking on the button below',
        examMinutes: 'minutes of the exam',
        answeredQuestions: 'Answered Questions',
        minOfDay: 'min of the day',
        closeExam: 'Close',
        titleFinishPageSendAnswers: 'Your answers have been submitted!',
        congratulationsFinishAnswers: 'Congratulations, you have finished answering.',
        thanksForParticipating: 'Thank you for participating.',
        buttonBackToDashboard: 'Back to Dashboard',
        questionTitle: 'Question',

        toggleQuestionBank: 'Exercises',
        toggleQuestionBooks: 'Exercises',
        toggleSimulations: 'Simulations',
        toggleExams: 'Exams',
        toggleResultExams: 'Results',
        buttonNewQuestion: 'New Question',
        tableHeaderType: 'Type',
        tableHeaderQuestion: 'Question',
        tableHeaderArea: 'Area',
        tableHeaderPublication: 'Publication',
        tableHeaderExclusivity: 'Exclusivity',
        tableHeaderAuthorship: 'Authorship',
        labelQuestionType: 'Question Type',
        selectMultipleChoice: 'Multiple choice',
        selectTrueOrFalse: 'True or false',
        trueOrFalse: 'True / False',

        titleNewQuestion: 'New Question',
        subtitleNewQuestion: 'To get started, choose a name and select a knowledge area and subjects to associate with the question.',
        formTitleQuestionName: '*Question Name',
        placeholderQuestionName: 'The name will only be visible to you',
        tooltipContent: 'The question name is for your identification purposes. Students will not see this information.',
        stepsTextName: 'Start by choosing the name, area, and related subjects',
        stepsTextInfo: 'Describe the information about the question',
        stepsTextType: 'Choose the type and craft the answers',
        stepsTextVisibility: 'Finally, choose when and where the question should be visible to the students',

        subtitleMotivatorText: 'Develop the motivating text, the statement, and, if applicable, the press.',
        formTitleMotivatorText: 'Motivating Text',
        formTitleStatementQuestion: 'Question Statement',
        pInsertMotivatingText: 'Insert the motivating text here!',
        pInsertStatement: 'Insert the statement here!',
        titlePress: 'Press',
        placeholderPress: 'Students will not see this information.',

        subtitleTypeQuestion: 'Choose the question type, write the text for the options, and mark the correct option.',
        titleAlternatives: '*Options',
        descriptionMultipleChoice: 'Write the text for the options and mark the correct one.',
        buttonAddAlternative: 'Add Option',
        descriptionTrueOrFalse: '*Mark the correct option for the question.',
        alternativeTrue: 'true',
        alternativeFalse: 'False',

        subtitleExclusivity: 'Finally, select the publication and exclusivity of the question. Exclusive questions will only be shown in exams and simulations.',
        titlePublicationExclusivity: '*Publication and Exclusivity of the Question',
        labelAfterCreate: 'Publish after creation',
        labelExclusiveExams: 'Exclusive question for exams',
        buttonPreview: 'Preview',
        correctAnswer: ' Correct answer:',
        textAsideQuestion: 'Question',
        textAsidePublished: 'published',
        textAsideNotPublished: 'not published',
        textAsideExclusive: 'exclusive',
        textAsideNotExclusive: 'not exclusive',

        newQuestionBook: 'New question book',
        questionBookName: 'Exercise Name',
        placeholderQuestionBookName: 'Enter the question book name',
        selectCourse: 'Select a course',
        loading: 'Loading...',
        selectModule: 'Select a module.',
        chooseSubject: 'Choose the subjects that will be included in the question book',
        stepsTextNameQuestionBook: 'Choose a name and associate the question book with a course and its respective module.',
        stepsTextAddSubjects: 'And add subjects.',
        getSubject: 'Search subject...',
        examSubjects: 'Exams subjects',
        publish: 'Publish',
        selectExamsSubject: 'Select the subjects for the exam',
        addQuestionTitle: 'Add questions for',
        placeholderSearchQuestion: 'Search question...',
        tagUnreleasedQuestion: 'Unreleased',

        newSimulation: 'New Simulation',
        nameSimulation: 'Simulation Name',
        placeholderSimulationName: 'Enter the simulation name',
        descriptionRecommendations: 'Description or recommendations for students',
        titleAsideBarSimulations: '{{currentCourseTitle}} students',
        titleAsideBarDescriptions: 'The exam will be available to <strong>{{currentCourseTitle}} students</strong>. They will be notified when it is scheduled',
        titleAsideBarTitle: 'Students in the {{currentCourseTitle}} course',
        titleAsideBarStartEnd: 'Start and end of the assessment',
        stepsSimulationCourse: 'Associate the simulated with a course, choose a name and give students instructions',
        stepsSimulationName: 'Add the materials',
        stepsSimulationDetails: 'Select the duration, passing grade, and correction factor',

        detailsDescription: 'Configure the assessment settings and then select the questions.',
        limitTimeTitle: 'Time limit for submission',
        startDateTitle: 'Start of assessment',
        endDateTitle: 'End of assessment',
        startHourTitle: 'Start time',
        endHourTitle: 'End time',
        passingScoreTitle: 'Passing score',
        correctionFactorTitle: 'Correction factor',
        textAsideNoCorrectFactor: 'No correction factor',
        textAsideWrongCancelRight: 'One wrong cancels one right',
        titleWeekToNextSimulationTitle: 'Weeks to the Next Simulation',
        placeholderExample: 'Example',

        buttonCreateExam: 'Create Exam',
        buttonManageExams: 'Manage Exams',
        publishExam: 'Publish Exam',

        toggleModule: 'Module',
        toggleClass: 'Class',
        newExam: 'New exam',
        nameExam: 'Exam name',
        placeholderExamName: 'Write the exam name',

        stepsTextExamsCourse: 'Start by associating the exam with a course, class, or module',
        stepsTextExamsName: 'Choose a name, provide instructions to students, and add subjects',
        stepsTextExamsDuration: 'Select the duration, passing grade, and correction factor',

        participation: 'Participation',
        aproved: 'Approved',
        globalAvarage: 'Global Average',
        questionNumber: 'Number of Questions',
        avarageTime: 'Average Time',

        exams: 'Exams',
        simulations: 'Simulations',
        examsSimulations: 'Exams and Simulations',
        questionExclusivity: 'Question Exclusivity',
        chooseExclusivity: 'Choose the exclusivity of the question',
        nonExclusive: 'Non-exclusive',
        exclusiveToExams: 'Exclusive to exams',
        exclusiveToSimulations: 'Exclusive to simulations',
        exclusiveToExamsAndSimulations: 'Exclusive to exams and simulations',
        questionPublishing: 'Question Publishing',
        shift: 'Shift',
        seriesCourse: 'Series/Course',
        classModule: 'Class/Module',
        subject: 'Subject'
      },
      materials: {
        search: 'Search',
        buttonAccessMaterials: 'Access materials',
        lastChange: 'Last change:',
        buttonAccessMaterial: 'Access material',
        exploreMaterials: 'Explore other materials from the module.'
      },
      questionBooks: {
        toggleQuestionBooks: 'Exercises',
        toggleResultExercises: 'Exercise Results',
        buttonNewQuestionBook: 'New exercise',
        buttonAccessQuestionBook: 'Access exercises',
        publishIn: 'Published in:',
        today: 'Today',
        weekCurrent: 'Current week',
        monthLast: 'Last month',
        monthThree: 'Last 3 months',
        answeringAverageTime: 'Mobile average answering time',
        exploitation: 'Exploitation',
        mistakes: 'Mistakes',
        hits: 'Hits',
        lastDay: 'yesterday',
        lastWeek: 'last week',
        lastMonth: 'last month',
        less: 'less',
        more: 'more',
        what: 'than',
        labelNeverAnswered: 'Questions I have never answered',
        labelNeverGot: 'Questions I have never got right',
        labelGotAndWrong: 'Questions I have both got right and wrong',
        buttonPause: 'Pause',
        labelQuestionBookName: 'Question Book Name',
        labelMyInteractions: 'My Interactions',
        buttonCreateQuestionBook: 'Create Question Book',

        //back-end
        medium: 'medium',
        hard: 'hard',
        easy: 'easy'
      },
      activity: {
        emptyInfo: 'No information available at the moment.',
        comparisonTitleCourses: 'Comparison of Submitted Activities Across Courses',
        comparisonTitleClasses: 'Comparative of submitted activities between classes',
        studentListing: 'Student listing',
        buttonSaveActivity: 'Save Activity',
        submitEvaluation: 'Submit evaluation',
        activityOf: '{{activityStudent}} Activity',
        teacherEvaluation: 'Teacher Evaluation',
        placeholderTeacherEvaluation: 'Write your evaluation here',
        activityTitle: 'Complementary Activity',
        evaluate: 'Evaluate',
        evaluateActivities: 'Evaluate activities',
        evaluateActivity: 'Evaluate activity',
        placeholderActivityTitle: 'Write the title of the activity here',
        waitingEvaluation: 'Waiting for evaluation',
        evaluated: 'Evaluated',
        notSubmited: 'Not submitted',
        evaluationCompleted: 'Evaluation completed',
        viewEvaluation: 'View evaluation',
        totalSubmissions: 'Total submissions',
        placeholderActivityDescription: 'Describe the activity here',
        newTitle: 'New',
        editingTitle: 'Editing',
        buttonAccess: 'Access',
        answers: 'Answers',
        titleResponseToday: "Today's responses:",
        titleStudentAnswer: 'Student answer',
        titleActivityDescription: 'Activity description',
        saveAndLeave: 'Save and leave',
        missed: 'You missed',
        right: 'You got it right',
        correct: 'Correct',
        wrong: 'Wrong',
        blank: 'Blank',
        useOf: 'Use of',
        warningText: "Today's results will be reset at midnight. <br></br>If there is no interaction for 5 minutes, the session will automatically pause.",
        buttonWarning: "Not this time, here's the answer key:",
        titleTeacherEvaluation: 'Teacher evaluation',
        in: 'In',
        sendFiles: 'File sent',
        sendUrl: 'URL sent',
        placeholderUploadFile: 'Click here to upload a file',
        placeholderSentUrl: 'Enter the URL',
        toggleFile: 'File',
        placeholderWriteComment: 'Write your comment here',
        buttonBackToActivity: 'Back to Complementary Activities',
        buttonBackToCourse: 'Back to Course',
        warningSubmit: 'Are you sure you want to submit the activity? This action cannot be undone.',
        stepsActivityName: 'Choose the name, purpose, and related subjects of the activity',
        stepsActivityAbout: 'Next, provide information about the activity',
        stepsActivityDeadline: 'Finally, determine the duration, in hours/lessons, and the mandatory status of the activity.',
        newActivityTitle: 'New Complementary Activity',
        newActivityDescription: 'To get started, choose the name of the activity, who it should be sent to, and the related subjects.',
        activityName: 'Activity Name',
        placeholderActivityName: 'Enter the activity name',
        aboutActivityDescription: 'Here you can provide information about the activity and give instructions to students on how to complete it.',
        aboutActivity: 'About the activity',
        periodDescription: 'Provide the period during which the activity will be available for students to submit',
        periodName: 'Period available for activity submission',
        hoursLesson: 'Hours/Lesson',
        mandatoryActivity: 'Mandatory activity to proceed in the module',
        associateToLesson: 'Associate to a lesson',
        selectLesson: 'Select a lesson',

        //back-end:
        activity_not_submitted: 'Activity not submitted',
        awaiting_submission: 'Awaiting submission',
        activity_evaluated: 'Activity evaluated',
        activity_submitted: 'Activity submitted'
      },
      projects: {
        addManager: 'Add Manager',
        placeholderManager: 'Enter the name of the manager',
        placeholderProfile: 'Enter the name of the new profile.',
        managersDescription: 'Add managers to assist with the project',
        sidebarGroups: 'Groups',
        sidebarGroup: 'Group',
        sidebarIndividual: 'Individual',
        sidebarCustomized: 'Customized',
        projectDistribution: 'Project Distribution',
        individualDescription: 'One project per participant',
        customizedDescription: 'Choose who will be part of the project',
        stepsParticipants: 'Inform the number of participants',
        enterNumberParticipants: 'Enter the number of participants in each group',
        tipRandomParticipants: 'Participants will be randomly selected',
        groupStudentsDescriptionCount: '{{studentsLength}} groups <span>with {{groupSize}} participants</span>',
        buttonCreateGroups: 'Create groups',
        stepsManagers: 'Add managers',
        labelRandomGroup: 'Random groups',
        labelIndividual: 'Individual',
        labelCustomized: 'Customized',
        projectOrganization: 'Project organization',
        placeholderAboutProject: 'Here you can talk about the characteristics of the project, methodologies, objectives, and others.',
        placeholderAbout: 'Here you can talk about the characteristics, methodologies, objectives, and others.',
        previewProject: 'Preview project',
        projectName: 'Project name',
        placeholderProjectName: 'Enter the project name',
        asideProjectDescription: 'The project will be available to the <strong>students of {{currentCourseOrClassTitle}}</strong>. They will be notified when the project is created',
        stepsCourseClass: 'First, select a course or class to associate with the new project',
        stepsName: 'Then, enter the name and add a description',
        stepsImage: 'Choose an image',
        stepsOrganization: 'Choose how you want to organize the project',
        selectParticipants: 'Select participants',
        newEvent: 'New event',
        placeholderStudentName: "Enter the student's name",
        placeholderSearchProjects: 'Search projects',
        newProjects: 'New project',
        toggleGroups: 'Groups',
        toggleIndividuals: 'Individuals',
        toggleCustomized: 'Customized',
        pendingEvaluation: 'Pending evaluation',
        accessProject: 'Access projects',
        createdIn: 'Created at',
        createdAt: 'Created on',
        versionProject: 'Version',
        titleProjectParticipants: 'Project Participants',
        titleProjectSchedule: 'Project Schedule',
        participants: 'Participants',
        buttonSendNewVersion: 'Send a new version',
        titleCreateProject: 'Create Project',
        titleFullDay: 'Full Day',
        titleProjectTeacher: 'Project Teachers',
        titleProjectStudents: 'Project Students',
        titleProjectManagers: 'Project Managers',
        titleTeachers: 'Teachers',
        titleStudents: 'Students',
        warningDeleteProjectVersionTitle: 'Do you really want to delete this version?',
        warningDeleteCannotBeUndoneText: 'This action cannot be undone.',
        warningDeleteCommentTitle: 'Do you really want to delete this comment?',
        warningDeleteFileTitle: 'Are you sure you want to delete this file?',
        warningDeleteTask: 'Are you sure you want to delete this task?',
        warningDeleteQuestionBook: 'Are you sure you want to delete this question book?',
        warningDeleteClass: 'Are you sure you want to delete this class?',
        warningDeleteStudent: 'Are you sure you want to delete this student?',
        warningDeleteQuestion: 'Are you sure you want to delete this question?',
        warningDeleteActivity: 'Are you sure you want to delete this supplementary activity?',
        warningDeleteExam: 'Are you sure you want to delete this exam?',
        warningDeleteSubject: 'Are you sure you want to delete this subject?',
        warningDeleteLesson: 'Are you sure you want to delete this lesson?',
        warningDeleteMatter: 'Are you sure you want to delete this course?',
        warningDeleteMaterialsFolder: 'Are you sure you want to delete this materials folder?',
        warningDeleteFolder: 'Are you sure you want to delete this folder?',
        warningDeleteCourse: 'Are you sure you want to delete this course? Please note that all associated data will be archived.',
        warningDeleteTeacher: 'Are you sure you want to delete this teacher?',
        warningDeleteEvent: 'Are you sure you want to delete this event?',
        warningDeleteModule: 'Are you sure you want to delete this module?',
        warningDeleteMaterial: 'Are you sure you want to delete this material?',
        warningDeleteParticipant: 'Are you sure you want to delete this participant?',
        warningDeleteProfile: 'Are you sure you want to delete this profile?',
        warningDeleteProject: 'Are you sure you want to delete this project?',
        warningDeleteQuest: 'Are you sure you want to delete this perception questionnaire?',
        warningDeleteStudentFromProject: 'Are you sure you want to delete this student from the project?',
        updatedIn: 'Updated at',
        in: 'in',
        by: 'by'
      },
      journey: {
        isActive: 'Active',
        isInactive: 'Inactive',
        toggleMetrics: 'Metrics',
        toggleMedals: 'Medals',
        thCourses: 'Courses',
        thClasses: 'Classes',
        thLastLmsWatched: 'Last watched class',
        thLastAccess: 'Last access',
        conclusion: 'Conclusion',
        studentCourse: 'Student courses',
        studentClasses: 'Student classes',
        monitoringCoursesTitle: 'Courses Monitoring',
        averageAccessStudentTitle: 'Comparative Moving Average of the Student',
        lastWeek: 'Last week',
        lastmonth: 'Last month',
        threeMonth: '3 months',
        totalAcess: 'Total accesses',
        totalTasks: 'Total tasks',
        materials: 'Materials',
        selectMatterText: 'Select the subject for more performance details.',
        notCompleted: 'Not completed',
        completed: 'Completed',
        accessesInClasses: 'Accesses in classes',
        medals: 'Medals',
        accessedIn: 'Accessed on'
      },
      profile: {
        toggleProfileInfo: 'Profile Information',
        toggleNotifications: 'Notifications',
        toggleSecurity: 'Security',
        labelFullName: 'Full Name',
        labelPhone: 'Phone',
        labelEmail: 'Email',
        bio: 'Bio',
        disclaimerLGPD: 'We comply with LGPD and good practices regarding data disclosure and related issues',
        typeYourPass: 'Type your password',
        typeYourNewPass: 'Type your new password',
        confirmNewPass: 'Confirm your new password',
        passMustMatch: 'Passwords must match',
        confirmYourPass: 'Confirm your password',
        newPass: 'New password',
        currentPass: 'Current password',
        wantSmsNotification: 'I want to receive notifications via SMS/WhatsApp.',
        wantEmailNotification: 'I want to receive notifications via email.',
        notificatedByTitle: 'You are notified by email and SMS/WhatsApp when:',
        switchCreatedEvent: 'An event is created.',
        switchNewMaterial: 'A new material is added.',
        switchNewPost: 'There is a new post on the fórum.',
        switchNewActivity: 'A new complementary activity is created.',
        switchNewTask: 'A new task is created.',
        switchAddNewProject: 'You are added to a new project.'
      },
      taxonomyAndMaterials: {
        toggleTaxonomies: 'Taxonomies',
        toggleMaterials: 'Materials',
        buttonAddTaxonomy: 'Add Taxonomy',
        createTaxonomy: 'Create taxonomy',
        placeholderTaxonomyName: 'Enter the taxonomy name',
        placeholderNewTaxonomyName: 'Enter the new taxonomy name',
        buttonCreateNewTaxonomy: 'Create new taxonomy',
        buttonEditTaxonomy: 'Edit taxonomy',
        warningDeleteTaxonomy: 'Are you sure you want to delete this taxonomy?',
        buttonNewFolder: 'New folder',
        createMaterialFolder: 'Create material folder',
        folderName: 'Folder name',
        placeholderFolderName: 'Enter the folder name',
        buttonNewMaterial: 'New materials',
        addMaterialTitle: 'Add material',
        fileName: 'File name',
        placeholderFileName: 'Enter the file name',
        placeholderSelectMaterial: 'Select a material',
        relatedCourses: 'Related Courses',
        relatedClass: 'Related Class'
      },
      courses: {
        toggleMyCourses: 'My Courses',
        createNewCourse: 'Create a new course',
        createCourseDescription: 'Create a new course and then select the related subjects.',
        courseTitle: 'Course Title',
        placeholderCourseTitle: 'Enter the course title',
        buttonCreateCourse: 'Create New Course',
        buttonNewCourse: 'New course',
        buttonNewClass: 'Create New Class',
        newClass: 'New Class',
        newClassDescription: 'Create a new class and then select the participants',
        className: 'Class Name',
        placeholderClassName: 'Enter the class name',
        relatedClasstoCourse: 'Associate the class with a course',
        classAvailabilityPeriod: 'Class Availability Period',
        managers: 'Managers',
        addParticpants: 'Add Participants',
        deleteParticipants: 'Delete participant',
        searchParticipants: 'Search participants...',
        placeholderParticipantName: "Enter participant's name",
        editCourse: 'Edit Course',
        courseTitle: 'Course title',
        students: 'Students',
        thStudent: 'Student',
        thRegistrationMethod: 'Registration Method',
        classes: 'Classes',
        lastUpdate: 'Last Update',
        accessIndex: 'Access Index',
        exams: 'Exams',
        evaluations: 'Evaluations',
        activities: 'Activities',
        projects: 'Projects',
        questionBooks: 'Question books',
        classesOf: 'Classes of',

        //Criação de cursos

        routeName: 'Choose a name',
        routeAbout: 'Talk about the course',
        routeImage: 'Finally, choose an image that helps to identify the course',
        placeholderCourseName: 'Enter the course name',
        aboutCourse: 'About the course',
        aboutCoursePlaceholder: 'Use this space to talk about the characteristics, methodologies, and other relevant information that will spark the interest of the students.',
        coverImage: 'Cover image',
        previewCourse: 'Preview course',
        asideTextDescription: 'A good cover image is essential to identify and create interest among students. You can use a promotional banner or photographs to illustrate the module.',
        observations: 'Observations:',
        asideTextDimensions:
          '<strong>Cover image dimensions:</strong> Choose images with dimensions of 1020x252 pixels and in .jpg, .jpeg, and .png formats. Images that do not meet these dimensions will be adjusted for optimal viewing on mobile devices and computers.',
        asideTextLegibility:
          '<strong>Legibility adjustments:</strong> To ensure good legibility of the text (module information) that will be included in the cover image, we will make a slight adjustment by darkening the image, as shown in the example below:',
        asideTextAltTip: 'Tip: The image will be slightly darkened for better legibility.',
        asideImageSecurity: 'Tip: Ensure image security',
        AsideTextTip: "<strong>Tip:</strong> If you're looking for good images, search on stock image websites. They usually have high-quality images with good composition.",
        toggleDisciplineLessons: 'Disciplines and Lessons',
        toggleActivity: 'Complementary Activities',
        toggleMaterials: 'Materials',
        toggleQuestionBooks: 'Exercises',
        toggleAbout: 'About',
        toggleFiles: 'Files'
      },
      lessons: {
        lesson: 'Lesson',
        isLessonMandatory: 'Will the lesson be mandatory to proceed in the module?',
        stepsLessonName: 'Start by naming the new lesson and relating it to subjects',
        stepsLessonVideo: 'Upload the video lesson',
        stepsLessonVideoOrSlide: 'Upload the video lesson or slide',
        stepsLessonQuestions: 'Add real-time questions',
        lessonVideoDescription: 'Add a video lesson. This step is essential for the progress of the lesson.',
        titleLinkVideo: 'Video lesson link',
        titleLinkVideoOrSlide: 'Video or slide link',
        placeholderSetLink: 'Enter the video lesson link',
        errorInvalidLink: 'Invalid video link',
        realtimeQuestionsTitle: 'Real-time questions',
        realtimeQuestionsDescription: 'Engage students with real-time questions during the lesson. Keep them interested and ensure effective understanding.',
        setTimeQuestionTitle: 'Choose the timing for the question to appear.'
      },
      nps: {
        buttonNewQuiz: 'New Quiz',
        newQuiz: 'New Perception Quiz',
        resolutionPeriod: 'Available Period for Resolution',
        quizTitle: 'Quiz Title',
        placeholderQuizTitle: 'Enter the quiz title',
        placeholderDescription: 'Quiz description',
        stars: 'Stars',
        star: 'Star',
        openReponses: 'Open-ended responses',
        accessQuest: 'Access quiz',
        everythingBeingSaving: 'Everything is being saved. The changes are being saved in the background by the system.',
        buttonAddQuestions: 'Add questions',
        buttonFinishView: 'Finish and preview',
        buttonIntervention: 'Intervention proposal',
        toggleAll: 'All',
        toggleStudentList: 'Student list',
        graphicScale: 'Graphic scale',
        question: 'Question',
        questions: 'questions',
        stronglyDisagree: 'Strongly disagree',
        partiallyDisagree: 'Partially disagree',
        neitherAgreeNorDisagree: 'Neither agree nor disagree',
        partiallyAgree: 'Partially agree',
        stronglyAgree: 'Strongly agree',
        setYourQuestion: 'Insert your question',
        writeProposal: 'Write your proposal here',
        quizDisplayCard: 'This is how the quiz will be displayed to the students.',
        buttonViewInterventionProposals: 'View intervention proposals',
        totalResponses: 'Total responses',
        totalQuestions: 'Total questions',
        filterResponseSent: 'Response sent',
        yearCourse: 'Year/Course'
      },

      metrics: {
        blankLabelSelect: 'All courses',
        projectsInProgress: 'Projects in progress',
        metricsTitle: 'You are in our Metrics Dashboard',
        metricsDescription: 'Track student performance indicators through dashboards with individual and collective metrics.',
        selectMetricsParagraph: 'Select a dashboard to access the metrics',
        titleClasses: 'Classes',
        subtitleClasses: "Access student's progress in the modules",
        titleEngagement: 'Engagement',
        subtitleEngagement: 'Access student interaction',
        totalStudents: 'Total number of students',
        student: 'Student',
        stats: 'Stats',
        distributionStudents: 'Distribution of students in the course',
        distributionStudentsByCourse: 'Distribution of students by course',
        distributionEnrolledStudentsModule: 'Distribution of enrolled students in the module',
        byProgress: 'by progress',
        byModule: 'by module',
        students: 'Students',
        averageClassAttendance: 'Average class attendance',
        perception: 'Perception',
        accessesLastWeek: 'Accesses in the last week',
        tooltipsLikes: 'Total number of likes on the class-related feed',
        tooltipsComments: 'Total number of student comments on the feed and forums related to the course',
        tooltipsMaterials: 'Total number of accesses to all course-related materials',
        tooltipsLessons: 'Total number of lessons accessed in the course',
        tooltipsModules: 'Total number of modules completed in the course',
        createdProjects: 'Created projects',
        approvedProjects: 'Approved projects',
        registeredProjects: 'registered projects.',
        submittedProjects: 'Submitted projects',
        registeredActivities: 'registered activities',
        additionalActivities: 'Additional activities',
        totalTasks: 'Total tasks for the period',
        performanceSubject: 'Performance by subject',
        accessVariation: 'Access variation'
      },
      participants: {
        buttonRegister: 'Register participant',
        toggleAdminProfiles: 'Administrative profiles',
        toggleNotifications: 'Notifications',
        profile: 'Profile',
        labelFullName: 'Full Name',
        phone: 'Phone',
        message: 'Message',
        placeholderSendMessage: 'If desired, send a message to the participant',
        buttonEdit: 'Edit',
        buttonSendEmail: 'Send registration email',
        labelChooseProfile: 'Choose a profile',
        tagRegistrationSent: 'Registration sent'
      },
      notFoundPage: {
        titleOhNo: 'This is not good!',
        Description404: "We apologize as we couldn't find the page you were looking for.",
        buttonBackToPlatform: 'Back to platform'
      },
      datesForm: {
        formattedDate: ' {{monthName}} {{day}}, {{year}}',
        formatDate: 'MM/DD/YYYY',
        formatDayMonth: 'MM/DD'
      },
      weekDays: {
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday'
      },
      monthName: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      },
      onboarding: {
        notDisplay: 'Do not display again',
        titleFeed: 'How to Use the Feed Menu',
        titleActivities: 'How to Use the Additional Activities Menu',
        titleDashboard: 'How to use the Start Menu',
        titleLms: 'How to Use the Classes Menu',
        titleMaterials: 'How to Use the Materials Menu',
        titleTasks: 'How to Use the Tasks Menu',
        titleProjects: 'How to Use the Projects Menu',
        titleQuestionBooks: 'How to Use the exercises Menu',
        titleAuditorium: 'How to Use the Monitoring Menu',
        titleExamsResolution: 'How to Use the Tests Menu',
        titleMockQuestionsBook: '"How to Use the Mock Exams Menu"',
        titleStudyPlan: 'How to use the Study Guide Menu.',
        titleShowUser: 'how to use the Performance menu',
        titleCourse: 'How to Use the Courses Menu',
        titleProfile: 'How to Use the Profile Menu',
        topBar: 'Top Bar',
        topBarDescription: 'In the top bar, we have the welcome message, a button to switch environments (if the user has permission), a Notes button, and icons for search, messages, and notifications.',
        selectCourseOrCertificates: 'Select Courses or Certificates',
        selectCourseOrCertificatesDescription: "In the 'Courses' menu header, you will find two tabs: 'My Courses' and 'Certificates'.",
        viewYourCertificates: 'View Your Certificates',
        viewYourCertificatesDescription:
          "In the 'Certificates' tab, you can view all your completion certificates for modules and courses you are enrolled in. Simply filter by courses using 'Select a course' and then click on the 'Certificate' button corresponding to the module you wish to view detailed information for. Next to the 'Certificate' button, there is a printer icon that allows the student to print their certificate.",
        filterAndCourseSelect: 'Filters and Course Selection',
        filterAndCourseSelectDescription:
          "At the top of the page, you can filter the available courses using the 'Search' box, selecting the 'Knowledge Area,' or in 'Select Status.' If you leave these fields blank, you can view all courses and classes you have access to.",
        courseCardsOverview: 'Course Cards Overview',
        courseCardsOverviewDescription: "Courses are presented in the 'Courses' menu in card format, containing useful information about each course.",
        aboutCourse: 'About the Course',
        onSelectCourse: 'When selecting a course, you can access the information through two tabs based on the course progress.',
        aboutCourseDescription:
          'If the course has already started, you will see a timeline in the Updates tab, where you can stay updated with the latest news. You will find a timeline presenting important updates in chronological order for the classes you are enrolled in.',
        aboutCourseTwoDescription: "In the 'About' tab, you will find information and key indicators about the course, as well as the list of modules just below. Upon selecting a module, you will be directed to the lessons.",
        accessingAndViewingLessons: 'Accessing and Viewing Lessons',
        whenClickCourseToExpand: 'When clicking on a discipline to expand it',
        accessingAndViewingLessonsDesc:
          "Upon entering the lesson, on your right, there is a column with all the lessons organized by subjects. Click on each one to expand or minimize the available lessons within it. Take note of the comment indicators next to each lesson. Those highlighted in orange are the ones that haven't been read yet. In the central part of the screen, the lesson will be displayed. Just below, you will find all the materials available for that lesson (exercises, complementary activities, study guides) as well as comments made by students and teachers/mentors.",
        materialsAndForum: 'Materials and Forum',
        materialsAndForumDescription: 'Access all comments made by students, teachers, and mentors, as well as materials related to the lesson, if available.',
        handouts: 'Handouts',
        handoutsDescription: 'Access questions related to the lesson for you to answer.',
        complementaryActivity: 'Complementary Activity',
        complementaryActivityDescription: 'Information about complementary activities to be developed in this lesson, if available.',
        payAttentionToTheRequirements: 'Pay Attention to the Requirements',
        payAttentionToTheRequirementsDescription: 'You may need to fulfill certain requirements before advancing in your courses. Sometimes you need to take a test, complete an activity, or watch all the lessons of a module to unlock other lessons.',
        adjustYourTasks: 'Adjust Your Tasks by Dragging Them on the Grid',
        adjustYourTasksDescription: 'Tasks can be dragged on the weekly grid, allowing you to adjust your activities according to your schedule.',
        markYourTasks: 'Mark Tasks as Completed',
        markYourTasksDescription: 'To complete a task, click on the circle in the upper left corner of the task, and it will be marked as completed.',
        createNewTasks: 'Create New Tasks',
        createNewTasksDescription:
          "Click on the '+' symbol that appears at the end of each column in the weekly table to create a new task on the chosen day. In the panel that appears on the right corner of the screen, enter information such as title, type, duration, description, and subjects related to the task. Click 'Create Task' to add the task to the calendar on the selected day.",
        tasksDetails: 'Task Details',
        tasksDetailsDescription: "By clicking on a task, there is an option called 'more details' that, when clicked by the student, will lead to a page with more details about the task.",
        quickAccessCourse: 'Quick Access to Ongoing Courses',
        quickAccessCourseDescription:
          'Resume your studies where you left off. At the top of the Dashboard, you will have quick access to ongoing courses. This allows you to easily resume lessons and continue your learning without wasting time looking for where you left off. The platform is designed to make the transition between study sessions as smooth as possible.',
        timeline: 'Shortcut to Live Classes and Study Plan',
        timelineDescription: 'Access live classes and/or your last accessed study plan through the shortcut buttons.',
        monthlyCalendar: 'Monthly Calendar',
        monthlyCalendarDesc:
          'Stay organized and plan your studies. On the right side of the Dashboard, you will find a monthly calendar that displays scheduled events. Use it to keep track of your academic commitments and click on specific days to see scheduled events. Event details will appear just below the calendar, making it easy to plan and organize your study routine."On the right side of the Dashboard, youll find a monthly calendar displaying scheduled events. Use it to track your academic commitments and click on specific days to see which events are scheduled. Event details will appear just below the calendar, making it easy to plan and organize your study routine.',
        choosingChanel: 'Choosing the Channel',
        choosingChanelDesc:
          "When entering the 'Forum' you can select the class you want to interact with using the 'Select a class' dropdown located at the top of the page. If you don't choose a class, your interaction will be with all students enrolled in the same educational institution. If you choose a class, only students enrolled in that specific class can interact with you.",
        publishingInFeed: 'Publishing in the Forum',
        publishingInFeedDesc: 'Share ideas and resources with your peers and teachers. To post, simply type your message in the text box below the filter and click on the blue arrow icon to the right to send.',
        interactingWithPublications: 'Interacting with Publications',
        interactingWithPublicationsDesc:
          'In the menu, you can interact, like, comment, and reply to posts from other students or teachers, fostering productive discussions and idea exchange. This helps create a collaborative learning environment and encourages engagement among members.',
        participantsList: 'Participants List',
        participantsListDesc:
          "To the right of the menu, you'll find a list of teachers and mentors who are participating in the conversation. This information helps identify the experts present and facilitates contacting them to ask questions or seek guidance.",
        testsSimulationsResults: 'Tests and Results',
        testsSimulationsResultsDesc:
          "At the top of the page, you will find the buttons 'Exams,' which will direct you to the assessments, and 'My Results', which will direct you to view the results of the assessments corrected according to the teacher's answer key.",
        testsMockQuestionsBookResults: 'Mock Exams and Results',
        testsMockQuestionsBookResultsDesc:
          "At the top of the page, you'll find the 'Mock Exams' button, which will direct you to the assessments, and the 'My Results' button, which will direct you to view the results of the assessments corrected according to the teacher's answer key.",
        exploreCardMockQuestions: 'Explore the mock exam cards.',
        exploreCardMockQuestionsDesc:
          "The assessments will appear in the center of the screen in card format. Each card contains information such as the title of the mock exam, status of the mock exam, taxonomy, date of administration, and the following buttons: 'Access Mock Exam' or 'Answer Key,' depending on whether you have already taken the test or not.",
        filters: 'Filters',
        filtersDesc: 'Use the filters at the top of the page to select a course, class, or status and filter relevant assessments.',
        exploreCardAvaliations: 'Explore Assessment Cards',
        exploreCardAvaliationsDesc:
          "Assessments will appear in the center of the screen in card format. Each card contains information such as test title, test status, taxonomy, number of questions, date of administration, and an 'Access Test' or 'Answer Key' button depending on whether you have already taken the test or not.",
        analyzeYourResults: '"Select Exercises or Exercise Results."',
        analyzeYourResultsDesc: "In the 'Exercises' tab, all exercises created by the teacher as well as those created by you will be listed.",
        handoutsFilter: 'Filter by Handouts',
        handoutsFilterDesc: "At the top of the 'Handouts' tab, use the checkboxes to filter Handouts by 'Course,' 'Class,' or 'Filter' (public, private, or my notebooks).",
        exploreHandoutsCard: 'Explore the Exercise Cards',
        exploreHandoutsCardDesc:
          "The exercises are presented in the center of the screen in card format. Each one contains information such as title, difficulty level, related subjects, number of questions, publication date, and an 'Access' button.",
        accessHandout: 'Access a Handout',
        accessHandoutDesc: "Click the 'Access Handout' button on a card to access the Handout resolution area.",
        answerQuestions: 'Answer the Questions',
        answerQuestionsDesc:
          "When you access the cards, you will enter the resolution area. Read each question and its alternatives carefully. Click the 'Submit answer' button after selecting your option. On the right column, you'll find the answer tracking, percentage of completion, a timer to control study time, and a 'Save and exit' button to save your progress and leave the resolution area. At this point, it's worth highlighting that exercises will be available for resolution again starting at midnight.",
        trackYourPerformance: 'Analyze your results',
        trackYourPerformanceDesc:
          'Youll be able to see some relevant indicators right at the beginning, followed by more detailed performance charts. They include: time spent responding, questions answered, unique questions answered, proficiency index, exercises completed, performance (errors and correct answers), and rolling average response time.',
        saveAndLeave: 'Save and Exit',
        saveAndLeaveDesc: "Click the 'Save and Exit' button in the right column to save your progress and exit the resolution area.",
        filterProjects: 'Filter Projects',
        filterProjectsDesc: "At the top of the page, use the checkboxes to filter projects by 'Search,' 'Select a Course,' 'Select a Class,' and 'Filters' (approved and ongoing).",
        exploreProjectsCard: 'Explore Project Cards',
        exploreProjectsCardDesc:
          "The projects are displayed in the center of the screen in a card format. Each card contains information such as title, status, participants, and a button labeled 'Access Project'. It is through this button that you access the project details.",
        accessProjectDesc: 'on a card to view project details.',
        navigateProjectDetails: 'Navigate Project Details',
        navigateProjectDetailsDesc:
          'When accessing a project, on the details screen, you will find a timeline with the versions of documents that have been sent to the projects responsible teacher. Click on each submission to view file details and comments from both students and teachers.',
        sendFile: 'Submitting files',
        sendFileDesc:
          'If the project status is "In Progress," you will see a "Submit New Version" button at the top right corner of the project details screen. Click on this button to open the new version submission window, and after selecting a file, a new version will be created for the teacher to evaluate and write a comment. With each file submitted, a new version is generated.',
        projectApproval: 'Check Project Approval',
        projectApprovalDesc: "When the teacher approves your final version, the project status will change to 'Approved.' This indicates that you have successfully completed the project and can move on to other tasks or projects on the platform.",
        overviewProfile: 'Profile Overview and Achievements',
        overviewProfileDesc:
          'At the top of the screen, you will find information about your profile, including a photo, name, and active or inactive status. Additionally, note how many medals you have earned on your journey, obtained upon completing modules.',
        generalMetrics: 'General Performance Metrics',
        generalMetricsDesc: 'The first section of the screen displays general metrics such as the courses and classes you are enrolled in, the last lesson attended, and the date of your last platform access.',
        engagementMetrics: 'Engagement and Collaboration Metrics',
        engagementMetricsDesc: 'The second section of the screen provides information about your interactions on the platform, such as likes, comments, accessed materials and lessons, and posts made.',
        progressModules: 'Ongoing Courses',
        progressModulesDesc: 'The third section displays course progress tracking, detailing the name, quantity, and percentage of completion.',
        movingAverage: 'Comparative Moving Average of the Student',
        movingAverageDesc: 'The fourth section presents an area chart displaying the number of platform accesses per period (last week, last month, or last 3 months).',
        tasksSolution: 'Task Solution and Accessed Materials',
        tasksSolutionDesc:
          'The fifth section on the left presents a bar chart showing the number of tasks completed and not completed by period (last week, last month or last 3 months). On the right, it displays a list of materials accessed by period (last week, last month or last 3 months), with details of the number of accesses and percentage indicators of increased or decreased access to each material.',
        incomeBySubject: 'Subject Performance',
        incomeBySubjectDesc:
          'The sixth and final section presents a table of performance by subject, allowing you to choose the subject, topics, and related subtopics to view your performance. The table shows an income bar and a comparative percentage indicator with the rest of the students per period (last week, last month, or last 3 months).',
        accessingAnAuditorium: 'Accessing a monitoring session.',
        accessingAnAuditoriumDesc:
          "When entering the 'Monitoring' menu, you can use the 'Select a course' filter to help you find what you're looking for. If you don't select anything, all available monitoring sessions for you will appear on the screen in card format. Just choose one and click the 'Access' button.",
        monitoringTheMonitor: 'Monitoring the Monitor',
        monitoringTheMonitorDesc:
          'When you click "Access", you will find on the next screen, in the central part, the topic of the class, and you can follow only the messages from the monitor/teacher responsible for that class. This data will be organized by date and time.',
        interactingWithChat: 'Interacting with the Chat',
        interactingWithChatDesc:
          "You can interact during the class by clicking on the 'balloon' icon in the central part to respond to a comment from the teacher/monitor, or you can write directly in the comment field in the chat located on the right side. All student comments, including yours, will always be organized by date and time like in the central chat.",
        accessFiles: 'Accessing Files',
        accessFilesDesc:
          "Whenever the teacher/monitor sends files during the class, they will appear in the central chat, where you can access them, but they also remain available in the right-hand side button called 'Files.' Upon clicking, they will be organized by date. Additionally, to facilitate your search, you can use the 'Select a teacher' filter.",
        analyzingYourIncomeDesc:
          'Just below, you can view your performance by subjects, topics, and subtopics. These are the insights collected by the platform to generate your personalized study plans. In the radar chart, youll see your performance by subject, and in the sidebar menu Subjects, you can select a single subject to detail your specific performance.',
        accessStudyPlan: 'Accessing a Study Plan',
        accessStudyPlanDesc: 'At the top of the page, study plans are presented in card format, created exclusively for you.',
        navigatingStudyPlan: 'Navigating the Study Plan',
        navigatingStudyPlanDesc:
          'When you click on "Access" for a study plan, you can navigate through the content generated for each week. When the icon for a week is green, it means the tasks have already been completed; when its red, you still have tasks to complete, and when its gray, those are weeks that have not yet started. Nevertheless, you can access all of them to review or get ahead with any content.',
        studyingStudyPlan: 'Studying through the Study Plan',
        studyingStudyPlanDesc:
          'When you select a week, the study blocks for that week will appear in the center of the screen. Click to access. Each study block consists of a video and may or may not contain various attachments. After finishing the activities in a study block, click on the dot next to it to mark it as completed.',
        profileInformations: 'Profile Information',
        profileInformationsDesc:
          'Give a personal touch to your profile. By clicking on the "Profile Information" tab, you can edit important information such as your name, profile picture, email, phone, and a brief biography. This customization allows other users to get to know a bit more about you and establish meaningful connections on the platform. Dont forget to click the "Save" button after making the changes.',
        notificationsDesc:
          "In the 'Notifications' tab, you have full control over how and when you want to receive important updates. You can toggle individual notifications on or off, ensuring that you receive only the most relevant information for your learning experience. Select your notification preferences via SMS/WhatsApp or email and choose which events you would like to be notified about.",
        eventCreation: 'Event Creation',
        addNewMaterials: 'Adding New Materials',
        newPosts: 'New Posts in the Feed',
        creatingComplementaryActivity: 'Creating Complementary Activities',
        tasksCreation: 'Task Creation',
        addANewProjects: 'Adding to New Projects',
        security: 'Security',
        securityDesc:
          "In the 'Security' tab, you can change your password whenever you deem necessary. Protect your account with a strong password. The security of your account is paramount to safeguarding your personal information and ensuring a secure experience on the platform. Enter your current password, type the new desired password, confirm it, and click 'Save' to update your password."
      },
      button: {
        approve: 'Approve',
        newModule: 'New module',
        continue: 'Continue',
        delete: 'Delete',
        saveContinue: 'Save and continue',
        save: 'Save',
        edit: 'Edit',
        confirm: 'Confirm',
        view: 'View',
        sendAnswer: 'Send answer',
        textAnswerCorret: 'Correct answer. Congratulations!',
        textAnswerIncorrect: 'Not this time. Correct answer: letter',
        answerKey: 'Answer key',
        startExam: 'Start exam',
        notFinish: 'Not yet',
        finish: 'Finish',
        nextQuestion: 'Next question',
        submitAndFinish: 'Submit and finish',
        access: 'Access',
        supportMaterial: 'Support material',
        aboutProject: 'About project',
        back: 'Back',
        accessProject: 'Access project',
        detailsProject: 'Project details',
        yes: 'Yes',
        no: 'No',
        expand: 'Expand',
        enter: 'Enter',
        submit: 'Submit',
        cancel: 'Cancel',
        certification: 'Certification',
        next: 'Next',
        previous: 'Previous',
        manage: 'Manage',
        remove: 'Remove',
        add: 'Add',
        addQuestions: 'Add questions',
        manageQuestionBooks: 'Manage question books',
        newSimulation: 'New Simulation',
        manageSimulations: 'Manage Simulation',
        results: 'Results',
        manageClasses: 'Manage classes',
        viewData: 'View data',
        viewMetrics: 'View metrics',
        manageContent: 'Manage content',
        createModule: 'Create module',
        manageModules: 'Manage modules',
        startCourse: 'Start Course',
        continueCourse: 'Continue Course',
        newDiscipline: 'New Discipline',
        createDiscipline: 'Create discipline',
        createLesson: 'Create lesson',
        offlineExam: 'Offline exam',
        printExam: 'Print exam'
      },
      textsCommon: {
        courses: 'Courses',
        course: 'Course',
        teacher: 'Teacher',
        participants: 'Participants',
        teachers: 'teachers',
        students: 'Students',
        participantDistribution: 'Participant distribution',
        projects: 'projects',
        delete: 'Delete',
        data: 'Dada',
        lastUpdateAt: 'Last updated at',
        totalStudents: 'Total number of students',
        submissionIndex: 'Submission Index',
        until: 'Until',
        search: 'Search',
        title: 'Title',
        addQuestionBook: 'Add question book',
        newMaterial: 'New material',
        newActivity: 'New activity',
        name: 'Name',
        titleChooseSubject: 'Choose subject',
        matter: 'Subject',
        matters: 'Subjects',
        topic: 'Topic',
        questions: 'Questions',
        textSelectMatterToAdd: 'Select a subject to add topics.',
        tagConcluded: 'Completed',
        messagesTitle: 'Messages',
        placeholderSendYourComment: 'Send your comment',
        textPressEnter: 'Press enter to send.',
        unavailable: 'Unavailable',
        available: 'Available',
        availableUntil: 'Available until',
        createdAt: 'Created at',
        buttonAddMatters: 'Add subjects',
        solutionTasks: 'Task Solution',
        accesses: 'accesses',
        likes: 'Likes',
        numberLikes: 'Number of likes on the feed',
        comment: 'Comment',
        comments: 'Comments',
        sendComment: 'Send comment',
        numberComments: 'Number of messages sent in class forums and feed comments',
        accessedMaterials: 'Accessed materials',
        numberAccessedMaterials: 'Total number of accessed materials',
        accessedClasses: 'Accessed classes',
        numberAccessedClasses: 'Number of accessed classes',
        numberPosts: 'Number of posts created on the feed',
        research: 'Search',
        module: 'Module',
        unit: 'Unit',
        placeholderTaskDescription: 'Enter the task description',
        image: 'Image',
        placeholderUploadImage: 'Click to upload cover image',
        linkToMeeting: 'Link to online meeting',
        questionBook: 'Question Book',
        simulations: 'Simulations',
        activities: 'Activities',
        writeHere: 'Write here',
        response: 'Response',
        dateResponse: 'Date of response',
        class: 'Class',
        courseModules: 'Course modules',
        modules: 'Modules',
        publishedModules: 'Published modules',
        lessons: 'Lessons',
        published: 'Published',
        unpublished: 'Unpublished',
        disciplineName: 'Discipline name',
        mandatoryLesson: 'Mandatory lesson',
        lessonName: 'Lesson name',
        watchingNow: 'watching now'
      },
      toast: {
        error: 'Oops, there was an error. Please try again',
        errorMedals: 'Error loading medals',
        errorNotification: 'Error fetching notifications.',
        errorNotificationTimeline: 'Error fetching timeline notifications.',
        errorGetPost: 'Error fetching posts.',
        errorGetClassroom: 'Error fetching student classrooms.',
        errorGetTags: 'Error fetching tags.',
        errorGetClasses: 'Error loading classes',
        errorGetClassesInfo: 'Error fetching class information',
        errorGetCourseInfo: 'Error fetching course information',
        errorGetStudentInfo: 'Error fetching student information.',
        errorGetCourse: 'Error loading courses.',
        errorGetExams: 'Error loading exams',
        errorGetModule: 'Error loading modules',
        errorEditTask: 'Error editing task.',
        errorCreateComment: 'Error creating comment.',
        errorGetComment: 'Error fetching comments.',
        errorDeleteComment: 'Error deleting comment.',
        errorGetCertifications: 'Failed to load certificates',
        errorSendAnswer: 'Error sending answer',
        errorGetCard: 'Error fetching cards.',
        errorGetMetrics: 'Failed to load metrics',
        errorSelectAlternative: 'Select an alternative',
        errorGetActivity: 'Error fetching complementary activity.',
        errorGetActivityStudent: 'Error fetching student activity.',
        errorCreateQuestionBook: 'Error creating question book.',
        errorDeleteQuestionBook: 'Error deleting question book.',
        errorGetQuestionBooks: 'Error fetching question book.',
        errorGetFilteredQuestion: 'There are no questions for this filter',
        errorGetStudentMetrics: 'Error loading student metrics',
        errorDeleteProject: 'Error deleting project.',
        errorGetProject: 'Error fetching projects.',
        errorCreateProject: 'Error creating project.',
        errorEditProject: 'Error editing project.',
        errorUpdatePass: 'Error updating password',
        errorGetNotificationSettings: 'Error fetching notification settings',
        errorUpdateNotificationSettings: 'Error updating notification settings',
        errorEnterEmailPass: 'Enter your email and password',
        errorCreateAccount: 'Error creating your account',
        errorCreateQuestion: 'Error creating question',
        errorSaveQuestionBook: 'Error saving question book!',
        errorGetSubjects: 'Error retrieving subjects!',
        errorAddSubject: 'Error adding subject',
        errorGetTaxonomy: 'Error loading taxonomies',
        errorSubjectRemoved: 'Error deleting subject',
        errorReorderingQuestions: 'Error reordering questions',
        errorCreateSimulation: 'Error creating simulation',
        errorEditSimulation: 'Error editing simulation',
        errorCreateExam: 'Error creating exam',
        errorEditExam: 'Error editing exam',
        errorTryAgain: 'There was an error, please try again',
        errorGetExam: 'Error loading exam',
        errorGetAgenda: 'Error retrieving agenda',
        errorEditTask: 'Error editing task',
        errorDeleteQuestion: 'Error deleting question',
        errorEditQuestion: 'Error editing question',
        errorDeleteCourse: 'Error deleting course',
        errorDeleteNotes: 'Error deleting notes',
        errorDeleteClass: 'Error deleting class',
        errorDeleteProjectGroup: 'Error deleting Project Group.',
        errorDeleteDiscipline: 'Error deleting discipline!',
        errorDeleteModule: 'Error deleting module!',
        errorDeleteParticipants: 'Error deleting participant.',
        errorDeleteSimulation: 'Error deleting simulation',
        errorDeleteMaterial: 'Error deleting material',
        errorDeleteExam: 'Error deleting exam',
        errorDeleteLesson: 'Error deleting lesson.',
        errorDeleteQuiz: 'Error deleting perception quiz.',
        errorDeleteActivity: 'Error deleting activity.',
        errorDeleteBook: 'Error deleting book',
        errorDeleteProfile: 'Error deleting profile',
        errorDeleteVersion: 'Error deleting version',
        errorDeleteTask: 'Error deleting task',
        errorCreateClass: 'Error creating class',
        errorCreateCourse: 'Error creating course',
        errorCreateMaterial: 'Error creating material',
        errorCreateNotes: 'Error creating note',
        errorCreateLesson: 'Error creating lesson!',
        errorCreateDiscipline: 'Error creating discipline',
        errorCreateTaxonomy: 'Error creating taxonomy',
        errorCreateEventDate: 'Invalid date! The maximum deadline to create the event is one year from the current date.',
        errorCreateEvent: 'Error creating event',
        errorPublishTask: 'Error publishing task',
        errorPublishBook: 'Error publishing book',
        errorPublishQuestion: 'Error publishing question',
        errorPublishExam: 'Error publishing exam',
        errorGetFirstLvlTaxonomy: 'Error retrieving first-level taxonomies.',
        errorGetStudents: 'Error retrieving students.',
        errorGetMaterials: 'Error retrieving materials.',
        errorGetUser: 'Error retrieving user',
        errorGetParticipants: 'Error retrieving participants.',
        errorGetQuestions: 'Error retrieving questions',
        errorGetSimulations: 'Error retrieving simulations',
        errorGetTaskOverflow: 'Error retrieving task overflow.',
        errorGetDailyTaskOverload: 'Error retrieving daily task overload.',
        errorEditCourse: 'Error editing course.',
        errorGetSubjects: 'Error retrieving subjects.',
        errorGetQuiz: 'Error retrieving quiz.',
        errorEditQuiz: 'Error editing quiz.',
        errorCreateQuiz: 'Error creating quiz',
        errorAddParticipant: 'Error while adding participant.',
        errorEmailNotFound: 'There is no user with this email.',
        errorStartFlashCard: 'Error opening review.',
        errorOnSendAnswerFlashCard: 'Error sending review response.',
        errorFetchingActivity: 'Error fetching activity.',
        errorLoadClass: 'Error loading classes.',
        errorLoadCourseData: 'Error loading course data.',
        errorReorderSubject: 'Erro ao reordenar disciplinas!',
        errorDeletActivity: 'Error deleting activity.',
        errorFetchComplementActivity: 'Error fetching complementary activities.',
        errorIncorrectOrMissingValues: 'Incorrect or missing values',
        errorIncorrectColumns: 'Incorrect columns',
        errorEmptySpreadsheet: 'Empty spreadsheet',
        errorCourseDoesNotHaveThisClass: 'Course does not have this class',
        errorCourseDoesNotExist: 'Course does not exists',
        errorInstitutionDoesNotHaveThisClass: 'Institution does not have this class',
        errorInstitutionDoesNotExist: 'Institution does not exists',
        errorRegisterOrGroupedUserFail: 'Register or grouped user fail',

        saving: 'Saving...',
        loadingCreateQuiz: 'Creating quiz...',

        successActivityDelet: 'Activity deleted!',
        successRemoveParticipant: 'Participant successfully removed!',
        successCreateQuiz: 'Questionnaire created successfully!',
        successDeleteMensage: 'Message deleted!',
        successPublish: 'Published successfully!',
        successUnpublish: 'Unpublished successfully!',
        successSimulationDeleted: 'Simulation deleted successfully',
        successCreatedClass: 'Class created successfully',
        successCreateCourse: 'Course created successfully!',
        successEditCourse: 'Course edited successfully!',
        successDeleteCourse: 'Course deleted successfully!',
        successCreateMaterial: 'Material created successfully!',
        successEditMaterial: 'Material edited successfully!',
        successDeleteMaterial: 'Material deleted successfully!',
        successCreateNotes: 'Note created successfully!',
        successDeleteNotes: 'Note deleted successfully!',
        successDeleteQuiz: 'Quiz deleted successfully!',
        successEditQuiz: 'Quiz edited successfully!',
        successEditClass: 'Class edited successfully!',
        successDeleteClass: 'Class deleted successfully.',
        successRegistration: 'Registration successful',
        successEditUser: 'User edited successfully',
        successRemoveParticipant: 'Participant removed successfully!',
        successMarkQuestionExclusive: 'Question marked as exclusive',
        successMarkQuestionNonExclusive: 'Question marked as non-exclusive',
        successEditComplementaryActivity: 'Complementary activity edited successfully.',
        successCreateComplementaryActivity: 'Complementary activity created successfully.',
        successDeleteActivity: 'Activity deleted successfully!',
        successDeleteEvent: 'Event deleted successfully!',
        successDeleteExam: 'Exam deleted!',
        successPublishExam: 'Exam published!',
        successUnpublishExam: 'Exam unpublished',
        successReorderSubjects: 'Subjects reordered successfully!',
        successDeleteModule: 'Module deleted successfully!',
        successAddContent: 'Content added',
        successSave: 'Saved successfully!',
        successDeleteLesson: 'Lesson deleted successfully!',
        successEditLesson: 'Lesson edited successfully!',
        successCreateLesson: 'Lesson created successfully!',
        successEditMaterial: 'Material edited successfully!',
        successCreateMaterial: 'Material created successfully!',
        successCreateDiscipline: 'Discipline created successfully!',
        successRenameDiscipline: 'Discipline renamed!',
        successReorderDisciplines: 'Disciplines reordered successfully!',
        successReorderLessons: 'Lessons reordered successfully!',
        successDeleteDiscipline: 'Discipline deleted successfully!',
        successSetLessonMandatory: 'Lesson set as mandatory',
        successSetLessonNonMandatory: 'Lesson set as non-mandatory',
        successEditDiscipline: 'Discipline edited successfully!',
        successDeleteParticipant: 'Participant deleted successfully!',
        successCreateProfile: 'Profile created!',
        successEditProfile: 'Profile edited!',
        successDeleteProfile: 'Profile deleted',
        successSaveProfile: 'Profile saved!',
        successEditTaxonomy: 'Taxonomy edited successfully!',
        successCreateTaxonomy: 'Taxonomy created successfully!',
        successRemoveStudentFromProject: 'Student removed from project successfully',
        successDeleteProjectGroup: 'Project group deleted successfully!',
        successApproveProject: 'Project approved!',
        successDeleteVersion: 'Version deleted successfully.',
        successEditEvent: 'Event edited successfully!',
        successDeleteQuestion: 'Question deleted successfully!',
        successCreateQuestions: 'Questions created successfully!',

        successCreateExam: 'Exam created successfully',
        successEditExam: 'Exam edited successfully',
        successCreateSimulation: 'Simulation created successfully',
        successEditSimulation: 'Simulation edited successfully',
        successReorderingQuestions: 'Successfully reordered questions!',
        successSubjectRemoved: 'Subject removed',
        successAddSubject: 'Subject added!',
        successSaving: 'Successfully saved!',
        successCreateQuestion: 'Question created successfully!',
        successEditQuestion: 'Question edited successfully!',
        successCreateAccount: 'Account created successfully!',
        successUpdateNotifications: 'Notifications updated.',
        successUpdatePass: 'Password updated successfully',
        successUpdateProfile: 'Profile updated!',
        sucessEditProject: 'Project edited!',
        successCreateProject: 'Project created!',
        successDeleteProject: 'Project deleted!',
        successDeleteComment: 'Comment deleted successfully.',
        successCreateComment: 'Comment created successfully.',
        successSendMessage: 'Message sent!',
        successAsk: 'Doubt sent successfully!',
        successUndoTask: 'The task was undone',
        successCreateQuestionBook: 'Question book created successfully!',
        successDeleteQuestionBook: 'Question book deleted successfully!',
        successExamDone: 'Assessment done!',
        promiseSendingProject: 'Sending project...',
        successCreatedVersion: 'Version created!',
        errorCreatedVersion: 'Error creating version',
        successSubmitActivity: 'Activity submitted successfully!',
        errorSubmitActivity: 'Error while submitting activity!',
        sucessCreateTask: 'Successfully created task',
        successRemovedTask: 'Successfully removed task',
        successEditTask: 'Successfully edited task',
        //auditorio
        errorSearchAuditorium: 'Error when searching for auditorium',
        errorSearchAuditoriums: 'Error when searching for auditoriums',
        errorSearchMessages: 'Error when searching for messages',
        errorSearchFiles: 'Error when searching for files',
        errorSearchChatMessage: 'Error when searching for chat messages',
        errorCreateFile: 'Error when creating a file:',
        errorCreateMessage: 'Error when creating a message:',
        errorCreateChatMessage: 'Error when creating a chat message',
        errorCreateAuditorium: 'Error when creating an auditorium',
        successCreateAuditorium: 'Auditorium created successfully!',
        errorDeleteAuditorium: 'Error when deleting an auditorium',
        successDeleteAuditorium: 'Auditorium deleted!',
        successDeleteMessage: 'Message deleted',
        errorEditAuditorium: 'Error when editing an auditorium',
        successUpdateAuditorium: 'Auditorium updated successfully!',
        errorDeleteMessage: 'Error when deleting a message',
        errorDeleteFile: 'Error when deleting files',

        //liveClass
        successCreateLive: 'Class created successfully!',
        successEditLive: 'Class edited successfully!',
        errorCreateLive: 'Error creating class.',
        errorEditLive: 'Error editing class.'
      },
      warning: {
        requiredField: 'Required field!',
        required: 'required',
        selectTaxonomy: 'Select at least one taxonomy',
        selectSubject: 'Select at least one subject',
        minSubject: 'The minimum number of subjects is 1.',
        minObjective: 'Add at least 1 Objective',
        warningDeleteSimulation: 'Do you really want to delete this simulation?',
        warningDeleteInvite: 'Do you really want to delete this invitation?',
        warningDeleteActivity: 'Do you really want to delete this activity?',
        warningApproveProject: 'Are you sure you want to approve the project?',
        warningStartExams: 'Are you ready to start? Please note that the resolution time for this process is {{resolutionTime}} continuous hours.',
        warningStartExams2: 'Once started, it is important to understand that this timer cannot be paused.',
        warningSendEvaluation: 'Are you sure you want to submit the evaluation to the student? This action cannot be undone.'
      },
      emptyState: {
        titleCertificate: "You don't have any certificates yet",
        textCertificate: 'Complete modules to earn one',
        textNoDataYet: 'There are no data available yet. The data is created by the students.',
        textRegister: 'No information found for this period yet.',
        textInfo: "We haven't found any information to display here yet.",
        textContent: 'We have nothing to show yet. Content may appear here later.',
        textNotification: 'You have no new notifications yet',
        textDate: 'To start viewing data, select a date',
        textSelect: 'To start viewing data, select an option',
        textAdd: 'Add participants to the class who are on the platform.',
        textData: 'There are no data here yet.',
        textData2: 'How about creating some?',
        textProject: 'You are not yet in a project',
        textProcessed: 'As soon as they are available, we will show them here for you',
        textDiscipline: 'You can structure the module by adding disciplines and in each discipline, add lessons. You can also reorder them.',
        textDefault: 'Insert a text',
        titleNotes: 'Select a note',
        textNotes: 'To view, select a note.',
        titleComments: 'We do not have comments to display yet',
        textChildrenClickHere: 'Click here',
        textChildrenToSendComment: 'to send a comment',
        titleViewClassNotes: 'View your class notes',
        textViewClassNotes: 'Or click on "New Note" to create one.',
        titleImportantNotes: 'Important notes',
        titleNotFile: 'There are no files yet',
        textNotFile: 'Files uploaded by the teacher will be displayed here.',
        studyPlan: 'We have no file to show',
        notesTitle: 'No notes',
        notesText: 'Click the button below to start',
        notificationTitle: 'No Notifications',
        registerTitle: 'No records',
        eventTitle: 'No events today.',
        eventText: 'Events scheduled by teachers will appear here.',
        tasksTitle: 'You have no tasks',
        tasksText: 'Tasks can be created by you and your teachers. Click the button above to create your first task.',
        moduleText: 'No modules at the moment.',
        moduleFilteredText: 'No modules with the selected filter',
        lmsCommentNot: 'There are no comments for this lesson.',
        routeNoAccessTitle: 'You do not have access to this route',
        projectNoAccessTitle: 'Oops. You do not have access to this project.',
        contactAnAdministratorText: 'If you think this is wrong, contact an Administrator and report the problem',
        contactATeacherText: 'If you think this is a mistake, contact a teacher.',
        contentNotAvaliable: 'Oops. This content is not available.',
        materialsNotTitle: 'No materials',
        materialsNotText: 'There are no materials available for you.',
        missingDataTitle: 'Missing Data',
        submittingNewVersionText: 'There are still no versions for this project. How about sending a new version?',
        inAnyProjectTitle: 'You are not in any project at the moment.',
        inAnyProjectText: 'Do you think you should be? Contact your teacher for more details.',
        anyAccessForPeriod: 'We could not find any access for this period',
        anyFindedMaterial: 'No material found',
        selectTopicText: 'Select a topic.',
        selectSubtopicText: 'Select a subtopic.',
        selectOptionTitle: 'Select an option',
        selectOptionRegisterText: 'Select an option on the side for your registration.',
        selectSubjectPerformanceText: 'Select a subject on the side to learn details about performance.',
        classTitle: 'Classes not available.',
        classText: 'As soon as the teachers register new class modules, they will appear here for you. And yes, we will let you know :)',
        subjectSelectText: 'Select a subject on the side to display the questions',
        noQuestionsAdd: 'No questions added for this subject',
        noSubjectAdd: 'No subjects added to this exam',
        noStudentAnswered: 'No student answered this exam',
        noAnyResponses: "Unfortunately, we didn't receive any responses for this evaluation.",
        selectCourseToStart: 'Select a course to begin.',
        selectClassToStart: 'Select a class to begin.',
        selectOption: 'Select an option',
        toStartViewingData: 'To start viewing the data, please select an option.',
        noQuest: 'No quizzes.',
        questionNotCreated: 'Questions not yet created, how about creating them?',
        selectQuestionAnalysis: 'Select a question for analysis.',
        noProjectFoundFilter: 'No projects found for the selected filter',
        noActivityFoundFilter: 'No activity found for the selected filter',
        noTaskFoundFilter: 'No tasks found for this period',
        noNewsTitle: 'No news on your timeline',
        noNewsText: "Updates made by the teachers will appear here. Stay tuned so you don't miss anything.",
        noMaterialAccessedText: 'No material accessed for the selected filter.',
        noActivity: 'No complementary activity found.',
        noMaterialText: 'No material for the selected filter.',
        noStudentsRegistered: 'No students registered for the class',
        noVersionsSubmitted: 'No versions submitted for this project',
        noClassCourseSelected: 'No class for the selected course.',
        createNewProjectInteract: 'Create a new project and interact with the students.',
        selectClassAnalysis: 'Select a class for analysis.',
        noItemFound: 'No item found',
        noStudentStartedModule: 'No student has started any module',
        noAccessFoundForPeriod: 'No access found for this period',
        titleSimulationResultNotAvailable: 'The result of your simulation is not yet available',
        textSimulationResultNotAvailable: 'Study plans will appear here when the simulation result is published!',
        titleNoSimulation: 'You have not taken any simulations yet',
        textNoSimulation: 'Study plans will appear here when a simulation is completed',
        titleSelectDiscipline: 'Select a discipline',
        textSelectDiscipline: 'Select a discipline on the side to access its content',
        titleSelectModule: 'Select an module.',
        textSelectModule: 'Select an module on the side to access his content',
        titleAdminAuditorium: 'The lesson content will be displayed here',
        textAdminAuditorium: ' You can write, upload files, and share links with the students using the interaction bar below.',
        titleStudentAuditorium: 'The lesson will begin shortly',
        textStudentAuditorium: "You'll be able to follow all the content here",
        titleChatAuditorium: 'Class chat',
        textChatAuditorium: 'No messages have been sent in the chat yet. Write a comment to start the conversation',
        selectMatterText: 'Select a subject to view more details',
        achievementsText: 'You dont have any achievements yet',
        challengesText: 'You dont have any challenge yet'
      },
      auditorium: {
        auditorium: 'Auditorium',
        buttonLessonName: 'Insert the name of the lesson',
        placeholderWriteMessageHere: 'Write your message here.',
        buttonAttachFile: 'Attach file',
        stepName: 'To start, enter the auditorium name and the lesson name',
        stepSchool: 'Add the courses',
        NameNewAuditorium: 'Name of the new auditorium',
        newAuditorium: 'New auditorium',
        minCourses: 'The minimum number of courses is 1.',
        modalTextsAddCourses: 'Add the courses that will participate in the auditorium',
        studentsInAuditorium: 'Students in the auditorium'
      },
      flashCard: {
        review: 'Review',
        startReview: 'Start review',
        successDescription: 'You have completed the review and finished this study block. Whenever you need to review the material, just come back here :)',
        successTitle: "That's it!",
        successPoints: 'Your score was',
        buttonReturnStudyPlan: 'Return to the study plan',
        buttonIKnow: 'I know ',
        buttonIDontKnow: "I don't know",
        basedPreviousAnswer: 'Based on my previous answer, I',
        buttonRight: "It's right",
        buttonWrong: "It's wrong",
        buttonNextCard: 'Next card',
        letReview: "Let's review!",
        boxDescription: 'Answer questions that will help you solidify the topics covered in class'
      },
      studyPlan: {
        access: 'Study block',
        select: 'Select a Study Guide',
        generate: 'Generated from',
        studyPlan: 'Study plan',
        studyBlock: 'Study block',
        performaceExamsTitle: 'Performance in the exams taken',
        week: 'Week',
        onTrack: '<strong>Congratulations!</strong> You are on track with your study plan.',
        completed: 'Good job! You have completed your study plan',
        unfinished: 'You have unfinished weeks.',
        answerToComplete: 'Answer the flashcards and complete the study block'
      },
      liveClass: {
        nextClass: 'Next live class',
        buttonAccess: 'Access class',
        buttonCreate: 'Create live class',
        buttonEdit: 'Edit live class',
        aboutTitle: 'About the class',
        weekOf: 'Week of',
        lastWeek: 'last week',
        nextWeek: 'next week',
        accessClass: 'Access class',
        titleClass: 'Class Title',
        placeholderTitle: 'Write the class title',
        placeholderUrl: 'Enter the class URL',
        selectCourse: 'Select a course',
        selectTheCourse: 'Select the course',
        date: 'Date',
        startAndEndTime: 'Start and End Time',
        aboutClass: 'About the class',
        aboutDescription: 'What do students need to know about the class?'
      },
      filter: {
        selectModule: 'Select a module.',
        selectClass: 'Select a class',
        selectRegionals: 'Select the Regionals',
        selectInstitution: 'Select the Institution',
        institution: 'Institution',
        searchHere: 'Search here',
        selectTeacher: 'Select a teacher',
        chooseCourse: 'Choose a course',
        chooseClass: 'Choose a class',
        filters: 'Filters',
        labelSelect: 'Select',
        selectSubject: 'Select a subject',
        labelSelectClassroom: 'Select a Classroom',
        labelSelectCourse: 'Select a Course',
        labelInProgress: 'In Progress',
        pendingEvaluation: 'Pending evaluation',
        labelCompleted: 'Completed',
        blankLabel: 'Select to filter',
        labelInitiates: 'Initiated',
        labelUninitiated: 'Uninitiated',
        labelSelectStatus: 'Select a status',
        performed: 'Performed',
        unrealized: 'Unrealized',
        searchActivity: 'Search activity',
        searchMaterial: 'Search material',
        searchQuestionBook: 'Search question book',
        searchProject: 'Search project',
        public: 'Public',
        private: 'Private',
        published: 'Published',
        publishedNot: 'Not published',
        myBooks: 'My notebooks',
        blankLabelAll: 'All',
        blankLabelAll2: 'All',
        labelSelectTheClassroom: 'Select the class',
        labelSelectTheCourse: 'Select the course',
        selectQuestionType: 'Select the question type',
        checkboxUnreleasedQuestions: 'Unreleased questions',
        done: 'Done',
        unDone: 'Not Done',
        labelSearchStudent: 'Search for a student',
        labelSearchProfile: 'Search for a profile',
        placeholderStudentName: 'Student name',
        labelParticipantsWithoutClass: 'Participants without class.',
        participant: 'Participant',
        courseName: 'Course Name',
        labelSelectArea: 'Select an area',
        labelSearchFiles: 'Search file',
        schoolYear: 'Select a school year'
      },
      loader: {
        loading: 'Loading',
        Loading: 'Loading...',
        submitting: 'Submitting...',
        loading2: 'Loading...'
      },
      language: {
        language: 'en'
      }
    }
  }
};

export { messages };
