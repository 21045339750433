import React, { useEffect } from 'react';
import { useSession } from '../hooks/useSession';
import 'moment/locale/pt';
import Notes from './Notes';
import Notifications from './Notifications';
import getPermission from '../utils/getPermission';
import { useClient } from 'jsonapi-react';
import getAuthorityLevel, { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import {  navigate } from '@reach/router';
import DropdownMessages from './DropdownMessages';
import { useTranslation } from 'react-i18next';
import Search from './Search';
import EnvironmentSelector from './EnvironmentSelector';

function MainNav() {
  const client = useClient();
  const { t, i18n } = useTranslation();
  const {  connections, dbNumber, ssoToken } = useSession();
  const isAdmin = !checkIncludeProfiles({ only: ['student'] });

  const appName = process.env.REACT_APP_NAME;

  let supportUrl = '';

  switch (appName) {
    case 'maiseducacao':
      supportUrl = 'https://suportemaisformacao.zendesk.com/hc/pt-br/requests/new';
      break;
    case 'canaleducacao':
      supportUrl = 'https://suportecanaleducacao.zendesk.com/hc/pt-br/requests/new';
      break;
    case 'egepi':
      supportUrl = 'https://escoladegoverno.zendesk.com/hc/pt-br/requests/new';
      break;
    default:
      supportUrl = 'https://wa.me/5511978432586';
  }

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  const showNotes = getPermission('Criar anotações', 'Anotações') || getPermission('Editar anotações', 'Anotações') || getPermission('Excluir anotações', 'Anotações') || getPermission('Visualizar anotações', 'Anotações');

  return (
    <div className="header">
      <div className="header__container">
        <div className="header__actions">
          <EnvironmentSelector />
          <Search />
          <DropdownMessages />
          <Notifications />
        </div>

        {showNotes && <Notes />}
      </div>
    </div>
  );
}

export default MainNav;
