import { useEffect } from 'react';
import ActionCable from 'actioncable';
import { useClient } from 'jsonapi-react';

const ActionCableConsumer = ({ channel, channelId, setObjectChannel, channelKey }) => {
  const client = useClient();
  const apiBaseUrl = client.config.url;
  const cableUrl = apiBaseUrl.replace(/^http(s)?:\/\/(.*?)\/api\/v1\/?$/, 'wss://$2/cable');
  const cable = ActionCable.createConsumer(cableUrl);

  useEffect(() => {
    if (!channelKey || (channelKey && channelId)) {
      const subscriptionObject = cable.subscriptions.create(
        {
          channel,
          [channelKey]: channelId
        },
        {
          received: data => {
            setObjectChannel(data);
          }
        }
      );

      return () => {
        if (subscriptionObject) {
          subscriptionObject.unsubscribe();
        }
      };
    }
  }, [channel]);

  return null;
};

export default ActionCableConsumer;
